import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import {  AdminService} from 'src/app/services/admin/admin.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("add", {static: true}) add: ModalDirective;
  @ViewChild("edit", {static: true}) edit: ModalDirective;
  searchText: any;
  elements: any = [];
  previous: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
  headElements = [
    {
       name:"Product  Id",
       sortBy:"Doc Id",
       width: 150
     },
     {
      name:"Product Name",
      sortBy:"Product Name",
      width: 150
    },
   
    {
     name:"Product Code",
     sortBy:"Product Code",
     width: 150
   },
    

    {
      name:"Action",
      sortBy:"Action",
      width: 150
    }

   ];
  product_id: any;
  product_name: any;
  prodid: any;
  product_code: any;
  
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public adminServiceService: AdminService,
      private ctwaService:CtwaService,
      private masterService : MasterService,  
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({

      addformgup: this.formBuilder.group({
        productname: ['', Validators.required],
        productcode: ['', Validators.required],
       
        
      }),
      editformgup: this.formBuilder.group({
        editproductname: ['', Validators.required],
        editproductcode: ['', Validators.required],
      
    }),
      
    });
    this.adminServiceService
    .getProduct()
    .subscribe( (product:any) => {
     ///console.log(product); return false;
        if(product.response === 200 && product.status === true ){
          for (let i = 0; i < product.product.length;  i++) {
            const element = product.product[i];
         // console.log(element); return false;
           
                            this.elements.push(
                              {
                                product_id	:element.product_id,
                                product_name: element.product_name,
                               
                                product_code: element.product_code,
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
   // add  
   submittedAdd = true;
   get rs() { return (<FormGroup>this.registerForm.get('addformgup')).controls; }
   get addformgup() {
     return this.registerForm.get('addformgup');
   }
   // edit 
 
   submittedEdit = true;
   get ed() { return (<FormGroup>this.registerForm.get('editformgup')).controls; }
   get editformgup() {
     return this.registerForm.get('editformgup');
   }
    //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(7);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 
  editformdata(data){
   // console.log(data);
    this.product_id    = data.product_id;
    this.product_name  = data.product_name;
   
    this.product_code  = data.product_code;
    
    this.showLoader = false;
    setTimeout(() => {
    this.edit.show();
      this.showLoader = true;
    }, 1000);
   
  }

  editbtn(){
    this.submittedEdit = true;
    this.showLoader = false;
  
    // stop here if form is invalid
    if (this.editformgup.invalid) {
      console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);
  
      return;
    }else{
  
      let editproductname=this.editformgup.value.editproductname;
      let editproductcode=this.editformgup.value.editproductcode;
     
      let detail = { results: [{ "editproductname":editproductname,"editproductcode":editproductcode,"product_id":this.product_id}] };
      detail['results']; 
      this.masterService.editProductmaster(detail).subscribe((result: any) => {
  
        if(result.response === 200 && result.status === true){
          this.showLoader = true;
          this.elements=[];
         
            this.edit.hide();
            this.adminServiceService
            .getProduct()
            .subscribe( (product:any) => {
             // console.log(product); return false;
                if(product.response === 200 && product.status === true ){
                  for (let i = 0; i < product.product.length;  i++) {
                    const element = product.product[i];
                 // console.log(element); return false;
                   
                                    this.elements.push(
                                      {
                                        product_id	:element.product_id,
                                        product_name: element.product_name,
                                        product_code: element.product_code,
                                   
                                                  
                                        
                                        
                                        
        
                                      }
                                      );
                                      
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
        }
      
      });
    }
  }
  addBtn(){
    this.submittedAdd = true;
    this.showLoader = false;

    // stop here if form is invalid
    if (this.addformgup.invalid) {
      console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }else{
      let name=this.addformgup.value.productname;
      let code=this.addformgup.value.productcode;

      let detail = { results: [{ "name":name,"code":code}] };
      detail['results']; 
      this.masterService.addProductmaster(detail).subscribe((add: any) => {
     
        if(add.response == 200 && add.status == true){
          this.showLoader = true;
          this.addformgup.get('productname').setValue('');
          this.addformgup.get('productcode').setValue('');
          this.elements=[];
          this.add.hide();
          this.adminServiceService
          .getProduct()
          .subscribe( (product:any) => {
           // console.log(product); return false;
              if(product.response === 200 && product.status === true ){
                for (let i = 0; i < product.product.length;  i++) {
                  const element = product.product[i];
               // console.log(element); return false;
                 
                                  this.elements.push(
                                    {
                                      product_id	:element.product_id,
                                      product_name: element.product_name,
                                      product_code: element.product_code,                                     
      
                                    }
                                    );
                                    
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
               
        }
      
      });
    

    }
  }

  deleteForm(data){
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);

    
    this.prodid=data.product_id;    
    this.masterService.deleteProduct(this.prodid)
    .subscribe((del:any) => {
      if(del.response === 200 && del.status === true ){
        this.elements=[];          
           this.adminServiceService
          .getProduct()
          .subscribe( (product:any) => {
          // console.log(product); return false;
              if(product.response === 200 && product.status === true ){
                for (let i = 0; i < product.product.length;  i++) {
                  const element = product.product[i];
              // console.log(element); return false;
                
                                  this.elements.push(
                                    {
                                      product_id	:element.product_id,
                                      product_name: element.product_name,
                                      product_code: element.product_code,
                                
                                                
                                      
                                      
                                      

                                    }
                                    );
                                    
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
      }else{
        console.log('User list Api faile');
      }

    });
  }









        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}