import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import {  AdminService} from 'src/app/services/admin/admin.service';
import {  PartnerService} from 'src/app/services/partner/partner.service'

export interface Color {
  name: string;
}
@Component({
  selector: 'app-meraemi-business-partner',
  templateUrl: './meraemi-business-partner.component.html',
  styleUrls: ['./meraemi-business-partner.component.scss']
})
export class MeraemiBusinessPartnerComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("add", {static: true}) add: ModalDirective;
  @ViewChild("edit", {static: true}) edit: ModalDirective;
  searchText: any;
  elements: any = [];
  previous: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
  headElements = [
    {
       name:"Partner Id",
       sortBy:"Partner Id",
       width: 150
     },
     {
      name:"Partner Name",
      sortBy:"Partner Name",
      width: 150
    },
   
    {
     name:"Partner Email",
     sortBy:"Partner Email",
     width: 150
   },
    

    {
      name:"Partner Contact",
      sortBy:"Partner Contact",
      width: 150
    },
    

    {
      name:"Partner Operating From",
      sortBy:"Partner Operating From",
      width: 150
    }
    ,
    

    {
      name:"Partner Pincode",
      sortBy:"Partner Pincode",
      width: 150
    },
    

    {
      name:"Partner Date",
      sortBy:"Partner Date",
      width: 150
    },
    

    {
      name:"View",
      sortBy:"View",
      width: 150
    }
   ];
  product_id: any;
  product_name: any;
  prodid: any;
  product_code: any;
  pid: any;
  
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public adminServiceService: AdminService,
      private ctwaService:CtwaService,
      private masterService : MasterService,  
      private partnerSer : PartnerService,  
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
  
    this.partnerSer
    .getBusinessPartnerData()
    .subscribe( (pat:any) => {
     //console.log(pat.data[0]); return false;
        if(pat.response === 200 && pat.status === true ){
          for (let i = 0; i < pat.data[0].partner.length;  i++) {
            const element = pat.data[0].partner[i];
        // console.log(element); return false;
           
                            this.elements.push(
                              {
                                partnerDate	           : element.partnerDate,                                                           
                                partnerEmail           : element.partnerEmail,
                                partnerFname           : element.partnerFname,          
                                partnerId              : element.partnerId,      
                                partnerLname           : element.partnerLname,    
                                partnerMobile          : element.partnerMobile, 
                                partnerOperatingFrom   : element.partnerOperatingFrom, 
                                partnerPincode         : element.partnerPincode, 

                              }
                              );
                              
                                
            }
          console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
   
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(7);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 
  

   businessPartnerDetails(pid){      
    // console.log(data);
    this.pid = this.cryptojsService.encryptData((pid));       
    const base_url=location.origin+"/#/business-partner-details?data=";        
    const detailsurl = base_url + this.pid.toString();   
   // console.log(detailsurl);
    window.open(detailsurl, "_blank");
}






        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}