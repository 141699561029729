/*app.component.ts*/
import { Component, OnInit, ViewChild } from '@angular/core';
import * as CanvasJS from '../../../assets/js/canvasjs.min';

import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { formatDate } from '@angular/common';
import { MdbTableDirective, MdbTablePaginationComponent, ModalDirective } from 'ng-uikit-pro-standard';

import { LoginServiceService } from 'src/app/services/login/login-service.service';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import { HttpClient  } from '@angular/common/http';
@Component({
   selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
 
export class DashboardComponent implements OnInit {
 // @ViewChild('datefrom', {static: true}) usernameElement: ElementRef;
  @ViewChild("datefrom", {static: true}) datefrom: ModalDirective;
  @ViewChild("dateto", {static: true}) dateto: ModalDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
	FirstDaydate: string;
	optionsadid: any = []; 
  currentdate: string;
  formdates:  number=0;	
  registerForm: FormGroup;
  main_array: any=[];
  showLoader: boolean;
  router: any;
  form: string;
  to: string;
  activedate:  number=0;	
  daternagediv: number=0;	
  public radioModel: string = 'Left';
  tabval:number=0;
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  lastmontStartDate: string;
  lastmontEndDate: string;
  totalApplicationActiveCurrentMonth:number=0;
  totalLonMtAciveCurrentMonth: number=0;
  activeDocPending: any = []; 
  activeDocPendingTotal:  number=0;
  activeDocReceived: any = []; 
  activeDocReceivedTotal: number=0;
  activeTransfer:  any = []; 
  activeTransferTotal: number=0;
  activeLenderProcess: any = [];
  activeLenderProcessTotal: number=0;
  
  activeDisbursed: any = [];
  uid:number=0;
  activeDisbursedTotal: number=0;
  activeLoanTap: any = [];
  activeLoanTapTotal: number=0;
  activeFullerton: any = [];
  activeFullertonTotal: number=0;
  activeIdfcFirstBank: any = [];
  activeIdfcFirstBankTotal: number=0; 
  activeABCLBank: any = [];
  activeABCLBankTotal: number=0; 
  activeTataCapital:  any = [];
  activeTataCapitalTotal: number=0; 
  activeKotak: any = [];
  activeKotakTotal:number=0; 
  activeHDB: any = [];
  activeHDBTotal: number=0; 
 

  totalLonMtInAciveCurrentMonth : number=0; 
  totalApplicationInActiveCurrentMonth : number=0; 
  InactiveCustCancel : any = [];
  inactiveCRMCancel: any = [];
   inactiveCRMDecline: any = [];
   InactiveCustCancelTotal: number=0; 
   inactiveCRMCancelTotal: number=0; 
   inactiveCRMDeclineTotal: number=0; 

   inActiveLoanTap: any = [];
   inActiveLoanTapTotal: number=0;
   inActiveFullerton: any = [];
   inActiveFullertonTotal: number=0;
   inActiveIdfcFirstBank: any = [];
   inActiveIdfcFirstBankTotal: number=0;
   inActiveABCLBank: any = [];
   inActiveABCLBankTotal: number=0;
   inActiveTataCapital: any = [];
   inActiveTataCapitalTotal: number=0;
   inActiveKotak: any = [];
   inActiveKotakTotal: number=0;
   inActiveHDB: any = [];
   inActiveHDBTotal: number=0;
  loanAmtActive: number=0;
  loanAmtinActive: number=0;
 
  activelendingkartTotal: number=0;
  activeAxisBankTotal: number=0;
  activeHeroFincorpLtdTotal: number=0;
  activePoonawallaFinanceLtdTotal: number=0;
  inActivelendingkartTotal: number=0;
  inActiveAxisBankTotal: number=0;
  inActiveHeroFincorpLtdTotal: number=0;
  inActivePoonawallaFinanceLtdTotal: number=0;
  activeLenderTotal: number=0;
  inactiveLenderTotal: number=0;

//lastmonth Active user
lastmonthLoanAmtActive: number=0;
LastMonthloanAmtActive: number=0;
lastMontActiveTotal: number=0;
activeDocPendingLastMonth: any = [];
activeDocPendingLastMonthTotal: number=0;
activeDocReceivedLastMonth: any = [];
activeDocReceivedLastMonthTotal: number=0;
activeTransferLastMonth: any = [];
activeTransferLastMonthTotal: number=0;
activeLenderProcessLastMonth: any = [];
activeLenderProcessLastMonthTotal: number=0;
activeDisbursedLastMonth: any = [];
activeDisbursedLastMonthTotal: number=0;
activeLenderLastmonthTotal: number=0;
activeLoanTapLastMonth: any = [];
activeLoanTapLastMonthTotal: number=0;
activeFullertonLastMonth: any = [];
activeFullertonLastMonthTotal: number=0;
activeIdfcFirstBankLastMonth: any = [];
activeIdfcFirstBankLastMonthTotal: number=0;
activeABCLBankLastMonth: any = [];
activeABCLBankLastMonthTotal: number=0;
activeTataCapitalLastMonth: any = [];
activeTataCapitalLastMonthTotal: number=0;
activeKotakLastMonth: any = [];
activeKotakLastMonthTotal: number=0;
activeHDBLastMonth: any = [];
activeHDBLastMonthTotal: number=0;
activelendingkartLastMonthTotal: number=0; 
activeAxisBankLastMonthTotal: number=0;
activeHeroFincorpLtdLastMonthTotal: number=0;
   

activePoonawallaFinanceLtdLastMonthTotal: number=0;previousdayFrom: string;
  previousdayTo: string;
  totoaldocCompletePercentage: number;
  docCompletDiv: number;
  totoaldocReceivedPercentage: number;
  totoalDocIncompletePercentage: number;
  totoalDocPendingPercentage: number;
  applicationActiveDiv: number;
  totoalApplicationActivePercentage: number;
  loanAmtDiv: number;
  loanAmtPercentage: number;
  totoalPendingReviewPercentage: number;
  transferDiv: number;
  totoaltransferPercentage: number;
  lenderProcessDiv: number;
  totoallenderProcessPercentage: number;
  disbursedDiv: number;
  totoaldisbursedPercentage: number;
  leadApplicationsInactDiv: number;
  totoaleadApplicationsInacPercentage: number;
  elig_status: string;
  appstage: string;
  lender_name: string;
  provider: string;
  empcategory: string;
  meraemistage: any;
  elements: any= [];
  lendername: any;
  maindataToDate: string;
  maindatafFomDate: string;
  elementsdate: any= [];
  tabledata: number=0;
  cityid: any;
  loginuserid: any;
  rolecode: any;
  fname: any;
  lname: any;
  fnamelname: string;
;
activeOfflineLastMonth: any = [];
activeOfflineLastMonthTotal: number=0;;
activeOffline: any = [];
activeOfflineTotal: number=0;
inActiveOffline: any = [];
inActiveOfflineTotal: number=0;
//lastmonth 
totalApplicationInActiveLastMonth: number=0;
totalLonMtInAciveLastMonth: number=0;
InactiveCustCancelLastMonth: any = [];
InactiveCustCancelLastMonthTotal: number=0;
inactiveCRMCancelLastMonth: any = [];
inactiveCRMCancelLastMonthTotal: number=0;
inactiveCRMDeclineLastMonth: any = [];
inactiveCRMDeclineLastMonthTotal: number=0;
inActiveOfflineLastMonth: any = [];
inActiveOfflineLastMonthTotal: number=0;
inActiveLoanTapLastMonth: any = [];
inActiveLoanTapLastMonthTotal: number=0;
inActiveFullertonLastMonth: any = [];
inActiveFullertonLastMonthTotal: number=0;
inActiveIdfcFirstBankLastMonth: any = [];
inActiveIdfcFirstBankLastMonthTotal: number=0;
inActiveABCLBankLastMonth: any = [];
inActiveABCLBankLastMonthTotal: number=0;
inActiveTataCapitalLastMonth: any = [];
inActiveTataCapitalLastMonthTotal: number=0;
inActiveKotakLastMonth: any = [];
inActiveKotakLastMonthTotal: number=0;
inActiveHDBLastMonth: any = [];
inActiveHDBLastMonthTotal: number=0;
inActivelendingkartLastMonthTotal: number=0;;
inActiveAxisBankLastMonthTotal: number=0;;
inActiveHeroFincorpLtdLastMonthTotal: number=0;;
inActivePoonawallaFinanceLtdLastMonthTotal: number=0;;
///Active
activePendingReview: any = [];
activeCallPending: any = [];
activeCallComplete: any = [];
activeDocComplete: any = [];
activeDocIncomplete: any = [];
activePendingReviewTotal: number=0;
activeCallPendingTotal: number=0;                  
activeCallCompleteTotal: number=0;                
activeDocCompleteTotal: number=0;
activeDocIncompleteTotal: number=0;
//lstmonth Inactive
activePendingReviewLastMonth: any = [];
activePendingReviewLastMonthTotal: number=0;
activeCallPendingLastMonth: any = [];
activeCallPendingLastMonthTotal: number=0;
activeCallCompleteLastMonth: any = [];
activeCallCompleteLastMonthTotal: number=0;
activeDocCompleteLastMonth: any = [];
activeDocCompleteLastMonthTotal: number=0;
activeDocIncompleteLastMonth: any = [];
activeDocIncompleteLastMonthTotal: number=0;
PendingReviewDiv: number=0;
PendingReview: number=0;
callPendinDiv: number=0;
 callPendin: number=0;
 callCompleteDiv: number=0;
 callComplete: number=0;
 docComplete: number=0;
 docCompleteDiv: number=0;
 docIncompleteDiv: number=0;
 docIncomplete: number=0;
 docPendingDiv: number=0;
 docPending: number=0;
 docReceivedDiv: number=0;
 docReceived: number=0;
 //graph
 activeDocPendingGrap:  any = []; 
activeDocReceivedGraph:  any = []; 
activeTransferGraph:  any = []; 
activeLenderProcessGraph:  any = []; 
activeDisbursedGraph:  any = []; 
activePendingReviewGraph:  any = []; 
activeCallPendingGraph:  any = []; 
activeCallCompleteGraph:  any = []; 
activeDocCompleteGraph:  any = []; 
activeDocIncompleteGraph:  any = []; 
cust: {} ;
mainaaary: any = []; 
mainaaaryInactive: any = []; 
previous: any = [];
result: any = [];
user:any;
title = 'angular-ip-address';
api_key = 'YOUR API KEY';
url = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + this.api_key;

	constructor(  private formBuilder: FormBuilder, private cryptojsService:CryptojsService,
    private http:HttpClient,
    private ctwaService:CtwaService,
    private loginServiceService: LoginServiceService,
    ) {


           

     }
	
		  
     ngOnInit() {    
      console.log(this.getIPAddress());
    
      this.user=this.loginServiceService.userData();
      //console.log(this.user);
       if(this.user.length !=0){
         this.cityid=(JSON.parse(this.user)['cityname']);
         this.loginuserid= (JSON.parse(this.user)['fb_uid']);
         this.rolecode= (JSON.parse(this.user)['rolecode']);
         this.fname= (JSON.parse(this.user)['first_name']);
         this.lname= (JSON.parse(this.user)['last_name']); 
        }
      this.tabval=1;
      let date = new Date();
            //month first date   
            var ourDate = new Date();
            let firstDays = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            // adjust 0 before single digit date
            let datesFirstDay = ("0" + firstDays.getDate()).slice(-2);
            // current month
            let monthFirstDay = ("0" + (firstDays.getMonth() + 1)).slice(-2);
            // current year
            let yearFirstDay = firstDays.getFullYear();
            this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
            //console.log('current Month Start Date' +this.FirstDaydate);
  
  
  
     
              //month current day
            
              
              let dates = ("0" + date.getDate()).slice(-2);
              // current month
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              // current year
              let year = date.getFullYear();
              this.currentdate =(year + "-" + month + "-" + dates);
            //  console.log('current Month Current Date' +this.currentdate);            
  
            this.registerForm = this.formBuilder.group({			
              filterchart: this.formBuilder.group({	 
                date : ['', Validators.required],
              datefrom: ['', Validators.required],
                dateto: ['', Validators.required],
              }),
              maindatafilter: this.formBuilder.group({	 
                 date : ['', Validators.required],
                 datefrom: ['', Validators.required],
                 dateto: ['', Validators.required],
              }),
              });
      
              this.optionsadid.push(
                {
                value: "0",
                label: "Today",
                },
                {
                  value: "1",
                  label: "Yesterday",
                  },
                  {
                    value: "7",
                    label: "Last 7 Days",
                    },
                    {
                      value: "30",
                      label: "Last 30 Days",
                      }
                    ,
                    {
                      value: "thismonth",
                      label: "This Month",
                      }
                      ,
                      
                    {
                      value: "lastmonth",
                      label: "Last  Month",
                      }
                      ,
                      {
                        value: "date",
                        label: "Select Custom Date",
                        }
                        ,
                  
                );
                // Active stage
                      //this month Active data show 	  
                       


                                   

                                        if( this.rolecode === "AD"){
                                          this.ctwaService
                                          .GetUserCTWAList()
                                          .subscribe( (result:any) => {
                                          // console.log(result); return false;
                                          
                                                 if (result.status = true  && result.response=== 200) {      //        
                                                  for (let i = 0; i < result.admin.length;  i++) {
                                                const element = result.admin[i];
                                              
                                              // console.log(element.data[0].lender_name);
                                              //  console.log(element.data[0].elig_status);
                                              // console.log(result);
                                                       
                                                  
                                                        if(element.data[0].app_stage  === "0"){
                                                          this.elig_status ='Eligibility Pending';
                                                          
                                                        
                                                        }else if(element.data[0].app_stage  === "1"){
                                                          if(element.data[0].elig_status =="1"){
                                                            this.elig_status ='Eligibility Clear';
                                                           
                                                          }else if(element.data[0].elig_status =="0"){
                                                            this.elig_status ='Eligibility Fail';
                                                            
                                                          }
                                                          this.appstage = this.elig_status;
                                                        
                                                        }else if(element.data[0].app_stage==="2"){
                                                      
                                                          this.appstage ="IAP Pending";
                                                      
                                                    
                                                      }else if(element.data[0].app_stage==="3"){
                                                        this.appstage ="IAP Complete";
                                                       
                                                        
                                                      }else if(element.data[0].app_stage === "4"){
                                                        this.appstage = 'Document Upload';
                                                        
                                                      }else if(element.data[0].app_stage ==="5"){
                                                        this.appstage = 'Meeting Scheduler';
                                                       
                                                      }else if(element.data[0].app_stage === "6"){
                                                        this.appstage = 'Success';
                                                        
                                                      }
                                                      this.lender_name = "na";
                                                  
                                                    
                                                   
                                                    
                                                    if(element.data[0].provider_id === "whatsapp"){
                                                      this.provider = 'Whatsapp';
                                                      
                                                    }
                                                    
                                                  //  (CRM_Cancel , CRM Decline, Cust Cancel)
                                                    this.fnamelname= element.data[0].first_name+' '+element.data[0].last_name
                                
                                
                                
                                                    if(element.data[0].salaried==="2"){
                                                      this.empcategory="SEP";
                                
                                                    }
                                                    if(element.data[0].feedback_stage !="na"){
                                                      this.meraemistage=element.data[0].feedback_stage;
                                                    }else{
                                                      this.meraemistage="Pending Review";
                                                    }
                                                  // console.log(element.data[0].user_stage);
                                                  //   console.log(this.lender_name);
                                                  this.elements.push(
                                                    {
                                                      user_id: element.data[0].user_id, 
                                                      name:  this.fnamelname, 
                                                      lendername :"Offline" ,
                                                      loan_amount: element.data[0].loan_amount,
                                                
                                                      meraemistage:this.meraemistage,
                                                    
                                                      date: element.data[0].date,
                                                      
                                                     
                                                      
                                                    }
                                                    );
                                                                    
                                                                      
                                                  }
                                                 // console.log(this.elements);
                                                  this.mdbTable.setDataSource(this.elements);
                                                  this.elements = this.mdbTable.getDataSource();
                                                  this.previous = this.mdbTable.getDataSource();
                                                  }
                                                  
                                                });
                                         
                                        }else{
                                          this.ctwaService.getAssignedByUserList(this.cityid)
                                          .subscribe( (result:any) => {
                                         // console.log(result); return false;
                                          
                                                  if (result.status = true  && result.response=== 200) {      //        
                                              for (let i = 0; i < result.ctwa.length;  i++) {
                                                const element = result.ctwa[i];
                                              
                                              // console.log(element.data[0].lender_name);
                                              //  console.log(element.data[0].elig_status);
                                              // console.log(result);
                                              
                                                  
                                                        if(element.data[0].app_stage  === "0"){
                                                          this.elig_status ='Eligibility Pending';
                                                          
                                                        
                                                        }else if(element.data[0].app_stage  === "1"){
                                                          if(element.data[0].elig_status =="1"){
                                                            this.elig_status ='Eligibility Clear';
                                                           
                                                          }else if(element.data[0].elig_status =="0"){
                                                            this.elig_status ='Eligibility Fail';
                                                            
                                                          }
                                                          this.appstage = this.elig_status;
                                                        
                                                        }else if(element.data[0].app_stage==="2"){
                                                      
                                                          this.appstage ="IAP Pending";
                                                      
                                                    
                                                      }else if(element.data[0].app_stage==="3"){
                                                        this.appstage ="IAP Complete";
                                                       
                                                        
                                                      }else if(element.data[0].app_stage === "4"){
                                                        this.appstage = 'Document Upload';
                                                        
                                                      }else if(element.data[0].app_stage ==="5"){
                                                        this.appstage = 'Meeting Scheduler';
                                                       
                                                      }else if(element.data[0].app_stage === "6"){
                                                        this.appstage = 'Success';
                                                        
                                                      }
                                                      this.lender_name = "na";
                                                   
                                                    
                                                    
                                                  
                                                    if(element.data[0].provider_id === "whatsapp"){
                                                      this.provider = 'Whatsapp';
                                                      
                                                    }
                                                  
                                                  //  (CRM_Cancel , CRM Decline, Cust Cancel)
                                                    this.fnamelname= element.data[0].first_name+' '+element.data[0].last_name
                                
                                
                                
                                                    if(element.data[0].salaried==="2"){
                                                      this.empcategory="SEP";
                                
                                                    }
                                                    if(element.data[0].feedback_stage !="na"){
                                                      this.meraemistage=element.data[0].feedback_stage;
                                                    }else{
                                                      this.meraemistage="Pending Review";
                                                    }
                                                  // console.log(element.data[0].user_stage);
                                                  //   console.log(this.lender_name);
                                                                  this.elements.push(
                                                                    {
                                                                      user_id: element.data[0].user_id, 
                                                                      name:  this.fnamelname, 
                                                                      lendername :"Offline" ,
                                                                      loan_amount: element.data[0].loan_amount,
                                                                
                                                                      meraemistage:this.meraemistage,
                                                                    
                                                                      date: element.data[0].date,
                                                                      
                                                                     
                                                                      
                                                                    }
                                                                    );
                                                                  
                                                                      
                                                  }
                                                //console.log(this.elements);
                                                
                                                  }
                                                  
                                                });
                                        }
                       
                                      
    }
    getIPAddress()
    {
      this.http.get(this.url).subscribe((res:any)=>{
        console.log(res.data)
      });
    }

	chartSubmitted: boolean = false;	
	// Employer  Login Method	
	get fa() { return (<FormGroup>this.registerForm.get('filterchart')).controls; }

  get filterchart(){
	return this.registerForm.get('filterchart');
    }
 
    get fg() { return (<FormGroup>this.registerForm.get('maindatafilter')).controls; }

    get maindatafilter(){
    return this.registerForm.get('maindatafilter');
      }
	ngAfterViewInit(){
	
	}
	
	DashbordReset(user_id){
		this.filterchart.reset();
		this.main_array=0;
		window.location.reload();
	}
	backmenu(){
		this.showLoader = false;
		setTimeout(()=>{    
		  //this.showLoader = true;
		  this.router.navigate(['/dashboard']);
		}, 2000);
	   
		
		}
		rotate(event){
			event.srcElement.classList.remove("rotate");
			event.srcElement.classList.remove("tooltiptext");
			setTimeout(()=>{
			  event.srcElement.classList.add("rotate");
			  event.srcElement.classList.remove("tooltiptext");
			location.reload();
			},0)
		  }
     

      getdateValue(){
        this.daternagediv=0;
        this.activedate=0;
        
       }
    
     onClick(check){
      //console.log(check);
      if(check==1){
        this.tab = 'tab1';
        this.tabval=1;
      }else if(check==2){
        this.tab = 'tab2';
        this.tabval=2;
       // console.log(this.cust);
      }   
    
     }

     getValue(event) {
      this.cust= {} ;
      this.mainaaary=[];
      this.mainaaaryInactive=[];
      this.activeDocPending=[];
      this.activeDocReceived=[];
      this.activeTransfer=[];
      this.activeLenderProcess=[];
      this.activeDisbursed=[];
      this.activePendingReview=[];
      this.activeCallPending=[];
      this.activeCallComplete=[];
      this.activeDocComplete=[];
      this.activeDocIncomplete=[];
      this.activeOffline=[];
      this.activeLoanTap=[];
      this.activeFullerton=[];
      this.activeIdfcFirstBank=[];
      this.activeABCLBank=[];
      this.activeTataCapital=[];
      this.activeKotak=[];
      this.activeHDB=[];
      this.activeOfflineTotal=0
      //Loan Tap
      this.activeLoanTapTotal=0
      // FULLERTON INDIA
      this.activeFullertonTotal=0
      // IDFC First Bank
      this.activeIdfcFirstBankTotal=0
      //Aditya Birla Capital Ltd
      this.activeABCLBankTotal=0
      //Tata Capital Limited
      this.activeTataCapitalTotal=0
      //'Kotak Mahindra Bank Ltd'
      this.activeKotakTotal=0
      //HDB Financial Services Ltd
      this.activeHDBTotal=0
      // console.log(this.activeKotakTotal);
      //Doc Pending
      this.activeDocPendingTotal=0;
      //Doc Received
      this.activeDocReceivedTotal=0;
      //Transfer
      this.activeTransferTotal=0
      //Lender Process
      this.activeLenderProcessTotal=0
      //Disbursed
      this.activeDisbursedTotal=0
      //Pending Review
      this.activePendingReviewTotal=0
      //Call Pending
      this.activeCallPendingTotal=0
      //Call Complete                    
      this.activeCallCompleteTotal=0
      //Doc Complete                  
      this.activeDocCompleteTotal=0
      //Doc Incomplete
      this.activeDocIncompleteTotal=0
      //  console.log(this.activeDocIncompleteTotal);
      //totalLonMtAciveCurrentMonth
      this.loanAmtActive =0
      
      
      
      //last month
      this.lastmonthLoanAmtActive=0;
      this.activeDocPendingLastMonth=[];
      this.activeDocReceivedLastMonth=[];
      this.activeTransferLastMonth=[];
      this.activeLenderProcessLastMonth=[];
      this.activeDisbursedLastMonth=[];
      this.activePendingReviewLastMonth=[];
      this.activeCallPendingLastMonth=[];
      this.activeCallCompleteLastMonth=[];
      this.activeDocCompleteLastMonth=[];
      this.activeDocIncompleteLastMonth=[];
      this.activeLenderLastmonthTotal=0;
      this.activeOfflineLastMonth=[];
      this.activeLoanTapLastMonth=[];
      this.activeFullertonLastMonth=[];
      this.activeIdfcFirstBankLastMonth=[];
      this.activeABCLBankLastMonth=[];
      this.activeTataCapitalLastMonth=[];
      this.activeKotakLastMonth=[];
      this.activeHDBLastMonth=[];
      
          //offline
          this.activeOfflineLastMonthTotal=0;
          //Loan Tap
          this.activeLoanTapLastMonthTotal=0;
        // FULLERTON INDIA
        this.activeFullertonLastMonthTotal=0;
        // IDFC First Bank
        this.activeIdfcFirstBankLastMonthTotal=0;
          //Aditya Birla Capital Ltd
        this.activeABCLBankLastMonthTotal=0;
        //Tata Capital Limited
        this.activeTataCapitalLastMonthTotal=0;
          //'Kotak Mahindra Bank Ltd'
        this.activeKotakLastMonthTotal=0;
          //HDB Financial Services Ltd
          this.activeHDBLastMonthTotal=0;
        // console.log(this.activeKotakLastMonthTotal);
        //Doc Pending
        this.activeDocPendingLastMonthTotal=0;
        //Doc Received
        this.activeDocReceivedLastMonthTotal=0;
        //Transfer
        this.activeTransferLastMonthTotal=0;
        //Lender Process
        this.activeLenderProcessLastMonthTotal=0;
        //Disbursed
        this.activeDisbursedLastMonthTotal=0;
      
        //Pending Review
        this.activePendingReviewLastMonthTotal=0;
        //Call Pending
        this.activeCallPendingLastMonthTotal=0;
        //Call Complete                    
        this.activeCallCompleteLastMonthTotal=0;
        //Doc Complete                  
        this.activeDocCompleteLastMonthTotal=0;
        //Doc Incomplete
        this.activeDocIncompleteLastMonthTotal=0;
        //lastmonthLoanAmtActive
        this.LastMonthloanAmtActive =0;
        this.applicationActiveDiv=0;
        this.docPendingDiv=0;
       this.totoalApplicationActivePercentage=0;
       this.loanAmtDiv=0;
       this.loanAmtPercentage=0;
       this.docCompletDiv=0;
       this.totoaldocCompletePercentage=0;
       this.docReceivedDiv=0;
       this.totoaldocReceivedPercentage=0;
       this.docIncompleteDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.docPendingDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.PendingReviewDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.transferDiv=0;
       this.totoaltransferPercentage=0;
       this.lenderProcessDiv=0;
       this.totoalDocIncompletePercentage=0;
       this.disbursedDiv=0;
       this.totoaldisbursedPercentage=0;
      
      
       this.InactiveCustCancelLastMonth=[];
       this.inactiveCRMCancelLastMonth=[];
       this.inactiveCRMDeclineLastMonth=[];
       this.activeDocPendingGrap=[];
       this.activeTransferGraph=[];
       this.activeLenderProcessGraph=[];
       this.activeDisbursedGraph=[];
       this.activePendingReviewGraph=[];
       this.activeCallPendingGraph=[];
       this.activeCallCompleteGraph=[];
       this.activeDocCompleteGraph=[];
       this.activeDocIncompleteGraph=[];
        this.daternagediv=0;
        this.activedate=0;
      this.daternagediv=0;
      this.inactiveCRMDeclineLastMonthTotal=0;
      this.InactiveCustCancelLastMonthTotal=0;
     //  alert(event.value);
      let dte = new Date();
      //current day
      if(event.value ==='0'){
        this.activedate=1;
        this.daternagediv=0;
        
        let fromdate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
  
       // console.log("today  fromdate",fromdate)
       // console.log("today  todate",todate)
        this.form=(fromdate);
        this.to=(todate);
        console.log("today  fromdate",this.form)
       console.log("today  todate",this.to)
        
        //Previous Day
        var previousDate = new Date()
        let previous =previousDate.setDate(previousDate.getDate() - 1)
        let previousdayformate =formatDate(previous, 'yyyy-MM-dd', 'en-US');
        this.previousdayFrom=previousdayformate;
        this.previousdayTo=previousdayformate;
     
      }else  if(event.value === '1'){
        this.daternagediv=0;
        this.activedate=1;
       let dayval =event.value;
       let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
        let fromdatechnage =dte.setDate(dte.getDate() - dayval);
        let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
        console.log("Yesterday  fromdate",fromdate)
        console.log("Yesterday  todate",todate)
        this.form=(fromdate);
        this.to=(todate);
        let dtes = new Date();
       let privfrom =dte.setDate(dtes.getDate() - 2);
       let privto =dte.setDate(dtes.getDate() - 1);
  
        let previousdayformatefrom =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
        let previousdayformateto =formatDate(privto, 'yyyy-MM-dd', 'en-US');
       this.previousdayFrom=previousdayformatefrom;
       this.previousdayTo=previousdayformateto;
        //console.log(previousdayformateto);
        
  // Prints yesterday's date in date format
        //Previous 7 Day
      }else  if(event.value === '7'){
        this.daternagediv=0;
        this.activedate=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
         let fromdatechnage =dte.setDate(dte.getDate() - dayval);
         let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
         this.form=(fromdate);
         this.to=(todate);
        // console.log("Last 7 days  fromdate",fromdate)
        // console.log("Last 7 days  todate",todate)
         let privfrom =dte.setDate(dte.getDate() - 8);
         let privfromdate =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
         let privto =dte.setDate(dte.getDate() - 14);
         let privtoDate =formatDate(privto, 'yyyy-MM-dd', 'en-US');
        //back month date 
         this.previousdayFrom=privfromdate;
       this.previousdayTo=privtoDate;
       //Previous 30 Day
      }else  if(event.value === '30'){
        this.daternagediv=0;
        this.activedate=1;
        let dayval =event.value;
        let todate =formatDate(dte, 'yyyy-MM-dd', 'en-US');
         let fromdatechnage =dte.setDate(dte.getDate() - dayval);
         let fromdate =formatDate(fromdatechnage, 'yyyy-MM-dd', 'en-US');
         this.form=(fromdate);
         this.to=(todate);
       //  console.log("Last 30 days  fromdate",fromdate)
      //   console.log("Last 30 days  todate",todate)
         let privfrom =dte.setDate(dte.getDate() - 31);
         let privfromdate =formatDate(privfrom, 'yyyy-MM-dd', 'en-US');
         let privto =dte.setDate(dte.getDate() - 60);
         let privtoDate =formatDate(privto, 'yyyy-MM-dd', 'en-US');
        //back month date 
        this.previousdayFrom=privfromdate;
       this.previousdayTo=privtoDate;
      //this month record
      }else if(event.value === 'date'){
        let dayval =event.value;
        this.daternagediv=1;
        this.activedate=0;
        console.log(dayval);
       //Previous month all data
      }else if(event.value === 'thismonth'){
        this.daternagediv=0;
        this.activedate=1;
        let date = new Date();
        //month first date   
        var ourDate = new Date();
        let firstDays = new Date(ourDate.getFullYear(), ourDate.getMonth(), 1);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // adjust 0 before single digit date
        let datesFirstDay = ("0" + firstDays.getDate()).slice(-2);
        // current month
        let monthFirstDay = ("0" + (firstDays.getMonth() + 1)).slice(-2);
        // current year
        let yearFirstDay = firstDays.getFullYear();
        this.FirstDaydate =(yearFirstDay + "-" + monthFirstDay + "-" + datesFirstDay);
        
  
  
  
  
          //month current day
        
          
          let dates = ("0" + date.getDate()).slice(-2);
          // current month
          let month = ("0" + (date.getMonth() + 1)).slice(-2);
          // current year
          let year = date.getFullYear();
          this.currentdate =(year + "-" + month + "-" + dates);
     
  
        
         this.form=this.FirstDaydate;
         this.to=this.currentdate;
                
         let lstmontfirstday=new Date(date.getFullYear(), date.getMonth() - 1, 1);
         let lstmontDay = ("0" + lstmontfirstday.getDate()).slice(-2);
         // current month
         let lstmontMonth = ("0" + (lstmontfirstday.getMonth() + 1)).slice(-2);
         // current year
         let lstmontYear = lstmontfirstday.getFullYear();
         this.lastmontStartDate =(lstmontYear + "-" + lstmontMonth + "-" + lstmontDay);
       //  console.log('Previous  Month Start Date' +this.lastmontStartDate);
  
         let lstmontLasttday=new Date(date.getFullYear(), date.getMonth(), 0);
         let lstmontEndDay = ("0" + lstmontLasttday.getDate()).slice(-2);
         // current month
         let lstmontEndMonth = ("0" + (lstmontLasttday.getMonth() + 1)).slice(-2);
         // current year
         let lstmontEndYear = lstmontLasttday.getFullYear();
       //  this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + lstmontEndDay);
         this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + dates);
         
        // console.log('Previous  Month End Date' +this.lastmontEndDate);
             //back month date 
         this.previousdayFrom=this.lastmontStartDate;
         this.previousdayTo= this.lastmontEndDate;
  
        
      }else if(event.value === 'lastmonth'){
        this.daternagediv=0;
        this.activedate=1;
      // alert('ok');
        let date = new Date();
        let lstmontfirstday=new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lstmontDay = ("0" + lstmontfirstday.getDate()).slice(-2);
        // current month
        let lstmontMonth = ("0" + (lstmontfirstday.getMonth() + 1)).slice(-2);
        // current year
        let lstmontYear = lstmontfirstday.getFullYear();
        this.lastmontStartDate =(lstmontYear + "-" + lstmontMonth + "-" + lstmontDay);
       
  
        let lstmontLasttday=new Date(date.getFullYear(), date.getMonth(), 0);
        let lstmontEndDay = ("0" + lstmontLasttday.getDate()).slice(-2);
        // current month
        let lstmontEndMonth = ("0" + (lstmontLasttday.getMonth() + 1)).slice(-2);
        // current year
        let lstmontEndYear = lstmontLasttday.getFullYear();
        this.lastmontEndDate =(lstmontEndYear + "-" + lstmontEndMonth + "-" + lstmontEndDay);
       
     
  
        
         this.form=this.lastmontStartDate;
         this.to=this.lastmontEndDate;
  
  
         let previousdayFrom=new Date(date.getFullYear(), date.getMonth() - 2, 1);
         let privfromdate =formatDate(previousdayFrom, 'yyyy-MM-dd', 'en-US');
         let previousdayto=new Date(date.getFullYear(), date.getMonth() - 1, 0);
         let privtoDate =formatDate(previousdayto, 'yyyy-MM-dd', 'en-US');
         //back month date 
         this.previousdayFrom=privfromdate;
         this.previousdayTo=privtoDate;
  
      }
      this.FirstDaydate=this.form;
      this.currentdate=this.to;
    //  this.form=thiscurrentdate.FirstDaydate
    //  this.to=this.currentdate;
      
     // console.log("today  fromdate",this.FirstDaydate)
     // console.log("today  todate",this.currentdate)
        //back month date 
        
     // console.log("Yesterday  fromdate ",this.previousdayFrom)
      //console.log("Yesterday  todate",this.previousdayTo)
         
  
   
                  












                  //----------------------------------------------------- Graph------------------------------------------------------------


     }
      filter_chart(){
        this.activedate=1;
        this.cust= {} ;
        this.mainaaary=[];
        this.mainaaaryInactive=[];
        this.activeDocPending=[];
        this.activeDocReceived=[];
        this.activeTransfer=[];
        this.activeLenderProcess=[];
        this.activeDisbursed=[];
        this.activePendingReview=[];
        this.activeCallPending=[];
        this.activeCallComplete=[];
        this.activeDocComplete=[];
        this.activeDocIncomplete=[];
        this.activeOffline=[];
        this.activeLoanTap=[];
        this.activeFullerton=[];
        this.activeIdfcFirstBank=[];
        this.activeABCLBank=[];
        this.activeTataCapital=[];
        this.activeKotak=[];
        this.activeHDB=[];
        this.activeOfflineTotal=0
        //Loan Tap
        this.activeLoanTapTotal=0
        // FULLERTON INDIA
        this.activeFullertonTotal=0
        // IDFC First Bank
        this.activeIdfcFirstBankTotal=0
        //Aditya Birla Capital Ltd
        this.activeABCLBankTotal=0
        //Tata Capital Limited
        this.activeTataCapitalTotal=0
        //'Kotak Mahindra Bank Ltd'
        this.activeKotakTotal=0
        //HDB Financial Services Ltd
        this.activeHDBTotal=0
        // console.log(this.activeKotakTotal);
        //Doc Pending
        this.activeDocPendingTotal=0;
        //Doc Received
        this.activeDocReceivedTotal=0;
        //Transfer
        this.activeTransferTotal=0
        //Lender Process
        this.activeLenderProcessTotal=0
        //Disbursed
        this.activeDisbursedTotal=0
        //Pending Review
        this.activePendingReviewTotal=0
        //Call Pending
        this.activeCallPendingTotal=0
        //Call Complete                    
        this.activeCallCompleteTotal=0
        //Doc Complete                  
        this.activeDocCompleteTotal=0
        //Doc Incomplete
        this.activeDocIncompleteTotal=0
        //  console.log(this.activeDocIncompleteTotal);
        //totalLonMtAciveCurrentMonth
        this.loanAmtActive =0
        
        
        
        //last month
        this.lastmonthLoanAmtActive=0;
        this.activeDocPendingLastMonth=[];
        this.activeDocReceivedLastMonth=[];
        this.activeTransferLastMonth=[];
        this.activeLenderProcessLastMonth=[];
        this.activeDisbursedLastMonth=[];
        this.activePendingReviewLastMonth=[];
        this.activeCallPendingLastMonth=[];
        this.activeCallCompleteLastMonth=[];
        this.activeDocCompleteLastMonth=[];
        this.activeDocIncompleteLastMonth=[];
        this.activeLenderLastmonthTotal=0;
        this.activeOfflineLastMonth=[];
        this.activeLoanTapLastMonth=[];
        this.activeFullertonLastMonth=[];
        this.activeIdfcFirstBankLastMonth=[];
        this.activeABCLBankLastMonth=[];
        this.activeTataCapitalLastMonth=[];
        this.activeKotakLastMonth=[];
        this.activeHDBLastMonth=[];
        
            //offline
            this.activeOfflineLastMonthTotal=0;
            //Loan Tap
            this.activeLoanTapLastMonthTotal=0;
          // FULLERTON INDIA
          this.activeFullertonLastMonthTotal=0;
          // IDFC First Bank
          this.activeIdfcFirstBankLastMonthTotal=0;
            //Aditya Birla Capital Ltd
          this.activeABCLBankLastMonthTotal=0;
          //Tata Capital Limited
          this.activeTataCapitalLastMonthTotal=0;
            //'Kotak Mahindra Bank Ltd'
          this.activeKotakLastMonthTotal=0;
            //HDB Financial Services Ltd
            this.activeHDBLastMonthTotal=0;
          // console.log(this.activeKotakLastMonthTotal);
          //Doc Pending
          this.activeDocPendingLastMonthTotal=0;
          //Doc Received
          this.activeDocReceivedLastMonthTotal=0;
          //Transfer
          this.activeTransferLastMonthTotal=0;
          //Lender Process
          this.activeLenderProcessLastMonthTotal=0;
          //Disbursed
          this.activeDisbursedLastMonthTotal=0;
        
          //Pending Review
          this.activePendingReviewLastMonthTotal=0;
          //Call Pending
          this.activeCallPendingLastMonthTotal=0;
          //Call Complete                    
          this.activeCallCompleteLastMonthTotal=0;
          //Doc Complete                  
          this.activeDocCompleteLastMonthTotal=0;
          //Doc Incomplete
          this.activeDocIncompleteLastMonthTotal=0;
          //lastmonthLoanAmtActive
          this.LastMonthloanAmtActive =0;
          this.applicationActiveDiv=0;
          this.docPendingDiv=0;
        this.totoalApplicationActivePercentage=0;
        this.loanAmtDiv=0;
        this.loanAmtPercentage=0;
        this.docCompletDiv=0;
        this.totoaldocCompletePercentage=0;
        this.docReceivedDiv=0;
        this.totoaldocReceivedPercentage=0;
        this.docIncompleteDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.docPendingDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.PendingReviewDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.transferDiv=0;
        this.totoaltransferPercentage=0;
        this.lenderProcessDiv=0;
        this.totoalDocIncompletePercentage=0;
        this.disbursedDiv=0;
        this.totoaldisbursedPercentage=0;
        
        
        this.InactiveCustCancelLastMonth=[];
        this.inactiveCRMCancelLastMonth=[];
        this.inactiveCRMDeclineLastMonth=[];
        this.activeDocPendingGrap=[];
        this.activeTransferGraph=[];
        this.activeLenderProcessGraph=[];
        this.activeDisbursedGraph=[];
        this.activePendingReviewGraph=[];
        this.activeCallPendingGraph=[];
        this.activeCallCompleteGraph=[];
        this.activeDocCompleteGraph=[];
        this.activeDocIncompleteGraph=[];
          this.daternagediv=0;
        
        this.inactiveCRMDeclineLastMonthTotal=0;
      this.InactiveCustCancelLastMonthTotal=0;
        
        let startDate = new Date(this.filterchart.value.datefrom);
        let endDate = new Date(this.filterchart.value.dateto);

        //previous month yaear day  Start Date
        const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      // console.log(differenceInDays); 
        const currentDates = new Date();
        const pastDate = new Date(this.filterchart.value.datefrom);
        let backdayStartDay=new Date(pastDate.setDate(startDate.getDate() - 30));

        let FirstDayBackMonth = ("0" + backdayStartDay.getDate()).slice(-2);
        // current month
        let monthFirstDayBackMonth = ("0" + (backdayStartDay.getMonth() + 1)).slice(-2);
        // current year
        let yearBackMonth = backdayStartDay.getFullYear();
        let privStartdates =(yearBackMonth + "-" + monthFirstDayBackMonth + "-" + FirstDayBackMonth);
        //console.log('previous month yaear day  Start Date' + privStartdates);

        //Filter day previous month yaear day stop date
        const currentDatess =new Date(this.filterchart.value.datefrom);
        const previousDay =new Date(this.filterchart.value.datefrom);
        let backdayStopDay= new Date(previousDay.setDate(currentDatess.getDate() - 1));
        let FirstDaybackdayStop = ("0" + backdayStopDay.getDate()).slice(-2);
        // current month
        let monthFirstDaybackdayStop = ("0" + (backdayStopDay.getMonth() + 1)).slice(-2);
        // current year
        let yearBackbackdayStop= backdayStopDay.getFullYear();
        let previousDayStop =(yearBackbackdayStop + "-" + monthFirstDaybackdayStop + "-" + FirstDaybackdayStop);
      // console.log('previous month yaear day stop date' + previousDayStop);


    







        let fromdate =new Date(formatDate(startDate, 'yyyy-MM-dd', 'en-US'));
        let todate =new Date(formatDate(endDate, 'yyyy-MM-dd', 'en-US'));
        //console.log('filter from day' + fromdate);
      //  console.log('filter To day' + todate);
        const yearfrom = fromdate.getFullYear();
        const yearto = todate.getFullYear();
        const enddateFilter = new Date('2021-01-01');
        const enddate = enddateFilter.getFullYear();
        // console.log(enddate);
        // console.log(yearfrom);
        // console.log(yearto);
        if((yearfrom > enddate) && (yearto > enddate)){
          this.FirstDaydate=this.form;
          this.currentdate=this.to;
          this.form=formatDate(startDate, 'yyyy-MM-dd', 'en-US');
          this.to=formatDate(endDate, 'yyyy-MM-dd', 'en-US');

          
               




                    //----------------------------------------------------- Graph------------------------------------------------------------

                












        }else{
          alert('Date is '+ enddate+ 'greater than or equal to From date is'+fromdate+ 'And To date is '+ todate)
      
        }



      

    








      
      }
      tabledatashow(event) {
        console.log("ok");
      }
    //user Details
    getLeadDetails(data){

      // console.log(b);
      // console.log(c);
      // let FirstDaydate=a;
      // let  currentdate=b;
      // let active =c;
   
       const useid = this.cryptojsService.encryptData((data.user_id)); 
       const stage =data.meraemistage;
       const userstage =data.usersate;
      const base_url=location.origin+"/#/user-lead-details?data="; 
      const detailsurl = base_url +useid.toString();
      const urlstring=detailsurl+"&stage="+stage.toString();
      
      window.open(urlstring, "_blank");
    

    }
   
    maindata(){
      console.log('working on');
    }
}