import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Leads } from 'src/app/model/admin/leads.model';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  
  message: any;
  //bearer_token:string = "Bearer 292252f6-1c85-4c79-b869-0d3328c333e9";
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;

  getAllLeads(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'leads/Leads',{headers:headers});
  }
  getAllLeadsSalaried(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'salaried/getUserLeadSalaried',{headers:headers});
  }
   nextpage(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'salaried/getUserLeadSalariedNextPage',{data:data},{headers:headers});

  }
  getSuccessParameters(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'leads/success_parameters',{data:data},{headers:headers});

  }
  getUserEligStage(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'leads/getUserEligStage',{data:data},{headers:headers});

  }
  
  getAllAdId(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
   // console.log(headers);
    return this.http.get<Global>(`${this.global.apiurl}`+'FilterController/GetAdId',{headers:headers});
  }
  getCityname(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<Global>(`${this.global.apiurl}`+'FilterController/GetCityname',{headers:headers});
  }
  DetailedMis(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'detailsMis/detailsMisLeast',{headers:headers});
  }
  getAllStage(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/Stage',{headers:headers});
  }
  GetCriteriaFail(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'getAllTempCriteriaFail/getAllTempCriteriaFail',{headers:headers});
  }
  GetMortgageUser(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'MortgageCont/GetMortgageUser',{headers:headers});
  }
  filterCrmStageAndUserstage(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/filterCrmStageAndUserstage',{headers:headers});
  }
  GetTempSelfEmpDataController(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'tempselfemp/TempSelfEmpDataController',{headers:headers});
  }
 CampaignStage(data){
  
   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.post<any>(`${this.global.apiurl}`+'camp/CampaignStage',{data:data},{headers: headers});

  }
  TempSelfEmpWhataapUser(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'tempselfemp/TempSelfEmpWhataapUser',{data:data},{headers: headers});
 
   }
   userCampain(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'Campaign/GetUserIapComplete',{headers:headers});
  }
  GetUserIapCompleteCampaign(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'Campaign/GetUserIapCompleteCampaign',{data:data},{headers: headers});
 
   }
}
