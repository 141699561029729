import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireAuth } from 'angularfire2/auth';

import { Subscription, Observable } from 'rxjs';
import { Role } from 'src/app/model/role/role';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';
import { userTransactions } from 'src/app/model/user/userTransaction.model'; // Import the User class
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']

})

export class AppComponent implements OnInit {
  user:any;
  sub: Subscription;
  values: string[] = ['Tag 1', 'Tag 2', 'Tag 4'];

  specialPage: boolean;

  private specialPages: any[] = [
    '/login',
    '/register',
    '/set-password'
  ];

  private currentUrl = '';
  rolecode: any;
  loginAdmin: any;
  adminname: any;
  ctwadatalength: number=0;
  userlistLength: number=0;
  loginuser: any=[];
  fname: any;
  lname: any;
 
  userData: any; // Declare a property of type User

  constructor(
    private router: Router,
    private location: Location,
    public afAuth: AngularFireAuth,

    private authService:AuthenticationService,
    public cookieService:CookieService,
    
   
private loginServiceService: LoginServiceService,
  ) {

    this.router.events.subscribe((route: any) => {

     this.currentUrl = '';
      if (route.routerEvent) {
        this.currentUrl = route.routerEvent.url;
        
      //console.log(route.routerEvent.url)
      } else {
        this.currentUrl = route.url;
      }
      // console.log(route.url)
      // console.log(this.currentUrl)
      this.specialPage = this.specialPages.indexOf(this.currentUrl) !== -1;
      // console.log(this.specialPage)
    });
  }

  ngOnInit() {      
    this.user = this.loginServiceService.userData();
    //console.log('Cookie Value 2:', JSON.parse(this.user)['rolecode']);
    }

  get authenticated() {
    return this.user !== null;
  }
  
  
  // get authState$(): Observable<User|null> {
  //   return this.afAuth.user;
  // }

  goBack(): void {
    this.location.back();
  }
}
