import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { SelfEmpLeadService } from 'src/app/services/self-emp-lead/self-emp-lead.service';
import {  PartnerService} from 'src/app/services/partner/partner.service'
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  
  user:any;
  sub: Subscription;

  isValue: number=0;
  followupStageCount: any=0;
  PendingfeedbackCount: any=0;
  IApPendingfeedbackCount: any=0;
  EligPendingfeedbackCount: any=0;
  currentdate: string;
  userdate: string;
  elements: any=[];
  arrayelement: any=[];
  elements_elig: any=[];
  elig_Pendingfeedback_elements: any=[];
  iap_Pendingfeedback_elements: any=[];
  follow_update: any;
  stage_color: string;
  appstage: string;
  urlstage: string;
  elig_status: string;
  follwup_elig: any=0;
  provider: string;
  lender_name: string;
  element_main: any=[];
  iap_pendingCount:number=0;
  getContactRequest:number=0;
  
  totalUserSelfEmp: number=0;
  totalUserProfessional: number=0;
  totalpartner: number=0;
  rolecode: any;
  loginAdmin: any;
  adminname: any;
  ctwadatalength: number=0;
  userlistLength: number=0;
  loginuser: number=0;
  fname: any;
  lname: any;
  element_assign: any=[];
  cityid: any;
  loginuserid: any;
  elementsSal: any=[];
  saldata: number=0;
  elementsSelfEmp:any=[];
  elementsSelfEmpTotal: number=0;
  elementsSelfEmpProfessional:any=[];
  elemSelfEmpProfTotal: number=0;
  eleCtwaUser:any=[];
  eleCtwaUsertotal: number=0;
  fbAdid: any;
  elemLeadCtwa:any=[];
  elemLeadCtwaTotal: number=0;
  totalpertnerForm: number=0;
  constructor(
    public afAuth: AngularFireAuth,
    public router:Router,
    
    private authService:AuthenticationService,
    public cookieService:CookieService,
    private FeedbackService:FeedbackService,  

    private loginServiceService: LoginServiceService,
    private cryptojsService:CryptojsService,
    private ctwaService:CtwaService,
    private leadsService:LeadsService,
    private SelfEmpLeadService:SelfEmpLeadService,
    private partnerSer : PartnerService, 
   
    ) { }

  ngOnInit() {
 
   
    this.user=this.loginServiceService.userData();
    //console.log('Cookie Value 2:',  this.user.length);
   // console.log('Cookie Value 2:', JSON.parse(this.user)['rolecode']);
   if(this.user.length !=0){
    this.cityid=(JSON.parse(this.user)['cityname']);
    this.loginuserid= (JSON.parse(this.user)['fb_uid']);
    this.rolecode= (JSON.parse(this.user)['rolecode']);
    this.fname= (JSON.parse(this.user)['first_name']);
    this.lname= (JSON.parse(this.user)['last_name']); 
   }
   this.partnerSer
    .getBusinessPartnerData()
    .subscribe( (pat:any) => {
     //console.log(pat.data[0]); return false;
        if(pat.response === 200 && pat.status === true ){
         // console.log(pat.data[0].partner.length); return false;
           this.totalpertnerForm=pat.data[0].partner.length;
            }else{
              console.log('Business Partner Api faile');
            }
            
          });
    
   //salaried total user
   this.leadsService
   .getAllLeads()
   .subscribe( (result:any) => {
    //console.log(result);
     if (result.status = true  && result.response=== 200) {             
       for (let i = 0; i < result.leads.length;  i++) {
         const element = result.leads[i];
       
       //console.log(element);
       //  console.log(element.data[0].elig_status);
        //console.log(element);
                 this.stage_color = "info-color";
           
                 if(element.data[0].app_stage  === "0"){
                   this.elig_status ='Eligibility Pending';
                   this.stage_color = "info-color";
                   this.urlstage ='Eligibility_Pending';
                  
                 
                 }else if(element.data[0].app_stage  === "1"){
                    if(element.data[0].elig_status =="1"){
                     this.elig_status ='Eligibility Clear';
                     this.urlstage ='Eligibility_Clear';
                     this.stage_color = "info-color";
          
                    }else if(element.data[0].elig_status =="0"){
                     this.elig_status ='Eligibility Fail';
                     this.urlstage ='Eligibility_fail';
                     this.stage_color = "info-color";
                   
                    }
                    this.appstage = this.elig_status;
                  
                  }else if(element.data[0].app_stage==="2"){
               
                   this.appstage ="IAP Pending";
                   this.urlstage ='IAP_Pending';
                   this.stage_color = "info-color";
              
             
               }else if(element.data[0].app_stage==="3"){
                 this.appstage ="IAP Complete";
                 this.urlstage ='IAP_Complete';
              
                  
               }else if(element.data[0].app_stage === "4"){
                 this.appstage = 'Document Upload';
                 this.urlstage ="Document_Upload";
                 this.stage_color = "info-color";
            
               }else if(element.data[0].app_stage ==="5"){
                 this.appstage = 'Meeting Scheduler';
                 this.urlstage ="Meeting_Scheduler";
                 this.stage_color = "info-color";
              
               }else if(element.data[0].app_stage === "6"){
                 this.appstage = 'Success';
                 this.urlstage ='Success';
                 this.stage_color = "info-color";
               
               }
             if(element.data[0].lender_name !="NA"){
               if(element.data[0].lender_name=== "MeraEMI"){
                 this.lender_name = "Offline";
               }else if(element.data[0].lender_name === "Lender Not Selected"){
                 this.lender_name = "NA";
                }else{
                 this.lender_name = element.data[0].lender_name;
               }
             
               
              }
              
             



              if(element.data[0].provider_id !="whatsapp"){
                //console.log(element.data[0].provider_id);
             if(element.data[0].provider_id === "BOT MESSENGER"){
               this.provider = 'BOT';
               
             }else if(element.data[0].provider_id === "facebook.com"){
               this.provider = 'WEB';
             }
            
               this.elementsSal.push(
                 {
                   user_id: element.data[0].user_id, 
                 
                             
                   provider: this.provider,
                   city_name: element.data[0].city_name,
                   loan_amount: element.data[0].loan_amount,
                   ad_id: element.data[0].ad_id,
                   reg_date: element.data[0].date,
                   status: this.appstage,
                   feedbackstage:this.urlstage,
                   lender_name:this.lender_name,
             
                
                
                   user_monthly_income:element.data[0].user_monthly_income,
               
                  
                 }
                 );
                

              }
                          

           }
           this.saldata=this.elementsSal.length;
          // console.log(this.elementsSal.length);
           
           }else{
            this.saldata=0;
           }
          
         });
    //self emp total user
     
    this.SelfEmpLeadService
    .getAllLeads()
    .subscribe( (result:any) => {
   // console.log(result);
    
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.selfemp.length;  i++) {
          const element = result.selfemp[i]; 
               
            
                  if(element.data[0].app_stage  === "0"){
                    this.elig_status ='Eligibility Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';
                  
                  
                  }else if(element.data[0].app_stage  === "1"){
                     if(element.data[0].elig_status =="1" || element.data[0].elig_status =="na"){
                      this.elig_status ='Eligibility Clear';
                      this.urlstage ='Eligibility_Clear';
                      this.stage_color = "info-color";
                  
                     }else if(element.data[0].elig_status =="0"){
                      this.elig_status ='Eligibility Fail';
                      this.urlstage ='Eligibility_fail';
                      this.stage_color = "info-color";
                   
                     }
                     this.appstage = this.elig_status;
                   
                   }else if(element.data[0].app_stage==="2"){
                
                    this.appstage ="IAP Pending";
                    this.urlstage ='IAP_Pending';
                    this.stage_color = "info-color";
                  
              
                }else if(element.data[0].app_stage==="3"){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                 
                   
                }else if(element.data[0].app_stage === "4"){
                  this.appstage = 'Document Upload';
                  this.urlstage ="Document_Upload";
                  this.stage_color = "info-color";
              
                }else if(element.data[0].app_stage ==="5"){
                  this.appstage = 'Meeting Scheduler';
                  this.urlstage ="Meeting_Scheduler";
                  this.stage_color = "info-color";
                
                }else if(element.data[0].app_stage === "6"){
                  this.appstage = 'Success';
                  this.urlstage ='Success';
                  this.stage_color = "info-color";
                 
                }
              if(element.data[0].lender_name !="NA"){
                if(element.data[0].lender_name=== "MeraEMI"){
                  this.lender_name = "Offline";
                }else if(element.data[0].lender_name === "Lender Not Selected"){
                  this.lender_name = "NA";
                 }else{
                  this.lender_name = element.data[0].lender_name;
                }
              
                
               }
             
               
              
           
               //console.log(element.data[0].provider_id);
              if(element.data[0].provider_id === "BOT MESSENGER"){
                this.provider = 'BOT';
                
              }else{
                this.provider = 'WEB';
              }
                            this.elementsSelfEmp.push(
                              {
                                user_id: element.data[0].user_id,
                                provider: this.provider,
                                city_name: element.data[0].location,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,
                                lender_name:this.lender_name,  
                                user_monthly_income:element.data[0].user_monthly_income,
                                empcategory:element.data[0].salaried,
                                Campaign	:element.data[0].campain_name
                              }
                              );
                           
                                
            }
            this.elementsSelfEmpTotal=this.elementsSelfEmp.length;
          //console.log(this.elements);
         
            }else{
              this.elementsSelfEmpTotal=0;
            }
            
          });
  // Self Emp Professional
      this.SelfEmpLeadService
    .getProfessionalAllLeads()
    .subscribe( (result:any) => {
    //  console.log(result); //return false;

      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.selfemp.length;  i++) {
          const element = result.selfemp[i];         
                  if(element.data[0].app_stage  === "0"){
                    this.elig_status ='Eligibility Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';              
                  
                  }else if(element.data[0].app_stage  === "1"){
                    if(element.data[0].elig_status =="1"){
                      this.elig_status ='Eligibility Clear';
                      this.urlstage ='Eligibility_Clear';
                      this.stage_color = "info-color";
                    
                    }else if(element.data[0].elig_status =="0"){
                      this.elig_status ='Eligibility Fail';
                      this.urlstage ='Eligibility_fail';
                      this.stage_color = "info-color";
                    
                    }
                    this.appstage = this.elig_status;
                  
                  }else if(element.data[0].app_stage==="2"){
                
                    this.appstage ="IAP Pending";
                    this.urlstage ='IAP_Pending';
                    this.stage_color = "info-color";
              
              
                }else if(element.data[0].app_stage==="3"){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
          
                  
                }else if(element.data[0].app_stage === "4"){
                  this.appstage = 'Document Upload';
                  this.urlstage ="Document_Upload";
                  this.stage_color = "info-color";
              
                }else if(element.data[0].app_stage ==="5"){
                  this.appstage = 'Meeting Scheduler';
                  this.urlstage ="Meeting_Scheduler";
                  this.stage_color = "info-color";
              
                }else if(element.data[0].app_stage === "6"){
                  this.appstage = 'Success';
                  this.urlstage ='Success';
                  this.stage_color = "info-color";
        
                }
              if(element.data[0].lender_name !="NA"){
                if(element.data[0].lender_name=== "MeraEMI"){
                  this.lender_name = "Offline";
                }else if(element.data[0].lender_name === "Lender Not Selected"){
                  this.lender_name = "NA";
                }else{
                  this.lender_name = element.data[0].lender_name;
                }          
                
              }
              
              if(element.data[0].provider_id === "BOT MESSENGER"){
                this.provider = 'BOT';
                
              }else{
                this.provider = 'WEB';
              }       
        
                            this.elementsSelfEmpProfessional.push(
                              {
                                user_id: element.data[0].user_id,
                                provider: this.provider,
                                city_name: element.data[0].location,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,
                                lender_name:this.lender_name,  
                                user_monthly_income:element.data[0].user_monthly_income,
                                empcategory:element.data[0].salaried,
                                Campaign	:element.data[0].campain_name
                              }
                              );
                            
                                
            }
            this.elemSelfEmpProfTotal=this.elementsSelfEmpProfessional.length;
        
            }else{
              this.elemSelfEmpProfTotal=0;
            }
            
          });

  // user list ctwa total
      this.ctwaService
    .GetUserCTWAList()
    .subscribe( (result:any) => {
    // console.log(result); return false;

      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.ctwa.length;  i++) {
          const element = result.ctwa[i];
        
        // console.log(element.data[0].lender_name);
        //  console.log(element.data[0].elig_status);
        // console.log(result);
                  this.stage_color = "info-color";
            
                  if(element.data[0].app_stage  === "0"){
                    this.elig_status ='Eligibility Pending';
                    this.stage_color = "info-color";
                    this.urlstage ='Eligibility_Pending';
                  
                  
                  }else if(element.data[0].app_stage  === "1"){
                    if(element.data[0].elig_status =="1"){
                      this.elig_status ='Eligibility Clear';
                      this.urlstage ='Eligibility_Clear';
                      this.stage_color = "info-color";
                    
                    }else if(element.data[0].elig_status =="0"){
                      this.elig_status ='Eligibility Fail';
                      this.urlstage ='Eligibility_fail';
                      this.stage_color = "info-color";
                  
                    }
                    this.appstage = this.elig_status;
                  
                  }else if(element.data[0].app_stage==="2"){
                
                    this.appstage ="IAP Pending";
                    this.urlstage ='IAP_Pending';
                    this.stage_color = "info-color";
                
              
                }else if(element.data[0].app_stage==="3"){
                  this.appstage ="IAP Complete";
                  this.urlstage ='IAP_Complete';
                
                  
                }else if(element.data[0].app_stage === "4"){
                  this.appstage = 'Document Upload';
                  this.urlstage ="Document_Upload";
                  this.stage_color = "info-color";
                
                }else if(element.data[0].app_stage ==="5"){
                  this.appstage = 'Meeting Scheduler';
                  this.urlstage ="Meeting_Scheduler";
                  this.stage_color = "info-color";
            
                }else if(element.data[0].app_stage === "6"){
                  this.appstage = 'Success';
                  this.urlstage ='Success';
                  this.stage_color = "info-color";
              
                }
                this.lender_name = "na";           
                            this.eleCtwaUser.push(
                              {
                                user_id: element.data[0].user_id,                                       
                                provider: this.provider,
                                city_name: element.data[0].location,
                                loan_amount: element.data[0].loan_amount,
                                ad_id: element.data[0].ad_id,
                                reg_date: element.data[0].date,
                                status: this.appstage,
                                feedbackstage:this.urlstage,
                                lender_name:this.lender_name,  
                                user_monthly_income:element.data[0].user_monthly_income,
                                empcategory:"Self Employed",
                                Campaign	:element.data[0].campain_name,
                                mobile_number	:element.data[0].mobile_number,
                                
                              }
                              );
                            
                                
            }
            this.eleCtwaUsertotal=this.eleCtwaUser.length;
          //console.log(this.elements);
        
            }else{
              this.eleCtwaUsertotal=0;
            }
            
          });

  //lead listing ctwa
  this.ctwaService
  .getWhatsappJourney()
  .subscribe( (result:any) => {
  // console.log(result); return false;
  
    if (result.status = true  && result.response=== 200) {      //        
      for (let i = 0; i < result.Whatsapp.length;  i++) {
        const element = result.Whatsapp[i];
        const adid= element.data[0].ad_id;
    
              const parts = adid.split(":");
              if (parts.length === 2){
                this.fbAdid=parts[0];
              }else{
                this.fbAdid=element.data[0].ad_id;
              }
                //console.log(this.fbAdid);
                          this.elemLeadCtwa.push(
                            {
                              created_on	:element.data[0].created_on,
                              temp_id: element.data[0].temp_id,
                              ad_id: this.fbAdid,
                              name:  element.data[0].first_name+' '+element.data[0].last_name, 
                              email	:element.data[0].email,
                              mobileno: element.data[0].mobileno, 
                              signup: element.data[0].signup, 
                            }
                            );
                            
                              
          }
        //console.log(this.elements);
          this.elemLeadCtwaTotal=this.elemLeadCtwa.length;
          }else{
            this.elemLeadCtwaTotal=0;
          }
          
        });
       
      
  }
  toggle1() { 
    this.isValue = 1;
     
  }
  toggle2() { 
    this.isValue = 2;
    
  }
  toggle3() { 
    this.isValue = 3; 
    
  }
  
  get authenticated() {
    
    return this.user ;
    
  }
  rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  mis_user(event){   
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{      
      event.srcElement.classList.remove("tooltiptext");
      location.reload();
    },0)
  }
  support(event){   
    this.router.navigate(['/send-email']);  
    event.srcElement.classList.remove("tooltiptext");  
    setTimeout(()=>{ 
      this.router.navigate(['/send-email']) ;  
      event.srcElement.classList.remove("tooltiptext");  
      
      location.reload();
    },0)
  }
  chat(event){   
    this.router.navigate(['/send-email']);  
    event.srcElement.classList.remove("tooltiptext");  
    setTimeout(()=>{ 
      this.router.navigate(['/send-email']) ;  
      event.srcElement.classList.remove("tooltiptext");  
      
      location.reload();
    },0)
  }
  // get authState$(): Observable<User|null> {
  //   return this.afAuth.user;
  // }
  logout(){
    this.afAuth.auth.signOut();
    
 //   localStorage.removeItem('currentUser');
   // localStorage.removeItem('token_rm');
    this.cookieService.delete('currentUser');
    this.cookieService.delete('token_rm');
    this.router.navigate(['login']);

  }

}
