import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class CtwaService {
  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
    //crmWhatsapp/GetUserLeadAdminList->delete
    GetUserCTWAList(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'crmWhatsapp/GetUserCTWAList',{headers:headers});
    }
  lenderAPIResponse(data){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
   return this.http.post<any>(`${this.global.apiurl}`+'dashboardCont/lenderAPIResponse',{data:data},{headers: headers});
   console.log(headers);.69
  }
  // crmWhatsapp/getWhatsappJourney->delete
  ctwaLeadListing(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'ctwa/ctwaLeadListing',{headers:headers});
  }

   
   getFeedbackCTWA(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/getFeedbackCTWA',{data:data},{headers: headers});
     console.log(headers);
   }
   insertFeedbackCTWA(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/insertFeedbackCTWA',{data:data},{headers: headers});
     console.log(headers);
   }
   getCTWADocListing(uid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'crmWhatsapp/getCTWADocListing/'+uid+'',{headers:headers});
   // console.log(url);
  }
  // https://api2.meraemi.com/crm/v1/doc/getDocCategory
  //sys_doc_category_m

  getDocCategory(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'doc/getDocCategory',{headers:headers});
  }
  GetMeraemiStage(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/GetMeraemiStage',{headers:headers});
  }
  getDocName(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'doc/getDocName',{headers:headers});
  }
  getDocId(catid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'doc/getDocId/'+catid+'',{headers:headers});
   // console.log(url);
  }
  getAssignedByUserList(cityid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'admin/getAssignedByUserList/'+cityid+'',{headers:headers});
   // console.log(url);
  }
  
  GetUserLeadDetails(tempid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/GetUserLeadDetails/'+tempid+'',{headers:headers});
   // console.log(url);
  }
  GetUserDetails(userid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/GetUserDetails/'+userid+'',{headers:headers});
   // console.log(url);
  }

  reasonForRejection(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/reasonForRejection',{data:data},{headers: headers});
     console.log(headers);
   }
   
   ctwaDocReceived(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/ctwaDocReceived',{data:data},{headers: headers});
     console.log(headers);
   }
   assigByctwaUser(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'ctwa/assigByctwaUser',{data:data},{headers: headers});
     console.log(headers);
   }
   getAssignedByctwaList(cityid){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'ctwa/getAssignedByctwaList/'+cityid+'',{headers:headers});
   // console.log(url);
  }

  getWhatsappJourney(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'crmWhatsapp/getWhatsappJourney',{headers:headers});
  }
  

  CtwaLeadDetails(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/getWhatsappJourneyDetils',{data:data},{headers: headers});
     console.log(headers);
   }
   getUserWhatsappMsg(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'crmWhatsapp/getUserWhatsappMsg',{data:data},{headers: headers});
    
   }
  
}
