import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MdbTablePaginationComponent,IMyOptions, MDBDatePickerComponent, ClockPickerComponent,ModalDirective,MdbTableDirective } from 'ng-uikit-pro-standard';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';


import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable, empty } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';

//import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';

import { DatePipe } from '@angular/common';
import { contains } from 'jquery';

import { CtwaService } from 'src/app/services/ctwa/ctwa.service';

import {  AdminService} from 'src/app/services/admin/admin.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';
@Component({
  selector: 'app-leads-details',
  templateUrl: './leads-details.component.html',
  styleUrls: ['./leads-details.component.scss']
})
export class LeadsDetailsComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("frame1", {static: true}) frame1: ModalDirective;
  @ViewChild("frame2", {static: true}) frame2: ModalDirective;
  @ViewChild("docRecive", {static: true}) docRecive: ModalDirective;
  @ViewChild("docVeryFiNo", {static: true}) docVeryFiNo: ModalDirective;
  @ViewChild("docVeryFiYes", {static: true}) docVeryFiYes: ModalDirective;
  @ViewChild("feedbackquate", {static: true}) feedbackquate: ModalDirective;
  @ViewChild("assigned", {static: true}) assigned: ModalDirective;
  
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  tabval: number;
  subs: Subscription;
  sub: any;
  user:any;
  userdate: string;
  rolecode: any;
  loginAdmin: any;
  decryptData: any;
  data: any;
  showLoader: boolean;
  custsD = {};
  cust: any;
  userdetils: {};
  loandid:string;
  lenderloantabactive: number;
  docPending: any = [];
  lenderHDBactive: number;
  OldFeedbackMessage:  any = [];
  docReceiveduser:  any = [];
  elements_feedback: any=[];
  userdocReceived: number=0;
  docPendingStatus: number=0;
  ReceivedStatus: number=0;
  popupaddBtn: number=0;
  fedbacklength: number=0;
  fedbacklengthOld: any;
  message: any;
  feedbackloanid: string;
  FeedbackPopup: number=0;
  userdetailspagedata: any;
  whataapmsg:  any = [];
  aboutMe: any;
  address: any;
  bussDetails: any;
  eligStage: any;
  loanapp: any;
  loginuser: any;
  tempdata: any;
  education_qualification: any;
  since_residing_here: any;
  father_first_name: any;
  father_last_name: any;
  marital_status: any;
  gender: any;
  residence_status: any;
  pen_no: any;
  mother_name: any;
  email_id: any;
  ad_id: any;
  user_id: any;
  dob: any;
  mobile_no: any;
  last_name: any;
  first_name: any;
  provider_id: any;
  lead_id: any;
  loan_code: any;
  elig_status: any;
  elig_date: any;
  employment_type: any;
  industry_name: any;
  employer_catg: any;
  bus_year_name: any;
  bus_nature_type: any;
  prev_fin_year: any;
  short_name: any;
  estb_status: any;
  dscription: any;
  city_name: any;
  loan_amt: any;
  tenure: any;
  tempdate: any;
  templastname: any;
  tempfistnale: any;
  address_1: any;
  address_2: any;
  address_3: any;
  address_landmark: any;
  address_pin_code: any;
  tempid: any;
  tempemail: any;
  assigny: any;
  assFname: any;
  assLname: any;
  ass_cityname: any;
  district_name: any;
  assstatus: any;
  loginuserid: any;
  fname: any;
  lname: any;
  uname: string;
  optionState: any = []; 
  stateArray: any = [];
  optionCity: any = [];
  cityArray: any = [];
  registerForm: FormGroup;
  constructor(
    public afAuth: AngularFireAuth,
    private firebasedataService:FirebasedataService,
    private route:ActivatedRoute,
    private router:Router,
    private cryptojsService:CryptojsService,
    private loginServiceService: LoginServiceService,
    private ctwaService:CtwaService,
    private formBuilder: FormBuilder,
    public adminServiceService: AdminService,
   
  ) { }

  ngOnInit() {

  // validation input form
  this.registerForm = this.formBuilder.group({
   
  userassignedby: this.formBuilder.group({
    state: ['', Validators.required],
    city: ['', Validators.required]
  }),

  
});
    this.user=this.loginServiceService.userData();
    // console.log(this.user);
     if(this.user.length !=0){
       this.loginuserid= (JSON.parse(this.user)['fb_uid']);
       this.rolecode= (JSON.parse(this.user)['rolecode']);
       this.fname= (JSON.parse(this.user)['first_name']);
       this.lname= (JSON.parse(this.user)['last_name']); 
      }
      this.adminServiceService
      .getBanchStateMater()
      .subscribe( (st:any) => {
        //console.log(st);
              if (st.status === true) {
                
                for (let i = 0; i < st.state.length; i++) {
                  const menuarray = st.state[i];
                 
               
                  this.stateArray.push(
                    {
                      value: menuarray.data[0].district_id,
                      label: menuarray.data[0].district_name,                          
                     
                    }
                    );
                    //console.log(this.messages);
                
                
                   
                    
                }
                this.optionState =this.stateArray;
                //console.log(this.optionState);
              }else{
                console.log('District Api faile');
              }
              
            });

    this.tab  = 'tab1';
    this.tabval=1;
    //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
           //console.log(this.loginAdmin)
          }
        )
      }   
    
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);
          this.loandid= this.decryptData;
        //  parseInt
          if( this.decryptData != false){
            this.showLoader = true;           
            this.custsD["results"] = [{"loanid":(this.decryptData)}];
            //    console.log(this.custsD)          
            this.userdetils =this.custsD;
          //  console.log(this.userdetils); return false;
          }else{
            this.showLoader = true;    
            this.router.navigate(['/Leads-listing']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });

      let ctwaleadDetails={ results: [{"loanid":(this.loandid)}]};
      //console.log(ctwaleadDetails);
      //  get user details page api
      this.ctwaService.GetUserLeadDetails(this.loandid).subscribe(             
        (details:any) => {
         //console.log(details);
          if (details.status === true) {

            //aboutMe
            this.aboutMe=details.data['loans'][0]['aboutMe'];
                    this.education_qualification=this.aboutMe.education_qualification;
                    this.since_residing_here=this.aboutMe.since_residing_here;
                    this.father_first_name=this.aboutMe.father_first_name;
                    this.father_last_name=this.aboutMe.father_last_name;
                    this.marital_status=this.aboutMe.marital_status;
                    this.gender=this.aboutMe.gender;
                    this.residence_status=this.aboutMe.residence_status;
                    this.pen_no=this.aboutMe.pen_no;
                    this.mother_name=this.aboutMe.mother_name;
           // console.log( this.aboutMe.education_qualification); return false;
            //address
            this.address=details.data['loans'][0]['address'];
            this.address_1=this.address.address_1;
            this.address_2=this.address.address_2;
            this.address_3=this.address.address_3;
            this.address_landmark=this.address.address_landmark;
            this.address_pin_code=this.address.address_pin_code;
            //bussDetails
            this.bussDetails=details.data['loans'][0]['bussDetails'];
                        this.employment_type=this.bussDetails.employment_type;
                        this.industry_name=this.bussDetails.industry_name;
                        this.employer_catg=this.bussDetails.employer_catg;
                        this.bus_year_name=this.bussDetails.bus_year_name;
                        this.bus_nature_type=this.bussDetails.bus_nature_type;
                        this.prev_fin_year=this.bussDetails.prev_fin_year;
                        this.prev_fin_year=this.bussDetails.prev_fin_year;
                        this.short_name=this.bussDetails.short_name;
                        this.estb_status=this.bussDetails.estb_status;
                        this.dscription=this.bussDetails.dscription;
            //eligStage
            this.eligStage=details.data['loans'][0]['eligStage'];
                        
                        this.elig_status=this.eligStage.elig_status;
                        this.elig_date=this.eligStage.elig_date;
            //loanapp
            this.loanapp=details.data['loans'][0]['loanapp'];
                            this.loan_code=this.loanapp.loan_code;
                            this.city_name=this.loanapp.city_name;
                            this.loan_amt=this.loanapp.loan_amt;
                            this.tenure=this.loanapp.tenure;
            //loginuser
            this.loginuser=details.data['loans'][0]['loginuser'];
                      this.email_id=this.loginuser.email_id;
                      this.ad_id=this.loginuser.ad_id;
                      this.user_id=this.loginuser.user_id;
                      this.dob=this.loginuser.dob;
                      this.mobile_no=this.loginuser.mobile_no;
                      this.last_name=this.loginuser.last_name;
                      this.first_name=this.loginuser.first_name;
                      this.provider_id=this.loginuser.provider_id;
                      this.lead_id=this.loginuser.lead_id;
                     // this.logindate=this.loginuser.lead_id;
            //tempdata
            this.tempdata=details.data['loans'][0]['tempdata'];
                 this.tempdate=this.tempdata.tempdate;
                this.templastname=this.tempdata.templastname;
                this.tempfistnale=this.tempdata.tempfistnale;
                this.tempid=this.tempdata.temp_id;
                this.tempemail=this.loginuser.tempemail;
                

                //assigny
                this.assigny=details.data['loans'][0]['assigny'];
                this.assFname=this.assigny.assFname;
                this.assLname=this.assigny.assLname;
                this.ass_cityname  =this.assigny.ass_cityname;
                this.district_name =this.assigny.district_name;
                this.assstatus =this.assigny.assstatus;
                










           //this.userdetailspagedata = details.msg[0].data[0];
     


           
           //console.log( ); return false;
          }else{
            console.log("ctwa user listing api not found"); return false;
          }
        });
        let ctwaWhatMess =  { results: [{ "tempid":this.loandid}] } ;
       
         
          this.ctwaService.getFeedbackCTWA(ctwaWhatMess)
          .subscribe((result: any) => {  
           // console.log(result.status); return false;
            if (result.status === true) {
             
              for (let i = 0; i < result.feedvalue.length; i++) {
                const element = result.feedvalue[i];
       
               if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                this.docPending.push(
                  { 
                    docPending:"1"
            
                  });
            
            
            
              }
               if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                this.docPending.push(
                  { 
                    docPending:"1"
            
                  });
            
            
            
              }
              
              
              
              if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                this.docPending.push(
                  { 
                    Received:"1"
            
                  });
              
              }
             
             
              if(element.data[0].lender_name == "LoanTap"){
                this.lenderloantabactive=1;
              }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                this.lenderHDBactive=1;
              }
             
                if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                  this.OldFeedbackMessage.push(
                    {
                     
                      admin_name: element.data[0].admin_name, 
                      feedback_id: element.data[0].feedback_id, 
                      feedback_message:  element.data[0].feedback_message, 
                      followupdate:  element.data[0].follow_update, 
                      interactiondate:  element.data[0].interactiondate, 
                      stage:  element.data[0].stage, 
                      user_id:  element.data[0].user_id, 
                      status:  element.data[0].status,

                     
                     
                    }
                    );
                }else{
                  if(element.data[0].stage === "21" ){
                    this.docReceiveduser.push({
                      docReceived : '1'
                    }
                    );
                   
                  }else{
                    this.docReceiveduser.push({
                      docReceived : '0'
                    }
                    );
                  }

                  this.elements_feedback.push(
                    {
                      admin_name: element.data[0].admin_name, 
                      feedback_id: element.data[0].feedback_id, 
                      feedback_message:  element.data[0].feedback_message, 
                      followupdate:  element.data[0].follow_update, 
                      interactiondate:  element.data[0].interactiondate, 
                      stage:  element.data[0].stage, 
                      user_id:  element.data[0].user_id, 
                      status:  element.data[0].status,
                      meraemi_stage:  element.data[0].meraemi_stage,
                      lender_name:  element.data[0].lender_name,
                      product_name:  element.data[0].product_name,
                      
                    }
                    );
                }


                
               
                 
                

                  
              }
              for (let i = 0; i <  this.docReceiveduser.length; i++) {
                const docrec = this.docReceiveduser[i];
                if(docrec.docReceived !='0'){
                  this.userdocReceived=1;
                 
                }

                
              }
              

             
              this.docPendingStatus=this.docPending[0]['docPending'];
              this.ReceivedStatus=this.docPending[0]['Received'];
             
            if(this.elements_feedback.length !=0){
              this.fedbacklength =this.elements_feedback.length;
            }else{
              this.popupaddBtn =0;
             
            }
              
             /// console.log(this.elements_feedback.length); 
          //  console.log(this.OldFeedbackMessage);
          //  console.log(this.elements_feedback);
           // this.popupbtnactive=this.meraemiStage[0].stage;
             //console.log(this.elements_feedback.length);
              
              this.fedbacklengthOld =this.OldFeedbackMessage.length;        
              this.message = result.message;
               
               
            }else{
              this.popupaddBtn =0;
              //("hello");
            }
          }); 
          let ctwausermess={ results: [{"loanid":(this.loandid)}]};
      //  get user details page api
      this.ctwaService.getUserWhatsappMsg(ctwausermess).subscribe(             
        (whamsg:any) => {
          //console.log(whamsg);
          if (whamsg.status === true) {
           
            for (let i = 0; i < whamsg.msg.length; i++) {
              const element = whamsg.msg[i];
              
              this.whataapmsg.push(
                {
                 
                  annual_turnover: element.data[0].annual_turnover, 
                  bus_nature: element.data[0].bus_nature, 
                  created_on:  element.data[0].created_on, 
                  created_by:  element.data[0].created_by, 
                  credit_history:  element.data[0].credit_history, 
                  ctwa_doc_url:  element.data[0].ctwa_doc_url, 
                  gst_filing:  element.data[0].gst_filing, 
                  loan_amount:  element.data[0].loan_amount, 
                  loan_id:  element.data[0].loan_id,


                  message: element.data[0].message, 
                  message_id: element.data[0].message_id, 
                  message_title:  element.data[0].message_title, 
                  message_type:  element.data[0].message_type, 
                  office_Status:  element.data[0].office_Status, 
                  office_type:  element.data[0].office_type, 
                  residence_status:  element.data[0].residence_status, 
                  


                 
                 
                }
                );
              
            }
           }else{
             console.log("ctwa user listing api not found"); return false;
           }
          //console.log(this.whataapmsg); return false;
         
        });

  }
  assignSubmitted: boolean = false;
  get rs() { return (<FormGroup>this.registerForm.get('userassignedby')).controls; }
  get userassignedby() {
    return this.registerForm.get('userassignedby');
  }
  feedbackPopup(data1){

    this.feedbackloanid=data1;
     
     // console.log(data2);
      this.FeedbackPopup=1;
     
    }
  onClick(check){
    //console.log(check);
    if(check==1){
      this.tab = 'tab1';
      this.tabval=1;
    }else if(check==2){
      this.tab = 'tab2';
      this.tabval=2;
     // console.log(this.cust);
    }else if(check==3){
      this.tab = 'tab3';
      this.tabval=3;
     // console.log(this.cust);
    }     
 // alert(this.tabval);
}
get authState$(): Observable<User|null> {
  return this.afAuth.user;
}

  //intraktUrlAPIlisting
  intraktuserlisting(data){
    // console.log(data); return false;
  
      
      const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
     

     const dateur=base_url+"91"+data.toString();
    
     // //console.log(detailsurl);
      window.open(dateur, "_blank");
      

    }
    assignedToSatff(tempid,fname,lname){
      this.tempid=tempid;
      this.uname=fname+' '+lname;
     // this.optionCity.length= 0;
     // this.cityArray.length= 0;
    
  
      //console.log(user);
      this.assigned.show();
    }
    stateid(selectedValue: any): void {
      this.cityArray=[];
      this.optionCity=[];
      console.log(selectedValue);
      this.adminServiceService.getBanchCityMater(selectedValue)
        .subscribe((res:any) => {
          this.optionCity.length= 0;
          this.cityArray.length= 0;
         // console.log(res); return false;
          if (res.status === true) {
            //this.optionCity=[];
          //  this.cityArray=[];
            for (let i = 0; i < res.city.length; i++) {
              const menuarray = res.city[i];
             
           
              this.cityArray.push(
                {
                  value: menuarray.data[0].city_id,
                  label: menuarray.data[0].city_name,                          
                 
                }
                );
                //console.log(this.messages);
            
            
               
                
            }
            this.optionCity =this.cityArray;
            console.log(this.optionCity);
          }else{
            console.log('City Api faile');
          }
        });
      
     
      // Do something with the selected value
    }
    assignbyuserbtn(){
      this.assignSubmitted = true;
      this.showLoader = false;
  
      // stop here if form is invalid
      if (this.userassignedby.invalid) {
        setTimeout(() => {
          this.showLoader = true;;
        }, 2000);
  
        return;
      }else{
        this.showLoader = false;
        const tempid     = this.tempid;
        const loginuserid = this.loginuserid;
        const cityid= this.userassignedby.value.city;
        const state= this.userassignedby.value.state;
        let assUser={ results: [{"adminid":loginuserid,"tempid":tempid,"cityid":cityid}]};
       // this.elements=[];
        //console.log(assUser);
        this.adminServiceService.assigByctwaUser(assUser)
        .subscribe((asUserData: any) => {
          if(asUserData.response ===200 && asUserData.status === true){
            this.assigned.hide();
            let ctwaleadDetails={ results: [{"loanid":(this.loandid)}]};
      //console.log(ctwaleadDetails);
      //  get user details page api
      this.ctwaService.GetUserLeadDetails(this.loandid).subscribe(             
        (details:any) => {
         console.log(details);
          if (details.status === true) {

            //aboutMe
            this.aboutMe=details.data['loans'][0]['aboutMe'];
                    this.education_qualification=this.aboutMe.education_qualification;
                    this.since_residing_here=this.aboutMe.since_residing_here;
                    this.father_first_name=this.aboutMe.father_first_name;
                    this.father_last_name=this.aboutMe.father_last_name;
                    this.marital_status=this.aboutMe.marital_status;
                    this.gender=this.aboutMe.gender;
                    this.residence_status=this.aboutMe.residence_status;
                    this.pen_no=this.aboutMe.pen_no;
                    this.mother_name=this.aboutMe.mother_name;
           // console.log( this.aboutMe.education_qualification); return false;
            //address
            this.address=details.data['loans'][0]['address'];
            this.address_1=this.address.address_1;
            this.address_2=this.address.address_2;
            this.address_3=this.address.address_3;
            this.address_landmark=this.address.address_landmark;
            this.address_pin_code=this.address.address_pin_code;
            //bussDetails
            this.bussDetails=details.data['loans'][0]['bussDetails'];
                        this.employment_type=this.bussDetails.employment_type;
                        this.industry_name=this.bussDetails.industry_name;
                        this.employer_catg=this.bussDetails.employer_catg;
                        this.bus_year_name=this.bussDetails.bus_year_name;
                        this.bus_nature_type=this.bussDetails.bus_nature_type;
                        this.prev_fin_year=this.bussDetails.prev_fin_year;
                        this.prev_fin_year=this.bussDetails.prev_fin_year;
                        this.short_name=this.bussDetails.short_name;
                        this.estb_status=this.bussDetails.estb_status;
                        this.dscription=this.bussDetails.dscription;
            //eligStage
            this.eligStage=details.data['loans'][0]['eligStage'];
                        
                        this.elig_status=this.eligStage.elig_status;
                        this.elig_date=this.eligStage.elig_date;
            //loanapp
            this.loanapp=details.data['loans'][0]['loanapp'];
                            this.loan_code=this.loanapp.loan_code;
                            this.city_name=this.loanapp.city_name;
                            this.loan_amt=this.loanapp.loan_amt;
                            this.tenure=this.loanapp.tenure;
            //loginuser
            this.loginuser=details.data['loans'][0]['loginuser'];
                      this.email_id=this.loginuser.email_id;
                      this.ad_id=this.loginuser.ad_id;
                      this.user_id=this.loginuser.user_id;
                      this.dob=this.loginuser.dob;
                      this.mobile_no=this.loginuser.mobile_no;
                      this.last_name=this.loginuser.last_name;
                      this.first_name=this.loginuser.first_name;
                      this.provider_id=this.loginuser.provider_id;
                      this.lead_id=this.loginuser.lead_id;
                     // this.logindate=this.loginuser.lead_id;
            //tempdata
            this.tempdata=details.data['loans'][0]['tempdata'];
                 this.tempdate=this.tempdata.tempdate;
                this.templastname=this.tempdata.templastname;
                this.tempfistnale=this.tempdata.tempfistnale;
                this.tempid=this.tempdata.temp_id;
                this.tempemail=this.loginuser.tempemail;
                

                //assigny
                this.assigny=details.data['loans'][0]['assigny'];
                this.assFname=this.assigny.assFname;
                this.assLname=this.assigny.assLname;
                this.ass_cityname  =this.assigny.ass_cityname;
                this.district_name =this.assigny.district_name;
                this.assstatus =this.assigny.assstatus;
                










           //this.userdetailspagedata = details.msg[0].data[0];
     


           
           //console.log( ); return false;
          }else{
            console.log("ctwa user listing api not found"); return false;
          }
        });
          }else{
            console.log("user listing api error");
          }

       
         

         
        });
      }
    }
}
