import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Leads } from 'src/app/model/admin/leads.model';
import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class LenderContactService {
  message: any;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;


  getLenderName(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'lenderCont/getLenderName',{headers: headers});
  }


  PostLenderContact(data){  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'lenderCont/createLenderContact',{data:data},{headers: headers});
     console.log(headers);
   }
   GetAllLendercontact(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'lenderCont/getAllLendercontact',{headers: headers});
  }
  getProductName(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'lenderCont/getProductName',{headers: headers});
  }
  PostRateofIntrest(data){  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'getLenderOfferList/PostRateofIntrest',{data:data},{headers: headers});
     console.log(headers);
   }
   getLenderProdOffer(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'getLenderOfferList/GetLenderProdOffer',{headers: headers});
  }
  EditRateofIntrest(data){  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'getLenderOfferList/EditRateofIntrest',{data:data},{headers: headers});
     console.log(headers);
   }
   DeleteRateofIntrest(data){  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'getLenderOfferList/DeleteRateofIntrest',{data:data},{headers: headers});
     console.log(headers);
   }
   getSendemailUser(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'EmailAutomationMasters/getMailgunSendEmailUser',{headers: headers});
  }
  PostEmailCampainIntrest(data){  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'EmailAutomationMasters/PostEmailAutoCampainIntrest',{data:data},{headers: headers});
     console.log(headers);
   }
   GetEmailCampain(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    return this.http.get<any>(`${this.global.apiurl}`+'EmailAutomationMasters/GetEmailCampain',{headers: headers});
  }
}
