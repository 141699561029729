import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
    getBusinessPartnerData(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'businessPartner/getBusinessPartnerData',{headers:headers});
    }
  
    getBusinessPartnerDetails(pid){    
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'businessPartner/getBusinessPartnerDetails/'+pid+'',{headers:headers});
    
    }
  
}
