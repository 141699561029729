import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  AngularFireAuth
} from 'angularfire2/auth';
import {
  Router
} from '@angular/router';



@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  setpasswordForm: FormGroup;
  submitted: boolean;
  error: string;
  progress: boolean;
  errorMessage: string;
  successMessage: string;
  success: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public afAuth: AngularFireAuth,
    
   // private report: ReportService,
  ) {}

  ngOnInit() {
    this.setpasswordForm = this.formBuilder.group({
      set_pass_email: ['', [Validators.required, Validators.email]],
    });
  }
  private showError(error: string) {

    this.error = error;
    this.progress = false;
    setTimeout(() => this.error = null, 5000);
  }
  private showSuccess(message: string) {

    this.success = message;
    this.progress = true;
    setTimeout(() => this.successMessage = null, 5000);
  }
  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
  //  this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }
  get f() {
    return this.setpasswordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.setpasswordForm.invalid) {
      return;
    }
    const value = this.forgotPassword(this.f.set_pass_email.value);
    console.log(value)
  }
  public forgotPassword(email: string): Promise < void > {
    const actionCodeSettings = {
      url: location.origin + '/#/reset-password',
      handleCodeInApp: true,
    }
    console.log("Resetting the password for: " + email);
    return this.afAuth.auth.sendPasswordResetEmail(email, actionCodeSettings)
      .then(
        // () => this.reportSuccess('Signed in as ' + email,'/login')
        (res) => {
          this.success = "Password reset link is sent to your email address";
          this.showSuccess(this.success);
          setTimeout(() => this.reportSuccess('Signed in as ' + email, '/login'), 5000);
        },
        error => {
          console.log(error);
          if (error.code == 'auth/user-not-found') {
            this.errorMessage = "Username doesn't exist.";
          }
          this.showError(this.errorMessage);

        }
      )

    //   .catch(error => {
    //      console.log(error)
    //  //   this.showError(error.message);
    //     if (error.code == 'auth/user-not-found') {
    //       this.errorMessage = "Username doesn't exist.";
    //     }
    //     this.showError(this.errorMessage);
    //   });
  }

}
