import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';

import { TokenService } from 'src/app/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;

    getBanchStateMater(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'admin/getBanchStateMater',{headers:headers});
    }
    getBanchCityMater(stid){
      //console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'admin/getBanchCityMater/'+stid+'',{headers:headers});
     // console.log(url);
    }
    assigByctwaUser(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'ctwa/assigByctwaUser',{data:data},{headers: headers});
       console.log(headers);
     }
     assigByUser(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'admin/assigByUser',{data:data},{headers: headers});
       console.log(headers);
     }
     getBusinessYear(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusinessYear',{headers:headers});
    }
    getBusinessCategory(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusinessCategory',{headers:headers});
    }
    getIndustry(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getIndustry',{headers:headers});
    }
    getBusNatureMaster(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getBusNatureMaster',{headers:headers});
    }
    getDocumentproofBusiness(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getDocumentproofBusiness',{headers:headers});
    }
    getCreditPofile(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getCreditPofile',{headers:headers});
    }
    getProduct(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getProduct',{headers:headers});
    }
    getEmploymentTyp(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'WhatsappIAP/getEmploymentTyp',{headers:headers});
    }
    insertUserForEligibilityComplete(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/insertUserForEligibilityComplete',{data:data},{headers: headers});
   
     }
     //
     GetUserLeadDetails(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/GetUserLeadDetails',{data:data},{headers: headers});
   
     }
     insertUserForLead(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/insertUserForLead',{data:data},{headers: headers});
   
     }
     
     eligibilityInsertUser(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'WhatsappIAP/insertUserForEligibility',{data:data},{headers: headers});
   
     }
     
     deleteUserData(uid){
      //console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'admin/deleteUserData/'+uid+'',{headers:headers});
     // console.log(url);
    }
}
