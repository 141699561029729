import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mastercalculator',
  templateUrl: './mastercalculator.component.html',
  styleUrls: ['./mastercalculator.component.scss']
})
export class MastercalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
