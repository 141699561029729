import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { MasterService } from 'src/app/services/master/master.service';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';

export interface Color {
  name: string;
}
@Component({
  selector: 'app-crm-status',
  templateUrl: './crm-status.component.html',
  styleUrls: ['./crm-status.component.scss']
})
export class CrmStatusComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("add", {static: true}) add: ModalDirective;
  @ViewChild("edit", {static: true}) edit: ModalDirective;
  searchText: any;
  elements: any = [];
  previous: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
  headElements = [
    {
       name:"crm Stage  Id",
       sortBy:"Doc Id",
       width: 150
     },
     {
      name:"crm Status Name",
      sortBy:"Doc Category Name",
      width: 150
    },
    {
      name:"Action",
      sortBy:"Action",
      width: 150
    }

   ];
  meraemi_stage: any;
  stage_id: any;
  stageid: any;

  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private masterService : MasterService,  
      private ctwaService:CtwaService,
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({

      addformgup: this.formBuilder.group({
       addname: ['', Validators.required],
       
        
      }),
      editformgup: this.formBuilder.group({
       editname: ['', Validators.required]
      
    }),
      
    });
    this.ctwaService
    .GetMeraemiStage()
    .subscribe( (result:any) => {
   // console.log(result); return false;
    
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.meraemistage.length;  i++) {
          const element = result.meraemistage[i];
       
           
                            this.elements.push(
                              {
                                stage_id	:element.data[0].stage_id,
                                meraemi_stage: element.data[0].meraemi_stage,
                               
                           
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
  // add  
  submittedAdd = true;
  get rs() { return (<FormGroup>this.registerForm.get('addformgup')).controls; }
  get addformgup() {
    return this.registerForm.get('addformgup');
  }
  // edit 

  submittedEdit = true;
  get ed() { return (<FormGroup>this.registerForm.get('editformgup')).controls; }
  get editformgup() {
    return this.registerForm.get('editformgup');
  }
    //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(7);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 


   editformdata(data){
    // console.log(data);
     this.stage_id=data.stage_id;
     this.meraemi_stage   =data.meraemi_stage;
     
 
     
     this.showLoader = false;
     setTimeout(() => {
     this.edit.show();
       this.showLoader = true;
     }, 1000);
    
   }
   editbtn(){
     this.submittedEdit = true;
     this.showLoader = false;
   
     // stop here if form is invalid
     if (this.editformgup.invalid) {
      // console.log("err");
       setTimeout(() => {
         this.showLoader = true;
       }, 2000);
   
       return;
     }else{
   
       let editname=this.editformgup.value.editname;
       
       let detail = { results: [{ "editname":editname,"stage_id":this.stage_id}] };
       detail['results']; 
    //   console.log(detail);
       this.masterService.editMeraEMIStageMaster(detail).subscribe((result: any) => {
   
         if(result.response === 200 && result.status === true){
           this.showLoader = true;
           this.elements=[];
          
             this.edit.hide();
             this.ctwaService
             .GetMeraemiStage()
             .subscribe( (result:any) => {
            // console.log(result); return false;
             
               if (result.status = true  && result.response=== 200) {      //        
                 for (let i = 0; i < result.meraemistage.length;  i++) {
                   const element = result.meraemistage[i];
                
                    
                                     this.elements.push(
                                       {
                                         stage_id	:element.data[0].stage_id,
                                         meraemi_stage: element.data[0].meraemi_stage,
                                        
                                    
                                                   
                                         
                                         
                                         
         
                                       }
                                       );
                                       
                                         
                     }
                   //console.log(this.elements);
                     this.mdbTable.setDataSource(this.elements);
                     this.elements = this.mdbTable.getDataSource();
                     this.previous = this.mdbTable.getDataSource();
                     }else{
                       console.log('User list Api faile');
                     }
                     
                   });
         }
       
       });
     }
   }
   addBtn(){
     this.submittedAdd = true;
     this.showLoader = false;
 
     // stop here if form is invalid
     if (this.addformgup.invalid) {
       console.log("err");
       setTimeout(() => {
         this.showLoader = true;
       }, 2000);
 
       return;
     }else{
       let addname=this.addformgup.value.addname;
      
 
     
       this.masterService.addMeraEMIStageMaster(addname).subscribe((add:any) => {
         console.log(add);
         if(add.response == 200 && add.status == true){
           this.showLoader = true;
           this.addformgup.get('addname').setValue('');
          
           this.elements=[];
           this.add.hide();
           this.ctwaService
            .GetMeraemiStage()
            .subscribe( (result:any) => {
          // console.log(result); return false;
            
              if (result.status = true  && result.response=== 200) {      //        
                for (let i = 0; i < result.meraemistage.length;  i++) {
                  const element = result.meraemistage[i];
              
                  
                                    this.elements.push(
                                      {
                                        stage_id	:element.data[0].stage_id,
                                        meraemi_stage: element.data[0].meraemi_stage,
                                      
                                  
                                                  
                                        
                                        
                                        

                                      }
                                      );
                                      
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
                
         }
       
       });
     
 
     }
   }
   deleteForm(data){
     this.showLoader = false;
     setTimeout(() => {
       this.showLoader = true;
     }, 2000);
   console.log(data);
     
     this.stageid=data.stage_id;    
     this.masterService.deleteMeraEMIStageMaster(this.stageid)
     .subscribe((del:any) => {
       if(del.response === 200 && del.status === true ){
         this.elements=[];          
         this.ctwaService
         .GetMeraemiStage()
         .subscribe( (result:any) => {
        // console.log(result); return false;
         
           if (result.status = true  && result.response=== 200) {      //        
             for (let i = 0; i < result.meraemistage.length;  i++) {
               const element = result.meraemistage[i];
            
                
                                 this.elements.push(
                                   {
                                     stage_id	:element.data[0].stage_id,
                                     meraemi_stage: element.data[0].meraemi_stage,
                                    
                                
                                               
                                     
                                     
                                     
     
                                   }
                                   );
                                   
                                     
                 }
               //console.log(this.elements);
                 this.mdbTable.setDataSource(this.elements);
                 this.elements = this.mdbTable.getDataSource();
                 this.previous = this.mdbTable.getDataSource();
                 }else{
                   console.log('User list Api faile');
                 }
                 
               });
       }else{
         console.log('User list Api faile');
       }
 
     });
   }
 





        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}