import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';

import { TokenService } from 'src/app/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
    //--------------------------doc master api serbvice---------- Edit,delete,add----------------------------------
  
    deleteDoc(docid){  
      console.log(docid);   
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteDoc/'+docid+'',{headers:headers});    
    }
    editDoc(data){  
      console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'master/editDoc',{data:data},{headers: headers});
       console.log(headers);
     }
     addDoc(data){  
     // console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'master/addDoc',{data:data},{headers: headers});
       console.log(headers);
     }
  
 
    //--------------------------Buss Year Name--------------------------------------------
    
    addBussYearmaster(bussYearName){  
      //console.log(docid);   
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'master/addBussYearmaster/'+bussYearName+'',{headers:headers});    
    }
    
    editBussYearmaster(data){  
      console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'master/editBussYearmaster',{data:data},{headers: headers});
       console.log(headers);
     }
     deleteBussYearName(busid){  
   //   console.log(busid);   
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteBussYearName/'+busid+'',{headers:headers});    
    }
    
  //--------------------------Buss Category Master--------------------------------------------

  
    getBussCatMaster(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'master/getBussCatMaster',{headers:headers});
    }
    addBussCategorymaster(data){  
      console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'master/addBussCategorymaster',{data:data},{headers: headers});
       //console.log(headers);
     }
     
     editEmpCatmaster(data){  
      //console.log(data);
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'master/editEmpCatmaster',{data:data},{headers: headers});
      // console.log(headers);
     }
     
     deleteempcat(empcatid){  
      //   console.log(busid);   
         let headers = new HttpHeaders().set('Content-Type', 'application/json')
         .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
         return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteempcat/'+empcatid+'',{headers:headers});    
       }
         //--------------------------Doc Category Master--------------------------------------------
       
         addDocCatmaster(addname){  
        //console.log(docid);   
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
        return this.http.get<Global>(`${this.global.apiurl}`+'master/addDocCatmaster/'+addname+'',{headers:headers});    
      }
      
      editDocCatMaster(data){  
        console.log(data);
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
         .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
         return this.http.post<any>(`${this.global.apiurl}`+'master/editDocCatMaster',{data:data},{headers: headers});
         console.log(headers);
       }

       
       deleteDocCatname(doccatid){  
        //   console.log(busid);   
           let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
           return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteDocCatname/'+doccatid+'',{headers:headers});    
         }

         
         //--------------------------Product Master--------------------------------------------
       
    
        addProductmaster(data){  
          console.log(data);
          let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
           return this.http.post<any>(`${this.global.apiurl}`+'master/addProductmaster',{data:data},{headers: headers});
           console.log(headers);
         }
        editProductmaster(data){  
          console.log(data);
          let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
           return this.http.post<any>(`${this.global.apiurl}`+'master/editProductmaster',{data:data},{headers: headers});
           console.log(headers);
         }
         
         deleteProduct(prodid){  
          //   console.log(busid);   
             let headers = new HttpHeaders().set('Content-Type', 'application/json')
             .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
             return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteProduct/'+prodid+'',{headers:headers});    
           }

           
           //--------------------------Emp Type Master--------------------------------------------
       
    
           addEmpyTypemaster(addname){  
            //console.log(docid);   
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
            return this.http.get<Global>(`${this.global.apiurl}`+'master/addEmpyTypemaster/'+addname+'',{headers:headers});    
          }
          editEmpTypemaster(data){  
          console.log(data);
          let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
           return this.http.post<any>(`${this.global.apiurl}`+'master/editEmpTypemaster',{data:data},{headers: headers});
           console.log(headers);
         }
         
         deleteEmpType(empid){  
          //   console.log(busid);   
             let headers = new HttpHeaders().set('Content-Type', 'application/json')
             .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
             return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteEmpType/'+empid+'',{headers:headers});    
           }
           //--------------------------industry Master--------------------------------------------
       
    
           addIndustryMaster(addname){  
            //console.log(docid);   
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
            return this.http.get<Global>(`${this.global.apiurl}`+'master/addIndustryMaster/'+addname+'',{headers:headers});    
          }
          editIndustryMaster(data){  
          console.log(data);
          let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
           return this.http.post<any>(`${this.global.apiurl}`+'master/editIndustryMaster',{data:data},{headers: headers});
           console.log(headers);
         }
         
         deleteIndustryMaster(indid){  
          //   console.log(busid);   
             let headers = new HttpHeaders().set('Content-Type', 'application/json')
             .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
             return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteIndustryMaster/'+indid+'',{headers:headers});    
           }
          //--------------------------Bus Nature Master Master--------------------------------------------
       
    
                   addBusNatureMaster(addname){  
                    //console.log(docid);   
                    let headers = new HttpHeaders().set('Content-Type', 'application/json')
                    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
                    return this.http.get<Global>(`${this.global.apiurl}`+'master/addBusNatureMaster/'+addname+'',{headers:headers});    
                  }
                  editBusNatureMaster(data){  
                  console.log(data);
                  let headers = new HttpHeaders().set('Content-Type', 'application/json')
                   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
                   return this.http.post<any>(`${this.global.apiurl}`+'master/editBusNatureMaster',{data:data},{headers: headers});
                   console.log(headers);
                 }
                 
                 deleteBusNatureMaster(busid){  
                  //   console.log(busid);   
                     let headers = new HttpHeaders().set('Content-Type', 'application/json')
                     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
                     return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteBusNatureMaster/'+busid+'',{headers:headers});    
                   }

          //--------------------------MeraEMI Stage Master--------------------------------------------
          addMeraEMIStageMaster(addname){  
            //console.log(docid);   
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
            return this.http.get<Global>(`${this.global.apiurl}`+'master/addMeraEMIStageMaster/'+addname+'',{headers:headers});    
          }
          editMeraEMIStageMaster(data){  
          console.log(data);
          let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
           return this.http.post<any>(`${this.global.apiurl}`+'master/editMeraEMIStageMaster',{data:data},{headers: headers});
           console.log(headers);
         }
         
         deleteMeraEMIStageMaster(busid){  
          //   console.log(busid);   
             let headers = new HttpHeaders().set('Content-Type', 'application/json')
             .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
             return this.http.get<Global>(`${this.global.apiurl}`+'master/deleteMeraEMIStageMaster/'+busid+'',{headers:headers});    
           }


  //--------------------------Crm Stage Master--------------------------------------------
       getCrmStageMaster(){
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
          .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
          return this.http.get<Global>(`${this.global.apiurl}`+'master/getCrmStageMaster',{headers:headers});
          }

          addCrmStageMaster(addname){  
            //console.log(docid);   
            let headers = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
            return this.http.get<Global>(`${this.global.apiurl}`+'master/addCrmStageMaster/'+addname+'',{headers:headers});    
          }
          editCrmStageMaster(data){  
          console.log(data);
          let headers = new HttpHeaders().set('Content-Type', 'application/json')
           .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
           return this.http.post<any>(`${this.global.apiurl}`+'master/edit_meraEMIStage',{data:data},{headers: headers});
           console.log(headers);
         }
         
         deleteMeraEMIStage(crm_stageid){  
          //   console.log(crm_stageid);   
             let headers = new HttpHeaders().set('Content-Type', 'application/json')
             .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
             return this.http.get<Global>(`${this.global.apiurl}`+'master/delete_meraEMIStage/'+crm_stageid+'',{headers:headers});    
           }
}
