import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';


import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import { AdminService } from 'src/app/services/admin/admin.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';

export interface Color {
  name: string;
}
@Component({
  selector: 'app-user-listin',
  templateUrl: './user-listin.component.html',
  styleUrls: ['./user-listin.component.scss']
})
export class UserListinComponent implements OnInit, AfterViewInit {
  production: true;
  
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  @ViewChild("salary", {static: true}) salary: ModalDirective;
  @ViewChild("assigned", {static: true}) assigned: ModalDirective;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elementsalary: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
   optionsArray: any = []; 
   messages: any = []; 
  headElements = [
    {
       name:"User Id",
       sortBy:"user_id",
       width: 150
     },
     {
      name:"Profile",
      sortBy:"Profile",
      width: 150
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     {
       name:"Product",
       sortBy:"Product"
     },
 
     
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Source",
       sortBy:"Source"
     },
     {
       name:"Location",
       sortBy:"Location"
     },
     {
      name:"Loan Amount",
      sortBy:"user_monthly_income"
    },
     
     {
       name:"Last  Date",
       sortBy:"reg_date"
     },  
      
     {
       name:"Stage",
       sortBy:"status"
     },
      {
      name:"CRM Status",
      sortBy:"MeraEmi"
     },
   
     
    {
     name:"Assign to Branch",
     sortBy:"Assig to"
   },  {
    name:"Create By",
    sortBy:"Create By"
  }, 
   
   {
     name:"View",
     sortBy:"send_message"
   },
    

   ];
  
  appstage: string;
  elig_status: string;
 
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id:number = 0;
  to_user_id: number = 0;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  resultpush: any = [];
  responseArray:any = [];
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activelender: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  lendernamearray: any = [];
  stateArray: any = [];
  optionslocation: any = []; 
  optionState: any = []; 
  
  cityArray: any = [];
  optionCity: any = [];
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
 
  showLoader: boolean;
  lender_name: any;

  first_name: string;
  last_name: string;
  fnamelname: string;
  lenderfilter:string;
  loan_lender:any = [0];
  lender_filter: any;
  meraemistage: string;

  CrmStatus: any = [];
  UserStatus: any = [];
  crm_status: any = [];
  user_status: any = [];  
  crmstage:  any;
  userstage : any;
  meraemi_stage :any = [0];
  meraemi_sub_stage:any = [0];
 activeCrmstage:string;
  activeUserstage:string;
 Modaloneidid: number = 0;
 updateuserad: number = 0;
  adupdatebtnEnable: number= 0;

  usersal: number=0;
  fromSalary: number=0;
  toSalary: number=0;
  activeIncome: string;
  dbuserid:number=0;
  lendername: any;
  crmstages: any;
  userstages: any;
  empcategory: string;
  elementsMainfilter: any = [];
  uid: any;
  uname: any;
  user:any;
  rolecode: any;
  fname: any;
  lname: any;
  loginuserid: any;
  cityid: any;
  crmstageArray: any = [];
  constructor( 
      private cryptojsService:CryptojsService,
      private FeedbackService:FeedbackService,    
      
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
     
      private loginServiceService: LoginServiceService,
      private ctwaService:CtwaService,
      private adminService:AdminService,
      
      ) { }

  ngOnInit() {
    this.user=this.loginServiceService.userData();
   ///console.log(this.user);
    if(this.user.length !=0){
      this.cityid=(JSON.parse(this.user)['cityname']);
      this.loginuserid= (JSON.parse(this.user)['fb_uid']);
      this.rolecode= (JSON.parse(this.user)['rolecode']);
      this.fname= (JSON.parse(this.user)['first_name']);
      this.lname= (JSON.parse(this.user)['last_name']); 
     }
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.adminService
    .getBanchStateMater()
    .subscribe( (st:any) => {
      //console.log(st);
            if (st.status === true) {
              
              for (let i = 0; i < st.state.length; i++) {
                const menuarray = st.state[i];
               
             
                this.stateArray.push(
                  {
                    value: menuarray.data[0].district_id,
                    label: menuarray.data[0].district_name,                          
                   
                  }
                  );
                  //console.log(this.messages);
              
              
                 
                  
              }
              this.optionState =this.stateArray;
             // console.log(this.optionState);
            }else{
              console.log('District Api faile');
            }
            
          });

          this.ctwaService
          .GetMeraemiStage()
          .subscribe( (result:any) => {
         // console.log(result); return false;
          
            if (result.status = true  && result.response=== 200) {           
              for (let i = 0; i < result.meraemistage.length;  i++) {
                const element = result.meraemistage[i];
                this.crmstageArray.push(
                  {
                    value: element.data[0].meraemi_stage,
                    label: element.data[0].meraemi_stage,                          
                   
                  }
                  );
              }
              this.crm_status=this.crmstageArray;
            }else{
              console.log('crm stage  Api faile');
            }
            });


    this.optionsSelect= [

           
      { value: 'whatsapp', label: 'whatsapp' },
      
      ];  
      this.optionsStage= [
      { value: 'Eligibility Pending', label: 'Eligibility Pending' },      
      { value: 'Eligibility Clear', label: 'Eligibility Clear' },      
      { value: 'Eligibility Fail', label: 'Eligibility Fail' },
      { value: 'Criteria Fail', label: 'Criteria Fail' },      
      { value: 'IAP Pending', label: 'IAP Pending' },
      { value: 'IAP Complete', label: 'IAP Complete' },
      { value: 'IAP Error', label: 'IAP Error' },
      { value: 'IAP Fail', label: 'IAP Fail' },
      { value: 'IAP Success', label: 'IAP Success' },    
      { value: 'Success', label: 'Success' },
        
        ];  
        


             
  

    this.registerForm = this.formBuilder.group({
      idFilter: this.formBuilder.group({
        fromuserid: ['', Validators.required],
        touserid: ['', Validators.required],
      }),
      sourceFilter: this.formBuilder.group({
        source: ['', Validators.required]
      }),
      adidFilter: this.formBuilder.group({
        adid: ['', Validators.required]
      }),
      loanFilter: this.formBuilder.group({
        fromLonAmt: ['', Validators.required],
        toLonAmt: ['', Validators.required],
      }),
      stageFilter: this.formBuilder.group({
        stage: ['', Validators.required]
       
      }),
      dateFilter: this.formBuilder.group({
        fromdate: ['', Validators.required],
        todate: ['', Validators.required],
       
      }),
      locationFilter: this.formBuilder.group({
        location: ['', Validators.required],
       
      }),
       lendernamefilter: this.formBuilder.group({
        lendername: ['', Validators.required],
       
      }),
      crmstagefilter: this.formBuilder.group({
        crmstage: ['', Validators.required],
       
      }),
      userstagefilter: this.formBuilder.group({
        userstage: ['', Validators.required],
       
      }),
      adidedit: this.formBuilder.group({
        adids: ['', Validators.required]
      }),
      usersalary: this.formBuilder.group({
        fromusersal: ['', Validators.required],
        tousersal: ['', Validators.required],
      }),
       userassignedby: this.formBuilder.group({
        state: ['', Validators.required],
        city: ['', Validators.required]
      }),
    });
  
    // console.log(this.rolecode);
    if(this.rolecode === "AD"){
          this.ctwaService
          .GetUserCTWAList()
          .subscribe( (result:any) => {
          // console.log(result); return false;
          
            if (result.status = true  && result.response=== 200) {      //        
              for (let i = 0; i < result.admin.length;  i++) {
                const element = result.admin[i];
              
             
                        
                  
                        if(element.data[0].elig_status  === "0"){
                          this.elig_status ='Eligibility Pending';                        
                        }else{
                          this.elig_status ='Eligibility Clear';                         
                        }
                    
                        this.appstage = this.elig_status;      
                 
                      if(element.data[0].feedback_stage !=undefined){
                        this.meraemistage=element.data[0].feedback_stage;
                      }else{
                        this.meraemistage="Pending Review";
                      }                   
                                      
                    this.fnamelname= element.data[0].first_name+' '+element.data[0].last_name



                    if(element.data[0].salaried==="2"){
                      this.empcategory="SEP";

                    }
                    if(element.data[0].feedback_stage !="na"){
                      this.meraemistage=element.data[0].feedback_stage;
                    }else{
                      this.meraemistage="Pending Review";
                    }
                  // console.log(element.data[0].user_stage);
                  //   console.log(this.lender_name);
                                  this.elements.push(
                                    {
                                      user_id: element.data[0].user_id, 
                                      empcategory:element.data[0].employment_type,
                                      ad_id: element.data[0].ad_id,
                                      name:  this.fnamelname,                                                
                                      provider:  element.data[0].provider_id,
                                      city_name: element.data[0].location,
                                      loan_amount: element.data[0].loan_amount,                                     
                                      reg_date: element.data[0].date,
                                      status: this.appstage,  
                                      feedback_stage:this.meraemistage,                                    
                                      mobile_number	:element.data[0].mobile_number,
                                      ass_cityname	:element.data[0].ass_cityname,
                                      ass_status	:element.data[0].ass_status,
                                      district_name	:element.data[0].district_name,
                                      product_code	:element.data[0].product_code,
                                      createbyfname	:element.data[0].createbyfname,
                                      createbylname	:element.data[0].createbylname,
                                      
                                      
                                    }
                                    );
                                    this.element_main.push(
                                      {
                                        user_id: element.data[0].user_id, 
                                        empcategory:"Self Employed",
                                        ad_id: element.data[0].ad_id,
                                        name:  this.fnamelname,                                                
                                        provider:  element.data[0].provider_id,
                                        city_name: element.data[0].location,
                                        loan_amount: element.data[0].loan_amount,                                     
                                        reg_date: element.data[0].date,
                                        status: this.appstage,  
                                        feedback_stage:this.meraemistage,                                    
                                        mobile_number	:element.data[0].mobile_number,
                                        ass_cityname	:element.data[0].ass_cityname,
                                        ass_status	:element.data[0].ass_status,
                                        district_name	:element.data[0].district_name,
                                        product_code	:element.data[0].product_code,
                                        createbyfname	:element.data[0].createbyfname,
                                        createbylname	:element.data[0].createbylname,
                  
                                      }
                                      );
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
         
    }else{
      this.ctwaService.getAssignedByUserList(this.cityid)
      .subscribe( (result:any) => {
       // console.log(result);
       if (result.status = true  && result.response=== 200) {            
        for (let i = 0; i < result.ctwa.length;  i++) {
               const element = result.ctwa[i];
               
               if(element.data[0].elig_status  === "0"){
                this.elig_status ='Eligibility Pending';                        
              }else{
                this.elig_status ='Eligibility Clear';                         
              }
          
              this.appstage = this.elig_status;      
       
            if(element.data[0].feedback_stage !=undefined){
              this.meraemistage=element.data[0].feedback_stage;
            }else{
              this.meraemistage="Pending Review";
            }                   
                            
          this.fnamelname= element.data[0].first_name+' '+element.data[0].last_name;
          if(element.data[0].feedback_stage !="na"){
            this.meraemistage=element.data[0].feedback_stage;
          }else{
            this.meraemistage="Pending Review";
          }
          this.elements.push(
            {
              user_id: element.data[0].user_id, 
              empcategory:element.data[0].employment_type,
              ad_id: element.data[0].ad_id,
              name:  this.fnamelname,                                                
              provider:  element.data[0].provider_id,
              city_name: element.data[0].location,
              loan_amount: element.data[0].loan_amount,                                     
              reg_date: element.data[0].date,
              status: this.appstage,  
              feedback_stage:this.meraemistage,                                    
              mobile_number	:element.data[0].mobile_number,
              ass_cityname	:element.data[0].ass_cityname,
              ass_status	:element.data[0].ass_status,
              district_name	:element.data[0].district_name,
              product_code	:element.data[0].product_code,
              createbyfname	:"na",
            
              
              
            }
            );
            this.element_main.push(
              {
                user_id: element.data[0].user_id, 
                empcategory:"Self Employed",
                ad_id: element.data[0].ad_id,
                name:  this.fnamelname,                                                
                provider:  element.data[0].provider_id,
                city_name: element.data[0].location,
                loan_amount: element.data[0].loan_amount,                                     
                reg_date: element.data[0].date,
                status: this.appstage,  
                feedback_stage:this.meraemistage,                                    
                mobile_number	:element.data[0].mobile_number,
                ass_cityname	:element.data[0].ass_cityname,
                ass_status	:element.data[0].ass_status,
                district_name	:element.data[0].district_name,
                product_code	:element.data[0].product_code,
                createbyfname	:"na",

              }
              );


        }
        this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
      }else{
        console.log('User list Api faile');
      }

      });
    }
    
    
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  filter(name: string): Color[] {
    const filterValue = name.toLowerCase();
  
  //console.log(filterValue);
   if(filterValue.length -1){
    let details = { data: {results: this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0), } }; 
    details['data']['results'];    
   
   }
    
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  adidSubmitted: boolean = false;
  useridSubmitted: boolean = false;
  sourceSubmitted: boolean = false;
  loanSubmitted: boolean = false;
  stageSubmitted: boolean = false;
  dateSubmitted: boolean = false;
  locationSubmitted: boolean = false;
  lenderSubmitted: boolean = false;
  crmstageSubmitted: boolean = false;
  userstageSubmitted: boolean = false;
  usersalarybtn: boolean = false;
  assignSubmitted = true;
  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }

  get fc() { return (<FormGroup>this.registerForm.get('sourceFilter')).controls; }
  get fa() { return (<FormGroup>this.registerForm.get('adidFilter')).controls; }
  get fd() { return (<FormGroup>this.registerForm.get('loanFilter')).controls; }
  get fg() { return (<FormGroup>this.registerForm.get('stageFilter')).controls; }
  get fh() { return (<FormGroup>this.registerForm.get('dateFilter')).controls; }
  get fi() { return (<FormGroup>this.registerForm.get('locationFilter')).controls; }
  get lf() { return (<FormGroup>this.registerForm.get('lendernamefilter')).controls; }
  get lg() { return (<FormGroup>this.registerForm.get('crmstagefilter')).controls; }
  get lh() { return (<FormGroup>this.registerForm.get('userstagefilter')).controls; }
  get hc() { return (<FormGroup>this.registerForm.get('adidedit')).controls; }
  get usl() { return (<FormGroup>this.registerForm.get('usersalary')).controls; }
  get rs() { return (<FormGroup>this.registerForm.get('userassignedby')).controls; }
 
  get usersalary() {
    return this.registerForm.get('usersalary');
  }
  get userassignedby() {
    return this.registerForm.get('userassignedby');
  }
  get adidedit() {
    return this.registerForm.get('adidedit');
  }
  get idFilter() {
    return this.registerForm.get('idFilter');
  }

  get sourceFilter() {
    return this.registerForm.get('sourceFilter');
  }
 get adidFilter(){
  return this.registerForm.get('adidFilter');
}
get loanFilter(){
  return this.registerForm.get('loanFilter');
}get stageFilter(){
  return this.registerForm.get('stageFilter');
}
get dateFilter(){
  return this.registerForm.get('dateFilter');
}
get locationFilter(){
  return this.registerForm.get('locationFilter');
}
get lendernamefilter(){
  return this.registerForm.get('lendernamefilter');
}
get crmstagefilter(){
  return this.registerForm.get('crmstagefilter');
}
get userstagefilter(){
  return this.registerForm.get('userstagefilter');
}
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone() {
    this.display = "block";
  }
  onCloseHandledone() {
    
    this.display = "none";
  }
  onOpen(event: any) {
    //console.log(event);
  }
  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd-mm-yyyy',
    todayBtnTxt: "Today",
    clearBtnTxt: "Clear",
    closeBtnTxt: "Close",    
    showTodayBtn: true,
    showClearDateBtn: true
 
};
    //filter user id
    filterUserId(){
      this.useridSubmitted = true;
      // stop here if form is invalid
      if (this.idFilter.invalid) {
        return;
      }else{
        //console.log("ok");
        this.valuemain=1;
        this.userid =1;
        this.activeClass ="activbutton";
        this.display = "none";
        //user id
        this.from_user_id =parseInt(this.idFilter.value.fromuserid);
        this.to_user_id =parseInt(this.idFilter.value.touserid);

        const data = this.mdbTable.getDataSource();

        this.result = data.filter(d => {

          this.dbuserid = parseInt(d.user_id);        
          return ((this.dbuserid >= this.from_user_id)  && (this.dbuserid  <=   this.to_user_id) );  
          
        });
       
        

      //console.log(this.result); return false;
        this.elementsfilter.length=0;
        if(this.result.length > 0){
        for (let i = 0; i < this.result.length; i++) {
          const elements = this.result[i];
          //console.log(elements);
         

          this.elementsfilter.push(
            {
              user_id: elements.user_id, 
              empcategory:elements.empcategory,
              ad_id: elements.ad_id,
              name:  elements.name,                                                
              provider:  elements.provider,
              city_name: elements.city_name,
              loan_amount: elements.loan_amount,                                     
              reg_date: elements.reg_date,
              status: elements.status,  
              feedback_stage:elements.feedback_stage,                                    
              mobile_number	:elements.mobile_number,
              ass_cityname	:elements.ass_cityname,
              ass_status	:elements.ass_status,
              district_name	:elements.district_name,
              product_code	:elements.product_code,
              createbyfname	:elements.createbyfname,
              createbylname	:elements.createbylname,
            }
            );
           //
        }
      }else{
        this.messageDiv=1;
        this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
        this.clearid=11;
      }
        
        this.mdbTable.setDataSource(this.elementsfilter);
        this.elements = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();      
        
                  
    }

      }
      //filter user source
      filterSource(){
        this.sourceSubmitted = true;
        if (this.sourceFilter.invalid) {
          return;
        }else{
          this.valuemain=1;           
          this.sourcename=1;
          this.basicModal2.hide();
          this.activeSource ="activbutton";
          //source
          this.source =this.sourceFilter.value.source;
          
          
          const data = this.mdbTable.getDataSource();
 
          const  source = this.source;
         
          let filtered = [];
          
          source.filter(function(newsourceData) {
            console.log(newsourceData);
            return   data.filter(function(mainArray) {
              console.log(mainArray);

                if (newsourceData === mainArray.provider) {
                 
                filtered.push({
                  user_id: mainArray.user_id, 
                  empcategory:mainArray.empcategory,
                  ad_id: mainArray.ad_id,
                  name:  mainArray.name,                                                
                  provider:  mainArray.provider,
                  city_name: mainArray.city_name,
                  loan_amount: mainArray.loan_amount,                                     
                  reg_date: mainArray.reg_date,
                  status: mainArray.status,  
                  feedback_stage:mainArray.feedback_stage,                                    
                  mobile_number	:mainArray.mobile_number,
                  ass_cityname	:mainArray.ass_cityname,
                  ass_status	:mainArray.ass_status,
                  district_name	:mainArray.district_name,
                  product_code	:mainArray.product_code,
                  createbyfname	:mainArray.createbyfname,
                  createbylname	:mainArray.createbylname,
                })
              }
            })
          });
          //console.log(filtered);
          this.elementsSource.length=0;
          if(filtered.length > 0){
            for (let i = 0; i < filtered.length; i++) {
                const element = filtered[i];       
               
        
                this.elementsSource.push(
                  {
                    user_id: element.user_id, 
                    empcategory:element.empcategory,
                    ad_id: element.ad_id,
                    name:  element.name,                                                
                    provider:  element.provider,
                    city_name: element.city_name,
                    loan_amount: element.loan_amount,                                     
                    reg_date: element.reg_date,
                    status: element.status,  
                    feedback_stage:element.feedback_stage,                                    
                    mobile_number	:element.mobile_number,
                    ass_cityname	:element.ass_cityname,
                    ass_status	:element.ass_status,
                    district_name	:element.district_name,
                    product_code	:element.product_code,
                    createbyfname	:element.createbyfname,
                    createbylname	:element.createbylname,
                  }
                  );
                // console.log(this.elementsfilter);
              }
          }else{
            this.messageDiv=1;
            this.message ='Record not for Source'+"  "  +this.source;
            this.clearid=14;
           
           
          }
          this.mdbTable.setDataSource(this.elementsSource);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          






        
        }
      }
      //ad id p
      filterAdid() {
        this.adidSubmitted = true;
        if (this.adidFilter.invalid) {
          //return;
          alert('add value');
        }else{
          this.valuemain=1;           
          this.ad_id=1;
          this.basicModal4.hide();
          this.activeadid ="activbutton";
          // add id
          this.adid =this.adidFilter.value.adid;
          //console.log(this.adid);
          //console.log(this.result);
          const data = this.mdbTable.getDataSource();
         
          const  adid = this.adid;
         
          let filtered = [];
          
          adid.filter(function(newadid) {
            return   data.filter(function(mainArray) {
                if (newadid === mainArray.ad_id) {
                filtered.push({
                user_id:     mainArray.user_id, 
                name:  mainArray.name, 
               // last_name:   mainArray.last_name,              
                provider:    mainArray.provider,
                city_name:   mainArray.city_name,
                loan_amount: mainArray.loan_amount,
                ad_id:       mainArray.ad_id,
                reg_date:    mainArray.reg_date,
                status:      mainArray.status,
                feedbackstage: mainArray.feedbackstage,
                lender_name: mainArray.lender_name,
                feedback_stage:mainArray.feedback_stage,
                usersatge:mainArray.usersatge,
                
                user_monthly_income:mainArray.user_monthly_income, 
                })
              }
            })
          });
          //console.log(filtered);
          this.elements_adid.length=0;
          if(filtered.length > 0){
            for (let i = 0; i < filtered.length; i++) {
                const element = filtered[i];
              
              
        
                this.elements_adid.push(
                  {
                  user_id: element.user_id, 
                  name:  element.name, 
                  //last_name:  element.last_name,              
                  provider: element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,
                  ad_id: element.ad_id,
                  reg_date: element.reg_date,
                  status: element.status,
                  feedbackstage: element.feedbackstage,
                  lender_name: element.lender_name,
                  feedback_stage:element.feedback_stage,
                  usersatge:element.usersatge,
                  user_monthly_income:element.user_monthly_income, 
                  }
                  );
                // console.log(this.elementsfilter);
              }
          }else{
            this.messageDiv=1;
            this.message ='Record not for Add id'+"  "  +this.adid;
            this.clearid=14;
           
           
          }
          this.mdbTable.setDataSource(this.elements_adid);
          this.elements = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
          
          
        }
      }
      //loan amt filter
      filterLonAmt(){
        this.loanSubmitted = true;    
          // stop here if form is invalid
          if (this.loanFilter.invalid) {
            return;
          }else{
            
            
            this.valuemain=1;           
            this.loan_amt=1;
            this.basicModal.hide();
            this.activeloan ="activbutton";
            //Loan amt
            this.fromLonAmt =this.loanFilter.value.fromLonAmt;
            this.toLonAmt =this.loanFilter.value.toLonAmt;
           
            const data = this.mdbTable.getDataSource();
           
            const  toamt = parseFloat(this.toLonAmt.toString());
            const  fromamt = parseFloat(this.fromLonAmt.toString());
          //  console.log(toamt);
          //  console.log(fromamt); 
              
              //user id
              this.result = data.filter(d => {
                
                if(d.loan_amount != 'undefined' || d.loan_amount != undefined ){
                //  console.log(d);return false;
                  const  loanamt= parseFloat(d.loan_amount);
                  //return (fromamt  <= loanamt && loanamt  <=  toamt );
                  return ((loanamt >= fromamt ) && (loanamt  <=  toamt ));
                }


                
              });
             // console.log(this.result);
              this.elementsLoan.length=0;
            for (let i = 0; i < this.result.length; i++) {
              const element = this.result[i];
            // console.log(element);
            

              this.elementsLoan.push(
                {
                  user_id: element.user_id, 
                  empcategory:element.empcategory,
                  ad_id: element.ad_id,
                  name:  element.name,                                                
                  provider:  element.provider,
                  city_name: element.city_name,
                  loan_amount: element.loan_amount,                                     
                  reg_date: element.reg_date,
                  status: element.status,  
                  feedback_stage:element.feedback_stage,                                    
                  mobile_number	:element.mobile_number,
                  ass_cityname	:element.ass_cityname,
                  ass_status	:element.ass_status,
                  district_name	:element.district_name,
                  product_code	:element.product_code,
                  createbyfname	:element.createbyfname,
                  createbylname	:element.createbylname,
                }
                );
              // console.log(this.elementsfilter);
            }
            this.mdbTable.setDataSource(this.elementsLoan);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          //loan_amount
          
            //console.log(this.toLonAmt);
          }
      }
      // satge filter
    
      //date
      filterdate(){
        this.dateSubmitted = true;    
        // stop here if form is invalid
        if (this.dateFilter.invalid) {
          return;
        }else{
          this.valuemain=1;
          this.loan_date =1;
          this.activedate ="activbutton";
          this.frame.hide();

          //elig date
          this.fromdate =this.dateFilter.value.fromdate;
          this.todate =this.dateFilter.value.todate;
            const data = this.mdbTable.getDataSource();
              
              const  fromdate = this.fromdate;
              const  todate = this.todate;
              
            //user id
            this.result = data.filter(d => {
              const  reg_date= d.reg_date;
              const regdate = reg_date.split(' ')[0];
              //return (fromdate  <= regdate && regdate  <=  todate );            
              return (regdate >= fromdate  && regdate  <=  todate );
              
            });
            //console.log(this.result);
            this.elementsdate.length=0;
            if(this.result.length > 0){
              for (let i = 0; i < this.result.length; i++) {
                const element = this.result[i];
            
           
  
                this.elementsdate.push(
                  {
                    user_id: element.user_id, 
                    empcategory:element.empcategory,
                    ad_id: element.ad_id,
                    name:  element.name,                                                
                    provider:  element.provider,
                    city_name: element.city_name,
                    loan_amount: element.loan_amount,                                     
                    reg_date: element.reg_date,
                    status: element.status,  
                    feedback_stage:element.feedback_stage,                                    
                    mobile_number	:element.mobile_number,
                    ass_cityname	:element.ass_cityname,
                    ass_status	:element.ass_status,
                    district_name	:element.district_name,
                    product_code	:element.product_code,
                    createbyfname	:element.createbyfname,
                    createbylname	:element.createbylname,
                  
                  }
                  );
                // console.log(this.elementsfilter);
              }
            }else{
              this.messageDiv=1;
              this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
              this.clearid=14;
             
             
            }
           
            
            this.mdbTable.setDataSource(this.elementsdate);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            
        
        }
      }
      filterlocation(){
        //console.log(this.elements);
        console.log(this.locationFilter.value.location);
        this.locationSubmitted = true;    
        //
        if (this.myControl.invalid) {
          return;
        }else{
         // console.log(this.elementscityname);
          
          this.valuemain=1;
          this.city_name =1;
          this.activeLocation ="activbutton";
          this.basicModal1.hide();
          //fiter location
          this.cityname =this.locationFilter.value.location;
          
            const  data = this.mdbTable.getDataSource();
          //  console.log(data);
                const  cityname = this.cityname;
              
                this.result = data.filter(d => {
                 // console.log(d);return false;
                  if(d.city_name != 'na' ){
                  //  console.log(d);return false;
                    const  loanamt= (d.city_name);
                    return (cityname  === loanamt);
                    
                  }
                });
              
              // console.log(filtered);
                this.elementscityname.length=0;
              if(this.result.length > 0){
                for (let i = 0; i < this.result.length; i++) {
                    const element = this.result[i];
                    console.log(element);
            
                    this.elementscityname.push(
                      {
                        user_id: element.user_id, 
                        empcategory:element.empcategory,
                        ad_id: element.ad_id,
                        name:  element.name,                                                
                        provider:  element.provider,
                        city_name: element.city_name,
                        loan_amount: element.loan_amount,                                     
                        reg_date: element.reg_date,
                        status: element.status,  
                        feedback_stage:element.feedback_stage,                                    
                        mobile_number	:element.mobile_number,
                        ass_cityname	:element.ass_cityname,
                        ass_status	:element.ass_status,
                        district_name	:element.district_name,
                        product_code	:element.product_code,
                        createbyfname	:element.createbyfname,
                        createbylname	:element.createbylname,
                      }
                      );
                    // console.log(this.elementsfilter);
                  }
              }else{
                this.messageDiv=1;
                this.message ='Record not for city name'+"  "  +this.cityname;
                this.clearid=14;
               
               
              }
              this.mdbTable.setDataSource(this.elementscityname);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
          
        }
      }  
       
      filterCrmStage(){
       // this.lender_filter =this.crmstagefilter.value.crmstage;
       // console.log(this.lender_filter);
                this.crmstageSubmitted = true;                      
       
           // stop here if form is invalid
           if (this.crmstagefilter.invalid) {
             return;
           }else{
             
             this.valuemain=1;
             this.meraemi_stage =1;
             
             this.activeCrmstage ="activbutton";
             
            this.basicModal11.hide();
 
             //elig stage
            this.crmstage =this.crmstagefilter.value.crmstage;
             // add id
             
             const data = this.mdbTable.getDataSource();
           // console.log(data);
             const  stages = this.crmstage;
            // console.log(stage);
             let filtered = [];
           
             stages.filter(function(newStageData) {
             return   data.filter(function(mainArray) {
                 if (newStageData === mainArray.feedback_stage) {
                 filtered.push({
                  user_id: mainArray.user_id, 
                  empcategory:mainArray.empcategory,
                  ad_id: mainArray.ad_id,
                  name:  mainArray.name,                                                
                  provider:  mainArray.provider,
                  city_name: mainArray.city_name,
                  loan_amount: mainArray.loan_amount,                                     
                  reg_date: mainArray.reg_date,
                  status: mainArray.status,  
                  feedback_stage:mainArray.feedback_stage,                                    
                  mobile_number	:mainArray.mobile_number,
                  ass_cityname	:mainArray.ass_cityname,
                  ass_status	:mainArray.ass_status,
                  district_name	:mainArray.district_name,
                  product_code	:mainArray.product_code,
                  createbyfname	:mainArray.createbyfname,
                  createbylname	:mainArray.createbylname,
                  
                 
                 })
               }
             })
           });
          // console.log(filtered.length);
           this.elements_stage.length=0;
             if(filtered.length > 0){
               for (let i = 0; i < filtered.length; i++) {
                   const element = filtered[i];
               
                 
           
                   this.elements_stage.push(
                     {
                      user_id: element.user_id, 
                      empcategory:element.empcategory,
                      ad_id: element.ad_id,
                      name:  element.name,                                                
                      provider:  element.provider,
                      city_name: element.city_name,
                      loan_amount: element.loan_amount,                                     
                      reg_date: element.reg_date,
                      status: element.status,  
                      feedback_stage:element.feedback_stage,                                    
                      mobile_number	:element.mobile_number,
                      ass_cityname	:element.ass_cityname,
                      ass_status	:element.ass_status,
                      district_name	:element.district_name,
                      product_code	:element.product_code,
                      createbyfname	:element.createbyfname,
                      createbylname	:element.createbylname,
                     }
                     );
                //  console.log(this.elements_stage);
                 }
               }else{
               this.messageDiv=1;
               this.message ='Record not for stage'+"  "  +this.stage;
               this.clearid=14;
              
              
             }
             this.mdbTable.setDataSource(this.elements_stage);
             this.elements = this.mdbTable.getDataSource();
             this.previous = this.mdbTable.getDataSource();
           }
       } 
       filteruserstage(){
        // this.lender_filter =this.userstagefilter.value.userstage;
        // console.log(this.lender_filter);
                 this.crmstageSubmitted = true;                      
        
            // stop here if form is invalid
            if (this.userstagefilter.invalid) {
              return;
            }else{
              
              this.valuemain=1;
              this.meraemi_sub_stage =1;
              this.activeUserstage ="activbutton";
              this.basicModal12.hide();
  
              //elig stage
             this.userstage =this.userstagefilter.value.userstage;
              // add id
              
              const data = this.mdbTable.getDataSource();
            // console.log(data);
              const  stages = this.userstage;
             // console.log(stage);
              let filtered = [];
            
              stages.filter(function(newStageData) {
              return   data.filter(function(mainArray) {
                  if (newStageData === mainArray.usersatge) {
                  filtered.push({
                  user_id:     mainArray.user_id, 
                  name:  mainArray.name,                             
                  provider:    mainArray.provider,
                  city_name:   mainArray.city_name,
                  loan_amount: mainArray.loan_amount,
                  ad_id:       mainArray.ad_id,
                  reg_date:    mainArray.reg_date,
                  status:      mainArray.status,
                  feedbackstage: mainArray.feedbackstage,
                  lender_name: mainArray.lender_name,
                   feedback_stage:mainArray.feedback_stage,
                   usersatge:mainArray.usersatge,
                   user_monthly_income:mainArray.user_monthly_income, 
                  
                  })
                }
              })
            });
           // console.log(filtered.length);
            this.elements_stage.length=0;
              if(filtered.length > 0){
                for (let i = 0; i < filtered.length; i++) {
                    const element = filtered[i];
                
                  
            
                    this.elements_stage.push(
                      {
                      user_id: element.user_id, 
                      name:  element.name,                              
                      provider: element.provider,
                      city_name: element.city_name,
                      loan_amount: element.loan_amount,
                      ad_id: element.ad_id,
                      reg_date: element.reg_date,
                      status: element.status,
                      feedbackstage: element.feedbackstage,
                      lender_name: element.lender_name,
                      feedback_stage:element.feedback_stage,
                      usersatge:element.usersatge,
                      user_monthly_income:element.user_monthly_income, 
                      
                      }
                      );
                  // console.log(this.elements_stage);
                  }
                }else{
                this.messageDiv=1;
                this.message ='Record not for stage'+"  "  +this.stage;
                this.clearid=14;
               
               
              }
              this.mdbTable.setDataSource(this.elements_stage);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
            }
        } 

      



//master cleare dynamic value
clearUserid(user_id){
                   // console.log(user_id); return false;
                    //this.cityname;
                    this.messageDiv=0;
                    this.message=null;
                    this.clearid=null;
                    this.filterarray.length = 0;
                    //this.result.length=0;
                    this.filtered_people.length=0;
                    if(user_id ===10){
                      //user id
                      this.elements.length = 0;
                      this.clear_stagr.length = 0;
                      this.valuemain=1;
                      this.userid =0;
                      this.activeClass ="savemessage";
                      this.display = "none";
                      this.idFilter.reset();
                      
                    }else if(user_id ===11){
                      //source
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.sourcename=0;
                      this.basicModal2.hide();
                      this.activeSource ="savemessage";
                      this.sourceFilter.reset(); 
                      //this.filtered_people.length=0;
                          
                    }else if(user_id ===12){
                      //Location
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;       
                      this.valuemain=1;
                      this.city_name =0;
                      this.activeLocation ="savemessage";
                      this.basicModal1.hide();
                      this.locationFilter.reset(); 
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===13){
                      //loan amt
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.loan_amt=0;
                      this.basicModal.hide();
                      this.activeloan ="savemessage";
                      this.loanFilter.reset();
                      

                    }else if(user_id ===14){
                      //date
                      this.elements.length = 0;    
                      this.valuemain=1;           
                      this.loan_date=0;
                      this.basicModal.hide();     
                      this.activedate ="savemessage"; 
                      this.dateFilter.reset();
                      
                      
                    }else if(user_id ===15){
                      //add id
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;    
                      this.valuemain=1;           
                      this.ad_id=0;
                      this.basicModal.hide();       
                      this.activeadid ="savemessage";  
                      this.adidFilter.reset(); 
                      //this.filtered_people.length=0;
                      
                      
                    }else if(user_id ===16){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.loan_stage=0;
                      this.basicModal.hide();
                      this.activestage ="savemessage"; 
                      this.stageFilter.reset();
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===17){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.loan_lender=0;
                      this.basicModal.hide();
                      this.activelender ="savemessage"; 
                      this.lendernamefilter.reset();
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===18){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.meraemi_stage=0;
                      this.basicModal.hide();
                      this.activeCrmstage ="savemessage"; 
                      this.crmstagefilter.reset();
                      //this.filtered_people.length=0;
                      
                    }else if(user_id ===19){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.meraemi_sub_stage =0;
                      this.basicModal.hide();
                      this.activeUserstage ="savemessage"; 
                      this.userstagefilter.reset();
                    
                    }else if(user_id ===20){
                      //stage
                      this.clear_stagr.length = 0;
                      this.elements.length = 0;
                      this.valuemain=1;           
                      this.usersal =0;
                      this.basicModal.hide();
                      this.activeIncome ="savemessage"; 
                      this.elementsalary.reset();
                    
                    }
                     // user id form value no is 1
                      if(this.idFilter.value.fromuserid !='' && this.idFilter.value.touserid !='')
                      {
                        this.from_user_id =this.idFilter.value.fromuserid;
                        this.to_user_id =this.idFilter.value.touserid;
                                  
                      }else {
                        this.from_user_id =null;
                        this.to_user_id =null;
                      }
                    console.log(this.from_user_id);
                    console.log(this.to_user_id);
                    // source  form value no is 2
                    if(this.sourceFilter.value.source !=''){
                      this.source =this.sourceFilter.value.source;
                      this.filterarray.push(this.source);
                    }else {
                      this.source =null;
                      this.filterarray.push(this.source);
                    }
                    console.log(this.source);
                    //city name form value no is 3
                    if( this.locationFilter.value.location !='')
                    {
                      this.user_city =this.locationFilter.value.location;
                      this.filterarray.push(this.user_city);
                    }else {
                      this.user_city = null;
                      this.filterarray.push(this.user_city);
                    } 
                    console.log(this.user_city);
                    //loan amt form value  no is 4
                    if(this.loanFilter.value.fromLonAmt !='' && this.loanFilter.value.toLonAmt !=''){
                      this.fromLonAmt =this.loanFilter.value.fromLonAmt;
                      this.toLonAmt =this.loanFilter.value.toLonAmt;
                  
                    }else {
                      this.fromLonAmt =null;
                      this.toLonAmt =null;
                    }
                    console.log(this.fromLonAmt);
                    console.log(this.toLonAmt);
                      //elig date form value     no is 5
                      if(this.dateFilter.value.fromdate !='' && this.dateFilter.value.todate !='')
                      {
                      this.fromdate =this.dateFilter.value.fromdate;
                      this.todate =this.dateFilter.value.todate;
                      
                      }else {
                        this.fromdate =null;
                        this.todate =null;
                      }
                      console.log(this.fromdate);
                      console.log(this.todate);
                    // add id form value no is 6
                    if(this.adidFilter.value.adid !=''){
                      this.adid =this.adidFilter.value.adid;
                      this.filterarray.push(this.adid);
                    }else {
                      this.adid =null;
                      this.filterarray.push(this.adid);
                    } 
                    console.log(this.adid);                  
                  
                   
                    //crm stage form value no is 7
                    if(this.crmstagefilter.value.crmstage !=''){
                      this.crmstages =this.crmstagefilter.value.crmstage;
                      this.filterarray.push(this.stage);
                    }else {
                      this.crmstages =null;
                      this.filterarray.push(this.crmstages);
                    }
                    console.log(this.crmstages);
                    //user stage
                    
                    
                    var filter = {
                      provider : this.filterarray[0],
                      city_name : this.filterarray[1],
                      ad_id : this.filterarray[2],
                      status : this.filterarray[3],                      
                      crmstage : this.filterarray[5],
                      userstage : this.filterarray[6],
                  }
               //   console.log(filter); //return false;
                  const  data = this.element_main;
                  if(filter.ad_id != null){
                    this.filtered_people = data.filter((person) => (filter.ad_id.includes(person.ad_id)))
                   
                  }else if(filter.city_name != null){
                    this.filtered_people = data.filter((person) => (filter.city_name.includes(person.city_name)))
                   
                  }else if(filter.status != null){
                    this.filtered_people = data.filter((person) => (filter.status.includes(person.status)))
                  }else if(filter.provider != null){
                    this.filtered_people = data.filter((person) => (filter.provider.includes(person.provider)))
                   
                  }else if(filter.userstage != null){
                    this.filtered_people = data.filter((person) => (filter.userstage.includes(person.userstage)))
                   
                  }
                  else if(filter.crmstage != null){
                    this.filtered_people = data.filter((person) => (filter.crmstage.includes(person.crmstage)))
                   
                  }
                  //main filter
                 // console.log(this.from_user_id); return false;
                  if(this.idFilter.value.fromuserid !='' && this.idFilter.value.touserid !='')
                {
                    //console.log(this.filtered_people.length); return false;
                     if(this.filtered_people.length !=0){
                          const data = this.filtered_people;

                          this.result = data.filter(d => {
                  
                            this.dbuserid = parseInt(d.user_id);        
                            return ((this.dbuserid >= this.from_user_id)  && (this.dbuserid  <=   this.to_user_id) );  
                            
                          });

                            this.elementsMainfilter.length=0;
                            if(this.result.length > 0){
                            for (let i = 0; i < this.result.length; i++) {
                              const elements = this.result[i];
                              //console.log(elements);
                              
                    
                              this.elementsMainfilter.push(
                                {
                                user_id: elements.user_id, 
                                name:  elements.name,                                      
                                provider: elements.provider,
                                city_name: elements.city_name,
                                loan_amount: elements.loan_amount,
                                ad_id: elements.ad_id,
                                reg_date: elements.reg_date,
                                status: elements.status,
                                feedbackstage: elements.feedbackstage,
                                lender_name: elements.lender_name,
                                feedback_stage:elements.feedback_stage,
                                usersatge:elements.usersatge,
                                user_monthly_income:elements.user_monthly_income, 
                                }
                                );
                              
                            }
                              }else{
                                this.messageDiv=1;
                                this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
                                this.clearid=11;
                              }


                     }else{
                      const data = this.element_main;
                     // console.log(data); //return false;
                      this.result = data.filter(d => {
              
                        this.dbuserid = parseInt(d.user_id);        
                        return ((this.dbuserid >= this.from_user_id)  && (this.dbuserid  <=   this.to_user_id) );  
                        
                      });
                     
                      
              
                   // console.log(this.result); return false;
                      this.elementsMainfilter.length=0;
                      if(this.result.length > 0){
                      for (let i = 0; i < this.result.length; i++) {
                        const elements = this.result[i];
                        //console.log(elements);
                   
              
                        this.elementsMainfilter.push(
                          {
                          user_id: elements.user_id, 
                          name:  elements.name, 
                          //last_name:  elements.last_name,              
                          provider: elements.provider,
                          city_name: elements.city_name,
                          loan_amount: elements.loan_amount,
                          ad_id: elements.ad_id,
                          reg_date: elements.reg_date,
                          status: elements.status,
                          feedbackstage: elements.feedbackstage,
                          lender_name: elements.lender_name,
                          feedback_stage:elements.feedback_stage,
                          usersatge:elements.usersatge,
                          user_monthly_income:elements.user_monthly_income, 
                          }
                          );
                         //
                      }
                          }else{
                            this.messageDiv=1;
                            this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
                            this.clearid=11;
                          }
                     }
                    
                  }else if(this.loanFilter.value.fromLonAmt !='' && this.loanFilter.value.toLonAmt !='')
                  {
                    const  toamt = parseFloat(this.toLonAmt.toString());
                    const  fromamt = parseFloat(this.fromLonAmt.toString());
                      //console.log(this.filtered_people.length); return false;
                       if(this.filtered_people.length !=0){
                            const data = this.filtered_people;
                  
                            this.result = data.filter(d => {
                            
                              if(d.loan_amount != 'undefined' || d.loan_amount != undefined ){
                              //  console.log(d);return false;
                                const  loanamt= parseFloat(d.loan_amount);
                                //return (fromamt  <= loanamt && loanamt  <=  toamt );
                                return ((loanamt >= fromamt ) && (loanamt  <=  toamt ));
                              }
                    
                    
                              
                            });
                  
                              this.elementsMainfilter.length=0;
                              if(this.result.length > 0){
                              for (let i = 0; i < this.result.length; i++) {
                                const elements = this.result[i];
                                //console.log(elements);
                                
                      
                                this.elementsMainfilter.push(
                                  {
                                  user_id: elements.user_id, 
                                  name:  elements.name,                                      
                                  provider: elements.provider,
                                  city_name: elements.city_name,
                                  loan_amount: elements.loan_amount,
                                  ad_id: elements.ad_id,
                                  reg_date: elements.reg_date,
                                  status: elements.status,
                                  feedbackstage: elements.feedbackstage,
                                  lender_name: elements.lender_name,
                                  feedback_stage:elements.feedback_stage,
                                  usersatge:elements.usersatge,
                                  user_monthly_income:elements.user_monthly_income, 
                                  }
                                  );
                                
                              }
                                }else{
                                  this.messageDiv=1;
                                  this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
                                  this.clearid=11;
                                }
                  
                  
                       }else{
                        const data = this.element_main;
                       // console.log(data); //return false;
                       this.result = data.filter(d => {
                            
                        if(d.loan_amount != 'undefined' || d.loan_amount != undefined ){
                        //  console.log(d);return false;
                          const  loanamt= parseFloat(d.loan_amount);
                          //return (fromamt  <= loanamt && loanamt  <=  toamt );
                          return ((loanamt >= fromamt ) && (loanamt  <=  toamt ));
                        }
                  
                  
                        
                      });
                       
                        
                  
                     // console.log(this.result); return false;
                        this.elementsMainfilter.length=0;
                        if(this.result.length > 0){
                        for (let i = 0; i < this.result.length; i++) {
                          const elements = this.result[i];
                          //console.log(elements);
                        
                  
                          this.elementsMainfilter.push(
                            {
                            user_id: elements.user_id, 
                            name:  elements.name, 
                            //last_name:  elements.last_name,              
                            provider: elements.provider,
                            city_name: elements.city_name,
                            loan_amount: elements.loan_amount,
                            ad_id: elements.ad_id,
                            reg_date: elements.reg_date,
                            status: elements.status,
                            feedbackstage: elements.feedbackstage,
                            lender_name: elements.lender_name,
                            feedback_stage:elements.feedback_stage,
                            usersatge:elements.usersatge,
                            user_monthly_income:elements.user_monthly_income, 
                            }
                            );
                           //
                        }
                            }else{
                              this.messageDiv=1;
                              this.message ='Record not for User Id between'+"  "  +this.from_user_id+  '  To  '  + this.to_user_id;
                              this.clearid=11;
                            }
                       }
                      
                  }else  if(this.dateFilter.value.fromdate !='' && this.dateFilter.value.todate !='')
                  {
                    //console.log(this.fromdate);
                  //console.log(this.todate);
                      //elig date
                      const  fromdate = this.fromdate;
                      const  todate = this.todate;
                      if(this.filtered_people.length !=0){
                          const data = this.filtered_people; 
                        //user id
                        this.result = data.filter(d => {
                          const  reg_date= d.reg_date;
                          const regdate = reg_date.split(' ')[0];
                          //return (fromdate  <= regdate && regdate  <=  todate );            
                          return (regdate >= fromdate  && regdate  <=  todate );
                          
                        });
                        //console.log(this.result);
                        this.elementsMainfilter.length=0;
                        if(this.result.length > 0){
                          for (let i = 0; i < this.result.length; i++) {
                            const element = this.result[i];
                        
                           
              
                            this.elementsMainfilter.push(
                              {
                              user_id: element.user_id, 
                              name:  element.name, 
                              //last_name:  element.last_name,              
                              provider: element.provider,
                              city_name: element.city_name,
                              loan_amount: element.loan_amount,
                              ad_id: element.ad_id,
                              reg_date: element.reg_date,
                              status: element.status,
                              feedbackstage: element.feedbackstage,
                              lender_name: element.lender_name,
                              feedback_stage:element.feedback_stage,
                              usersatge:element.usersatge,
                              user_monthly_income:element.user_monthly_income, 
                              
                              }
                              );
                            // console.log(this.elementsfilter);
                          }
                        }else{
                          this.messageDiv=1;
                          this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
                          this.clearid=14;
                        
                        
                        }
                      }else{
                        console.log("2");
                        const data = this.element_main;
                        //user id
                        this.result = data.filter(d => {
                          const  reg_date= d.reg_date;
                          const regdate = reg_date.split(' ')[0];
                          //return (fromdate  <= regdate && regdate  <=  todate );            
                          return (regdate >= fromdate  && regdate  <=  todate );
                          
                        });
                        //console.log(this.result);
                        this.elementsMainfilter.length=0;
                        if(this.result.length > 0){
                          for (let i = 0; i < this.result.length; i++) {
                            const element = this.result[i];
                        
                          
              
                            this.elementsMainfilter.push(
                              {
                              user_id: element.user_id, 
                              name:  element.name, 
                              //last_name:  element.last_name,              
                              provider: element.provider,
                              city_name: element.city_name,
                              loan_amount: element.loan_amount,
                              ad_id: element.ad_id,
                              reg_date: element.reg_date,
                              status: element.status,
                              feedbackstage: element.feedbackstage,
                              lender_name: element.lender_name,
                              feedback_stage:element.feedback_stage,
                              usersatge:element.usersatge,
                              user_monthly_income:element.user_monthly_income, 
                              
                              }
                              );
                            // console.log(this.elementsfilter);
                          }
                        }else{
                          this.messageDiv=1;
                          this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
                          this.clearid=14;
                        
                        
                        }
                      }
                  }else{
                    this.elementsMainfilter=this.element_main;
                  }
                  if (this.fromdate && this.todate && this.fromdate <= this.todate) 
                  {
                      //elig date
                      const  fromdate = this.fromdate;
                      const  todate = this.todate;
                      if(this.filtered_people.length !=0){
                          const data = this.filtered_people; 
                        //user id
                        this.result = data.filter(d => {
                          const  reg_date= d.reg_date;
                          const regdate = reg_date.split(' ')[0];
                          //return (fromdate  <= regdate && regdate  <=  todate );            
                          return (regdate >= fromdate  && regdate  <=  todate );
                          
                        });
                        //console.log(this.result);
                        this.elementsMainfilter.length=0;
                        if(this.result.length > 0){
                          for (let i = 0; i < this.result.length; i++) {
                            const element = this.result[i];
                        
                            
              
                            this.elementsMainfilter.push(
                              {
                              user_id: element.user_id, 
                              name:  element.name, 
                              //last_name:  element.last_name,              
                              provider: element.provider,
                              city_name: element.city_name,
                              loan_amount: element.loan_amount,
                              ad_id: element.ad_id,
                              reg_date: element.reg_date,
                              status: element.status,
                              feedbackstage: element.feedbackstage,
                              lender_name: element.lender_name,
                              feedback_stage:element.feedback_stage,
                              usersatge:element.usersatge,
                              user_monthly_income:element.user_monthly_income, 
                              
                              }
                              );
                            // console.log(this.elementsfilter);
                          }
                        }else{
                          this.messageDiv=1;
                          this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
                          this.clearid=14;
                        
                        
                        }
                      }else{
                        const data = this.element_main;
                        //user id
                        this.result = data.filter(d => {
                          const  reg_date= d.reg_date;
                          const regdate = reg_date.split(' ')[0];
                          //return (fromdate  <= regdate && regdate  <=  todate );            
                          return (regdate >= fromdate  && regdate  <=  todate );
                          
                        });
                        //console.log(this.result);
                        this.elementsMainfilter.length=0;
                        if(this.result.length > 0){
                          for (let i = 0; i < this.result.length; i++) {
                            const element = this.result[i];
                        
                      
              
                            this.elementsMainfilter.push(
                              {
                              user_id: element.user_id, 
                              name:  element.name, 
                              //last_name:  element.last_name,              
                              provider: element.provider,
                              city_name: element.city_name,
                              loan_amount: element.loan_amount,
                              ad_id: element.ad_id,
                              reg_date: element.reg_date,
                              status: element.status,
                              feedbackstage: element.feedbackstage,
                              lender_name: element.lender_name,
                              feedback_stage:element.feedback_stage,
                              usersatge:element.usersatge,
                              user_monthly_income:element.user_monthly_income, 
                              
                              }
                              );
                            // console.log(this.elementsfilter);
                          }
                        }else{
                          this.messageDiv=1;
                          this.message ='Record not for date between'+"  "  +this.fromdate+  '  To  '  + this.todate;
                          this.clearid=14;
                        
                        
                        }
                      }
                  }else{
                    this.elementsMainfilter=this.element_main;
                  }
                  if( this.elementsMainfilter.length === 0){
                    this.elementsMainfilter=this.filtered_people;
                  }
                  //console.log(this.fromdate);
                  //console.log(this.todate);
                  this.mdbTable.setDataSource(this.elementsMainfilter);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource(); 
                //  console.log(this.filtered_people);
                  //console.log(this.filtered_people.length);
  }






        
     
     //user Details
       getLeadDetails(user,data){
        //console.log(data);
       // console.log(user); return false;
         let stage=  data;
         if(user.usersatge !=undefined){
          let userstage=user.usersatge;

           this.useid = this.cryptojsService.encryptData((user.user_id));       
           const base_url=location.origin+"/#/UserLeadDetails?data=";        
           const detailsurl = base_url + this.useid.toString();
   
           const dateur=detailsurl+"&stage="+stage.toString()+"&userstage="+userstage.toString();
          
           //console.log(detailsurl);
           window.open(dateur, "_blank");
         }else{
         // console.log(user.usersatge);
          this.useid = this.cryptojsService.encryptData((user.user_id));       
          const base_url=location.origin+"/#/UserLeadDetails?data=";        
          const detailsurl = base_url + this.useid.toString();
  
          const dateur=detailsurl+"&stage="+stage.toString();
         
          //console.log(detailsurl);
          window.open(dateur, "_blank");
         }
   
       }
        //intraktUrlAPIlisting
        intraktuserlisting(data){
         // console.log(data); return false;
       
           
           const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
          
  
          const dateur=base_url+"91"+data.toString();
         
          // //console.log(detailsurl);
           window.open(dateur, "_blank");
           
     
         }
    getDocument(user){
      
     // console.log(data);
     this.useid = this.cryptojsService.encryptData((user.user_id));       
     const base_url=location.origin+"/#/MeraEMI-Lender-Documents?data=";        
     const detailsurl = base_url + this.useid.toString();
    
    
     console.log(detailsurl);
     window.open(detailsurl, "_blank");
 }
 fistdateficker(){
   
  setTimeout(() => {
    this.darkPicker.openBtnClicked();
  
    setTimeout(() => {
      this.darkPicker.closeBtnClicked();
    
    }, 4000);

  }, 4000);
}
secondficker(){
  setTimeout(() => {
    this.datePicker.openBtnClicked();

    setTimeout(() => {
      this.datePicker.closeBtnClicked();
    }, 4000);

  }, 4000);
}
rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
 
    openModaloneidid(data,data1) {
      this.adid=data1;
      this.updateuserad=data;
      this.Modaloneidid =data;
   
      
    }
    Addid(){
      let user_id=this.updateuserad;
      let adid=this.adidedit.value.adids;
      console.log(user_id);
      console.log(adid);
      let detail = { results: [{ "user_id":user_id, "adid":adid}] };
      detail['results']; 
      this.FeedbackService.updateUserAdId(detail).subscribe((result: any) => {
        if(result.status===true  && result.response== 200){
          this.Modaloneidid=0;
          this.adidedit.reset();
          alert("Are you sure you want to update Ad ID?");
        }
       /// console.log(result);
      });
      
    }
    CancelAd(){
      this.adidedit.reset();
      this.Modaloneidid=0;
    }
    
    exportTojson() {
      // exportData is your array which you want to dowanload as json and sample.json is your file name, customize the below lines as per your need.
      let exportData = this.elements;
      return saveAs(
        new Blob([JSON.stringify(exportData, null, 2)], { type: 'JSON' }), 'sample.json'
      );}
      
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
  assignedToSatff(user){
    this.uid=user.user_id;
    this.uname=user.name;
   // this.optionCity.length= 0;
   // this.cityArray.length= 0;
  

    //console.log(user);
    this.assigned.show();
  }
 
  stateid(selectedValue: any): void {
    this.cityArray=[];
    this.optionCity=[];
    console.log(selectedValue);
    this.adminService.getBanchCityMater(selectedValue)
      .subscribe((res:any) => {
        this.optionCity.length= 0;
        this.cityArray.length= 0;
       // console.log(res); return false;
        if (res.status === true) {
          //this.optionCity=[];
        //  this.cityArray=[];
          for (let i = 0; i < res.city.length; i++) {
            const menuarray = res.city[i];
           
         
            this.cityArray.push(
              {
                value: menuarray.data[0].city_id,
                label: menuarray.data[0].city_name,                          
               
              }
              );
              //console.log(this.messages);
          
          
             
              
          }
          this.optionCity =this.cityArray;
          console.log(this.optionCity);
        }else{
          console.log('City Api faile');
        }
      });
    
   
    // Do something with the selected value
  }
//ass
assignbyuserbtn(){
  this.assignSubmitted = true;
  this.showLoader = false;

  // stop here if form is invalid
  if (this.userassignedby.invalid) {
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);

    return;
  }else{
    this.assigned.hide();
    this.showLoader = true;
    const userid     = this.uid;
    const loginuserid = this.loginuserid;
    const cityid= this.userassignedby.value.city;
    const state= this.userassignedby.value.state;
    let assUser={ results: [{"adminid":loginuserid,"userid":userid,"cityid":cityid}]};
    this.elements=[];
    //console.log(assUser);
    this.adminService.assigByUser(assUser)
    .subscribe((asUserData: any) => {
      if(asUserData.response=== 200 && asUserData.status === true){

        if( this.rolecode === "AD"){
          this.ctwaService
          .GetUserCTWAList()
          .subscribe( (result:any) => {
          // console.log(result); return false;
          
            if (result.status = true  && result.response=== 200) {      //        
              for (let i = 0; i < result.admin.length;  i++) {
                const element = result.admin[i];
              
             
                        
                  
                        if(element.data[0].elig_status  === "0"){
                          this.elig_status ='Eligibility Pending';                        
                        }else{
                          this.elig_status ='Eligibility Clear';                         
                        }
                    
                        this.appstage = this.elig_status;      
                 
                      if(element.data[0].feedback_stage !=undefined){
                        this.meraemistage=element.data[0].feedback_stage;
                      }else{
                        this.meraemistage="Pending Review";
                      }                   
                                      
                    this.fnamelname= element.data[0].first_name+' '+element.data[0].last_name



                    if(element.data[0].salaried==="2"){
                      this.empcategory="SEP";

                    }
                    if(element.data[0].feedback_stage !="na"){
                      this.meraemistage=element.data[0].feedback_stage;
                    }else{
                      this.meraemistage="Pending Review";
                    }
                  // console.log(element.data[0].user_stage);
                  //   console.log(this.lender_name);
                                  this.elements.push(
                                    {
                                      user_id: element.data[0].user_id, 
                                      empcategory:element.data[0].employment_type,
                                      ad_id: element.data[0].ad_id,
                                      name:  this.fnamelname,                                                
                                      provider:  element.data[0].provider_id,
                                      city_name: element.data[0].location,
                                      loan_amount: element.data[0].loan_amount,                                     
                                      reg_date: element.data[0].date,
                                      status: this.appstage,  
                                      feedback_stage:this.meraemistage,                                    
                                      mobile_number	:element.data[0].mobile_number,
                                      ass_cityname	:element.data[0].ass_cityname,
                                      ass_status	:element.data[0].ass_status,
                                      district_name	:element.data[0].district_name,
                                      product_code	:element.data[0].product_code,
                                      createbyfname	:element.data[0].createbyfname,
                                      createbylname	:element.data[0].createbylname,
                                      
                                      
                                    }
                                    );
                                    this.element_main.push(
                                      {
                                        user_id: element.data[0].user_id, 
                                        empcategory:"Self Employed",
                                        ad_id: element.data[0].ad_id,
                                        name:  this.fnamelname,                                                
                                        provider:  element.data[0].provider_id,
                                        city_name: element.data[0].location,
                                        loan_amount: element.data[0].loan_amount,                                     
                                        reg_date: element.data[0].date,
                                        status: this.appstage,  
                                        feedback_stage:this.meraemistage,                                    
                                        mobile_number	:element.data[0].mobile_number,
                                        ass_cityname	:element.data[0].ass_cityname,
                                        ass_status	:element.data[0].ass_status,
                                        district_name	:element.data[0].district_name,
                                        product_code	:element.data[0].product_code,
                  
                                      }
                                      );
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
         
          }else{
            this.ctwaService.getAssignedByUserList(this.cityid)
            .subscribe( (result:any) => {
            // console.log(result);
            if (result.status = true  && result.response=== 200) {            
              for (let i = 0; i < result.ctwa.length;  i++) {
                    const element = result.ctwa[i];
                    
                    if(element.data[0].elig_status  === "0"){
                      this.elig_status ='Eligibility Pending';                        
                    }else{
                      this.elig_status ='Eligibility Clear';                         
                    }
                
                    this.appstage = this.elig_status;      
            
                  if(element.data[0].feedback_stage !=undefined){
                    this.meraemistage=element.data[0].feedback_stage;
                  }else{
                    this.meraemistage="Pending Review";
                  }                   
                                  
                this.fnamelname= element.data[0].first_name+' '+element.data[0].last_name;
                if(element.data[0].feedback_stage !="na"){
                  this.meraemistage=element.data[0].feedback_stage;
                }else{
                  this.meraemistage="Pending Review";
                }
                this.elements.push(
                  {
                    user_id: element.data[0].user_id, 
                    empcategory:element.data[0].employment_type,
                    ad_id: element.data[0].ad_id,
                    name:  this.fnamelname,                                                
                    provider:  element.data[0].provider_id,
                    city_name: element.data[0].location,
                    loan_amount: element.data[0].loan_amount,                                     
                    reg_date: element.data[0].date,
                    status: this.appstage,  
                    feedback_stage:this.meraemistage,                                    
                    mobile_number	:element.data[0].mobile_number,
                    ass_cityname	:element.data[0].ass_cityname,
                    ass_status	:element.data[0].ass_status,
                    district_name	:element.data[0].district_name,
                    product_code	:element.data[0].product_code,
                    createbyfname	:"na",
                  
                    
                    
                  }
                  );
                  this.element_main.push(
                    {
                      user_id: element.data[0].user_id, 
                      empcategory:"Self Employed",
                      ad_id: element.data[0].ad_id,
                      name:  this.fnamelname,                                                
                      provider:  element.data[0].provider_id,
                      city_name: element.data[0].location,
                      loan_amount: element.data[0].loan_amount,                                     
                      reg_date: element.data[0].date,
                      status: this.appstage,  
                      feedback_stage:this.meraemistage,                                    
                      mobile_number	:element.data[0].mobile_number,
                      ass_cityname	:element.data[0].ass_cityname,
                      ass_status	:element.data[0].ass_status,
                      district_name	:element.data[0].district_name,
                      product_code	:element.data[0].product_code,
                      createbyfname	:"na",
      
                    }
                    );
      
      
              }
              this.mdbTable.setDataSource(this.elements);
                        this.elements = this.mdbTable.getDataSource();
                        this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
      
            });
          }

      }else{
        console.log("api not any responce !");
      }
      
    });
  }
}
}