import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { PartnerService } from  'src/app/services/partner/partner.service'

@Component({
  selector: 'app-business-partner-details',
  templateUrl: './business-partner-details.component.html',
  styleUrls: ['./business-partner-details.component.scss']
})
export class BusinessPartnerDetailsComponent implements OnInit {
  sub: any;
  data: any;
  decryptData: any;
  showLoader: boolean;
  patnerDetails: any=[];
  constructor(  private route:ActivatedRoute,   
                private router:Router,
                private cryptojsService:CryptojsService,
                private partnerser:PartnerService,
    ) { }
  ngOnInit() {
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;  
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);         
             
        }else{
          this.showLoader = true;    
          this.router.navigate(['/business-partner']);
        }        
      },(err)=>{
        console.log(err);
      });

  //console.log(parseInt(this.decryptData));   

  const uid = parseInt(this.decryptData);
      this.partnerser.getBusinessPartnerDetails(uid).subscribe(             
        (busDetails:any) => {
          if(busDetails.response == 200 && busDetails.status==true){
            this.patnerDetails=busDetails.data[0]['BusinessPartnerDetails'][0];
            console.log( this.patnerDetails);
          }else{
            console.log("Api cannot connect !");
          }
        
      
        });

  }
   //intraktUrlAPIlisting
   intraktuserlisting(data){
    // console.log(data); return false;
  
      
      const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
     

     const dateur=base_url+"91"+data.toString();
    
     // //console.log(detailsurl);
      window.open(dateur, "_blank");
      

    }


}
