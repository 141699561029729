import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import {  CookieService} from 'ngx-cookie-service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';

import {  AdminService} from 'src/app/services/admin/admin.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-leads-listing',
  templateUrl: './leads-listing.component.html',
  styleUrls: ['./leads-listing.component.scss']
})
export class LeadsListingComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  @ViewChild("basicModal2", {static: true}) basicModal2: ModalDirective;
  @ViewChild("basicModal1", {static: true}) basicModal1: ModalDirective;
  @ViewChild("basicModal", {static: true}) basicModal: ModalDirective;
  @ViewChild("basicModal3", {static: true}) basicModal3: ModalDirective;
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("darkPicker", {static: true}) darkPicker: MDBDatePickerComponent;
  @ViewChild("datePicker", {static: true}) datePicker: MDBDatePickerComponent;
  @ViewChild("basicModal10", {static: true}) basicModal10: ModalDirective;
  @ViewChild("basicModal11", {static: true}) basicModal11: ModalDirective;
  @ViewChild("basicModal12", {static: true}) basicModal12: ModalDirective;
  @ViewChild("salary", {static: true}) salary: ModalDirective;
  @ViewChild("newuseradd", {static: true}) newuseradd: ModalDirective;
  @ViewChild("assigned", {static: true}) assigned: ModalDirective;
  
  myControl = new FormControl();
  options: Color[] = [];
  data: Observable<Color[]>;
  previous: any = [];
  element_main: any = [];
  elementsfilter: any = []; 
  elementsSource: any = [];
  elements_adid: any = [];
  elementsLoan: any = [];
  elementsalary: any = [];
  elements_stage: any = [];
  elementsdate: any = [];  
  elementscityname: any = [0];
  elements: any = [];
  clear_stagr: any = [];
  filterarray: any = [];
  arrayele: any = [];
  All_ad_id: any = [];
   optionsArray: any = []; 
   messages: any = []; 
  headElements = [
    {
       name:"Date",
       sortBy:"Date",
       width: 150
     },
     {
      name:"Lead Id",
      sortBy:"Temp Id",
      width: 150
    },
    
    
     {
      name:"Profile",
      sortBy:"Profile",
      width: 150
    },
     {
       name:"Ad id",
       sortBy:"ad_id"
     },
     
     {
       name:"Location",
       sortBy:"Location"
     },
     
     {
      name:"Product",
      sortBy:"Product"
    },
     {
       name:"Name",
       sortBy:"first_name"
     },
     
     {
       name:"Email",
       sortBy:"Email"
     },
     {
       name:"Mobile No",
       sortBy:"Mobile No"
     },  
   
     
     {
      name:"Assign to Branch",
      sortBy:"Assig to"
    },
    
    {
      name:"Create By",
      sortBy:"Create By"
    },
     {
       name:"View ",
       sortBy:"Status"
     }

   ];
  stage_color: string;
  appstage: string;
  elig_status: string;
  provider: string;
  maxVisibleItems: number = 10;
  searchText: any;
  tabdiv: number;
  display: string;
  registerForm: FormGroup;
 
  submitted = false;
  from_user_id:number = 0;
  to_user_id: number = 0;
  valuemain: number;
  userid: number;
  activeClass: string;
  startupsLinks: any;
  series: any;
  result: any;
  resultpush: any = [];
  responseArray:any = [];
  optionsSelect: { value: string; label: string; }[];
  optionsStage: { value: string; label: string; }[];
  datafilter: any;
  source: any;
  adid: any;
  sourcename: number;
  ad_id: number;
  loan_amt: number;
  fromLonAmt: any;
  toLonAmt: any;
  stage: any;
  loan_stage: number;
  fromdate: any;
  todate: any;
  loan_date: number;
  city_name: number;
  cityname: any;
  user_city: any;
  activeSource: string;
  activeadid: string;
  activeloan: string;
  activestage: string;
  activelender: string;
  activedate: string;
  activeLocation: string; 
  results: any;
  useid: any;
  messageDiv: any;
  message: any;
  clearid: any;
  usercity: any;
  selectedValue = '1';
  selectedValues = '1';
  lendernamearray: any = [];
  optionslocation: any = []; 
  optionsadid: any = []; 
  citymainarray: any;
  statusArray: any;
  filtered_people: any = [];
  urlstage: string;
  showLoader: boolean;
  lender_name: any;
  iapstage: number=0;
  first_name: string;
  last_name: string;
  fnamelname: string;
  lenderfilter:string;
  loan_lender:any = [0];
  lender_filter: any;
  meraemistage: string;
  usersatge: string;
  CrmStatus: any = [];
  UserStatus: any = [];
  crm_status: any = [];
  user_status: any = [];  
  crmstage:  any;
  userstage : any;
  meraemi_stage :any = [0];
  meraemi_sub_stage:any = [0];
 activeCrmstage:string;
  activeUserstage:string;
 Modaloneidid: number = 0;
 updateuserad: number = 0;
  adupdatebtnEnable: number= 0;
  usernamelist: number = 0;
  usersal: number=0;
  fromSalary: number=0;
  toSalary: number=0;
  activeIncome: string;
  dbuserid:number=0;
  lendername: any;
  crmstages: any;
  userstages: any;
  empcategory: string;
  user: any;
  cityid: any;
  loginuserid: any;
  rolecode: any;
  fname: any;
  lname: any;
  stateArray: any = [];
  optionState: any = []; 
  cityArray: any = [];
  optionCity: any = [];
  uname: any;
  tempid: any;
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public cookieService: CookieService,
      private ctwaService:CtwaService,
      private loginServiceService: LoginServiceService,
      private adminService:AdminService,
      
      ) { }

  ngOnInit() {
    this.user=this.loginServiceService.userData();
    ///console.log(this.user);
     if(this.user.length !=0){
       this.cityid=(JSON.parse(this.user)['cityname']);
       this.loginuserid= (JSON.parse(this.user)['fb_uid']);
       this.rolecode= (JSON.parse(this.user)['rolecode']);
       this.fname= (JSON.parse(this.user)['first_name']);
       this.lname= (JSON.parse(this.user)['last_name']); 
      }
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);
      this.registerForm = this.formBuilder.group({

         userassignedby: this.formBuilder.group({
          state: ['', Validators.required],
          city: ['', Validators.required]
        }),
      });
      this.adminService
      .getBanchStateMater()
      .subscribe( (st:any) => {
      // console.log(st);
              if (st.status === true) {
                
                for (let i = 0; i < st.state.length; i++) {
                  const menuarray = st.state[i];
                 
               
                  this.stateArray.push(
                    {
                      value: menuarray.data[0].district_id,
                      label: menuarray.data[0].district_name,                          
                     
                    }
                    );
                    //console.log(this.messages);
                
                
                   
                    
                }
                this.optionState =this.stateArray;
               // console.log(this.optionState);
              }else{
                console.log('District Api faile');
              }
              
            });
      if( this.rolecode === "AD"){
          this.ctwaService
          .ctwaLeadListing()
          .subscribe( (result:any) => {
         //  console.log(result); //return false;
          
            if (result.status = true  && result.response=== 200) {      //        
              for (let i = 0; i < result.ctwa.length;  i++) {
                const element = result.ctwa[i];
            
                
                                  this.elements.push(
                                    {
                                      created_on	:element.data[0].created_on,
                                      temp_id: element.data[0].temp_id,
                                      ad_id: element.data[0].ad_id,
                                      first_name:  element.data[0].first_name, 
                                      last_name:  element.data[0].last_name, 
                                      middle_name:  element.data[0].middle_name,
                                      email	:element.data[0].email,
                                      mobileno: element.data[0].moile, 
                                      signup: element.data[0].signup, 
                                      cityname	:element.data[0].ass_cityname,
                                      ass_status	:element.data[0].ass_status,
                                      district_name	:element.data[0].district_name,
                                      product_code	:element.data[0].product_code,
                                      createbyname	:element.data[0].createbyfname,         
                                      location	:element.data[0].location,
                                      employment_type	:element.data[0].employment_type,
                                      

                                    }
                                    );
                                    
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });

      }else{
        this.ctwaService.getAssignedByctwaList(this.cityid)
        .subscribe( (ass:any) => {                 
                if (ass.status = true  && ass.response=== 200) {      //        
                  for (let i = 0; i < ass.ctwa.length;  i++) {
                    const element = ass.ctwa[i];

                    
                                      this.elements.push(
                                        {
                                          created_on	:element.data[0].created_on,
                                          temp_id: element.data[0].temp_id,
                                          ad_id: element.data[0].ad_id,
                                          first_name:  element.data[0].first_name, 
                                          last_name:  element.data[0].last_name, 
                                          email	:element.data[0].email,
                                          mobileno: element.data[0].moile, 
                                          signup: element.data[0].signup, 
                                          cityname	:element.data[0].ass_cityname,
                                          ass_status	:element.data[0].ass_status,
                                          district_name	:element.data[0].district_name,
                                          product_code	:element.data[0].product_code,
                                          createbyname	:element.data[0].createbyfname,         
                                          location	:element.data[0].location, 
                                          employment_type	:element.data[0].employment_type,          
                                          
                                          
                                          

                                        }
                                        );
                                        
                                          
                      }
                    //console.log(this.elements);
                      this.mdbTable.setDataSource(this.elements);
                      this.elements = this.mdbTable.getDataSource();
                      this.previous = this.mdbTable.getDataSource();
                      }else{
                        console.log('User list Api faile');
                      }
        });


      }
         
   
    
    
  }
  assignSubmitted = true;
  get rs() { return (<FormGroup>this.registerForm.get('userassignedby')).controls; }
  stateid(selectedValue: any): void {
    this.cityArray=[];
    this.optionCity=[];
    console.log(selectedValue);
    this.adminService.getBanchCityMater(selectedValue)
      .subscribe((res:any) => {
        this.optionCity.length= 0;
        this.cityArray.length= 0;
       // console.log(res); return false;
        if (res.status === true) {
          //this.optionCity=[];
        //  this.cityArray=[];
          for (let i = 0; i < res.city.length; i++) {
            const menuarray = res.city[i];
           
         
            this.cityArray.push(
              {
                value: menuarray.data[0].city_id,
                label: menuarray.data[0].city_name,                          
               
              }
              );
              //console.log(this.messages);
          
          
             
              
          }
          this.optionCity =this.cityArray;
          console.log(this.optionCity);
        }else{
          console.log('City Api faile');
        }
      });
    
   
    // Do something with the selected value
  }
  get userassignedby() {
    return this.registerForm.get('userassignedby');
  }
    //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
  assignedToSatff(user){
    this.tempid=user.temp_id;
    this.uname=user.name;
   // this.optionCity.length= 0;
   // this.cityArray.length= 0;
  

    //console.log(user);
    this.assigned.show();
  }
  assignbyuserbtn(){
    this.assignSubmitted = true;
    this.showLoader = false;

    // stop here if form is invalid
    if (this.userassignedby.invalid) {
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }else{
      this.assigned.hide();
      this.showLoader = true;
      const tempid     = this.tempid;
      const loginuserid = this.loginuserid;
      const cityid= this.userassignedby.value.city;
      const state= this.userassignedby.value.state;
      let assUser={ results: [{"adminid":loginuserid,"tempid":tempid,"cityid":cityid}]};
      this.elements=[];
      //console.log(assUser);
      this.adminService.assigByctwaUser(assUser)
      .subscribe((asUserData: any) => {
       // console.log(asUserData); return false;
        if(asUserData.response=== 200 && asUserData.status === true){        
              if( this.rolecode === "AD"){
                  this.ctwaService
                  .ctwaLeadListing()
                  .subscribe( (result:any) => {
                  // console.log(result); return false;
                  
                    if (result.status = true  && result.response=== 200) {      //        
                      for (let i = 0; i < result.ctwa.length;  i++) {
                        const element = result.ctwa[i];
                    
                        
                                          this.elements.push(
                                            {
                                              created_on	:element.data[0].created_on,
                                              temp_id: element.data[0].temp_id,
                                              ad_id: element.data[0].ad_id,
                                              first_name:  element.data[0].first_name, 
                                              last_name:  element.data[0].last_name, 
                                              middle_name:  element.data[0].middle_name,
                                              email	:element.data[0].email,
                                              mobileno: element.data[0].moile, 
                                              signup: element.data[0].signup, 
                                              cityname	:element.data[0].ass_cityname,
                                              ass_status	:element.data[0].ass_status,
                                              district_name	:element.data[0].district_name,
                                                        
                                              
                                              
                                              
        
                                            }
                                            );
                                            
                                              
                          }
                        //console.log(this.elements);
                          this.mdbTable.setDataSource(this.elements);
                          this.elements = this.mdbTable.getDataSource();
                          this.previous = this.mdbTable.getDataSource();
                          }else{
                            console.log('User list Api faile');
                          }
                          
                        });
        
              }else{
                this.ctwaService.getAssignedByctwaList(this.cityid)
                .subscribe( (ass:any) => {                 
                        if (ass.status = true  && ass.response=== 200) {      //        
                          for (let i = 0; i < ass.ctwa.length;  i++) {
                            const element = ass.ctwa[i];
        
                            
                                              this.elements.push(
                                                {
                                                  created_on	:element.data[0].created_on,
                                                  temp_id: element.data[0].temp_id,
                                                  ad_id: element.data[0].ad_id,
                                                  first_name:  element.data[0].first_name, 
                                                  last_name:  element.data[0].last_name, 
                                                  email	:element.data[0].email,
                                                  mobileno: element.data[0].moile, 
                                                  signup: element.data[0].signup, 
                                                  cityname	:element.data[0].ass_cityname,
                                                  ass_status	:element.data[0].ass_status,
                                                  district_name	:element.data[0].district_name,
                                                            
                                                  
                                                  
                                                  
        
                                                }
                                                );
                                                
                                                  
                              }
                            //console.log(this.elements);
                              this.mdbTable.setDataSource(this.elements);
                              this.elements = this.mdbTable.getDataSource();
                              this.previous = this.mdbTable.getDataSource();
                              }else{
                                console.log('User list Api faile');
                              }
                });
        
        
              }

        }else{
          console.log("api not any responce !");
        }
        
      });
    }
  }
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 
 //user Details
 QuickDataEntry(){
  // alert("ok");
        
  const base_url=location.origin+"/#/quickDataEntry";        
  this.newuseradd.hide();

 
 
  //console.log(detailsurl);
  window.open(base_url, "_blank");
   //console.log(user); return false;
   




   

  }
  detailedDataEntry(){
   
    const base_url=location.origin+"/#/detailedDataEntry";        
    this.newuseradd.hide();
  
   
   
    //console.log(detailsurl);
    window.open(base_url, "_blank");
     //console.log(user); return false;
  }

  updateprofile(QuickDataEntry){
    
    this.cookieService.set('detailedDataEntry', JSON.stringify(QuickDataEntry));
    console.log(QuickDataEntry);
    const base_url=location.origin+"/#/updateUserProfile";        
    this.newuseradd.hide();
  
   
   
    //console.log(detailsurl);
    window.open(base_url, "_blank");
     //console.log(user); return false;
  }




        
     
     //user Details
       getLeadDetails(user){
       // alert("ok");
       this.useid = this.cryptojsService.encryptData((user.temp_id));       
       const base_url=location.origin+"/#/leadsDetails?data=";        
       const detailsurl = base_url + this.useid.toString();

      
      
       //console.log(detailsurl);
       window.open(detailsurl, "_blank");
        //console.log(user); return false;
        




        
   
       }
        //intraktUrlAPIlisting
        intraktuserlisting(data){
         // console.log(data); return false;
       
           
           const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
          
  
          const dateur=base_url+"91"+data.toString();
         
          // //console.log(detailsurl);
           window.open(dateur, "_blank");
           
     
         }
  
         exportTojson() {
          // exportData is your array which you want to dowanload as json and sample.json is your file name, customize the below lines as per your need.
          let exportData = this.elements;
          return saveAs(
            new Blob([JSON.stringify(exportData, null, 2)], { type: 'JSON' }), 'sample.json'
          );}
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}