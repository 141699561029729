import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth, User } from 'firebase';
import * as firebase from 'firebase';
import { map } from 'rxjs/operators/map';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export { User } from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class FirebasedataService {
  productsRef: AngularFirestoreCollection<any>;
  model:any;
  usersCollection: AngularFirestoreCollection<unknown>;
  docs: any;
  user:User;
  public getData = new Subject(); 
  sub: any;

  constructor(
      private afs: AngularFirestore, 
      public afAuth: AngularFireAuth,
      private cookieService :CookieService
    ) 
  {
  }

  create_new(record) {
    console.log(record);
   const data =record;
    return this.afs.collection("users").doc(record.fb_uid).set({
      data
    })
  }

  save() {
    this.productsRef.add(this.model).then( _ => console.log('this.successMsg'));
  }

  checkUserExist(uid){
    this.usersCollection = this.afs.collection('users');
    this.docs = this.usersCollection.valueChanges();

    this.usersCollection.doc(uid).ref.get().then((doc) => {
      if (doc.exists) {
       this.docs = doc.data();
       this.getData.next(this.docs.data);
      }else{
        this.getData.next(null);
      }
     });
  }

  updateloginData(uid,lastsignin){
   var docRef = this.afs.collection("users").doc(uid);
    docRef.ref.get().then(function(doc) {
      docRef.ref.set({
       data:{
        signin_on:lastsignin
       } 
    },{ merge: true });
    })
  }

  read_users() {
    return this.afs.collection('users').snapshotChanges();
  }

  getUserData(): Observable<any>{
    return this.getData.asObservable();
  }
 

 userData() : boolean{
    const User = this.cookieService.get('currentUser');
    if(User != undefined){
      return true;
    }
    return false;
  }

  
}
