import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';


import {  AdminService} from 'src/app/services/admin/admin.service';
import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormControl } from '@angular/forms';
import { variable } from '@angular/compiler/src/output/output_ast';
import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';
import {
  MustMatch
} from 'src/app/helpers/must-match.validator';

export interface Color {
  name: string;
}
@Component({
  selector: 'app-team-setting',
  templateUrl: './team-setting.component.html',
  styleUrls: ['./team-setting.component.scss']
})
export class TeamSettingComponent implements OnInit, AfterViewInit {
[x: string]: any;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("basicModal4", {static: true}) basicModal4: ModalDirective;
  elements: any = [];
  previous: any = [];
  cityArray:any=[];
  optionCity:any=[];
  stateArray:any=[];
  optionState:any=[];
  searchText: any;
  showLoader: boolean;
  display: string;
  roleArray: any = [];
  optionsRole: any = []; 
  registerForm: FormGroup;
  submitted: boolean;
  headElements = [
    {
       name:"Member",
       sortBy:"Member",
       width: 150
     },
     
    
     {
       name:"Email",
       sortBy:"Email"
     },
     
     {
       name:"Contact",
       sortBy:"Contact"
     },
     
     {
       name:"Last Logged In",
       sortBy:"Last Logged In"
     },
     
     
     {
       name:"Role",
       sortBy:"Role"
     },
    
     
     
     {
       name:"Actions ",
       sortBy:"Actions"
     }

   ];
  progress: boolean;
registerForms: any;
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private loginServiceService: LoginServiceService,
      private ctwaService:CtwaService,
      private adminService:AdminService,
      
      ) { }

  ngOnInit() {
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.adminService
    .getBanchStateMater()
    .subscribe( (st:any) => {
      console.log(st);
            if (st.status === true) {
              
              for (let i = 0; i < st.state.length; i++) {
                const menuarray = st.state[i];
               
             
                this.stateArray.push(
                  {
                    value: menuarray.data[0].district_id,
                    label: menuarray.data[0].district_name,                          
                   
                  }
                  );
                  //console.log(this.messages);
              
              
                 
                  
              }
              this.optionState =this.stateArray;
              console.log(this.optionState);
            }else{
              console.log('District Api faile');
            }
            
          });

    this.registerForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      moile: ['', [Validators.required, Validators.minLength(10)]],
      
      userRole: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      reg_email: ['', [Validators.required, Validators.email]],
      reg_password: ['', [Validators.required, Validators.minLength(6)]],
      password_conf: ['', [Validators.required, Validators.minLength(6)]],
      role_code: ["RM"]
    }, {
      validator: MustMatch('reg_password', 'password_conf')
    });
    
    this.loginServiceService
    .registerUserList()
    .subscribe( (result:any) => {
    //console.log(result); return false;
    
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.reg.length;  i++) {
          const element = result.reg[i];
       
           
                            this.elements.push(
                              {
                                created_on	:element.data[0].created_on,
                                moile: element.data[0].moile,
                                pass: element.data[0].pass,
                                name:  element.data[0].first_name+' '+element.data[0].last_name, 
                                email	:element.data[0].email,
                                mobileno: element.data[0].moile, 
                                role_code: element.data[0].role_code, 
                                user_id: element.data[0].user_id, 
                                createdBy:  element.data[0].crfname+' '+element.data[0].crlname, 
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
         // console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
          this.loginServiceService
          .getUserRole()
          .subscribe( (role:any) => {
         // console.log(role); return false;
                  if (role.status === true) {
                    
                    for (let i = 0; i < role.userRole.length; i++) {
                      const menuarray = role.userRole[i];
                      
              
                      this.roleArray.push(
                        {
                          value: menuarray.data[0].role_id,
                          label: menuarray.data[0].role_name,                          
                         
                        }
                        
                        );
                        //console.log(this.messages);
                       }
                    
                       
                        
                    this.optionsRole =this.roleArray;
                    //console.log(this.optionsRole);
                  }else{
                    console.log('role Api faile');
                  }
                  
                });
    
  }
  stateid(selectedValue: any): void {
    this.cityArray=[];
    this.optionCity=[];
    console.log(selectedValue);
    this.adminService.getBanchCityMater(selectedValue)
      .subscribe((res:any) => {
        this.optionCity.length= 0;
        this.cityArray.length= 0;
       // console.log(res); return false;
        if (res.status === true) {
          //this.optionCity=[];
        //  this.cityArray=[];
          for (let i = 0; i < res.city.length; i++) {
            const menuarray = res.city[i];
           
         
            this.cityArray.push(
              {
                value: menuarray.data[0].city_id,
                label: menuarray.data[0].city_name,                          
               
              }
              );
              //console.log(this.messages);
          
          
             
              
          }
          this.optionCity =this.cityArray;
          console.log(this.optionCity);
        }else{
          console.log('City Api faile');
        }
      });
    
   
    // Do something with the selected value
  }
  get f() {
    return this.registerForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    this.showLoader = false;
    this.basicModal4.hide();
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }
    
    let details = {
 
      results: [{     
        "first_name": this.f.fname.value,  
        "last_name": this.f.lname.value,
        "mobileno": this.f.moile.value,          
        "email": this.f.reg_email.value,
        "provider_id": "Super Admin",  
        "cityname": this.f.city.value,
        "rolecode": this.f.userRole.value,   
        "otp": "1",             
        "reg_password": this.f.reg_password.value,
        "password_conf": this.f.password_conf.value       
       }]

    };
    details['results']; 
    this.loginServiceService.createUser(details)
         .subscribe((loginuser: any) => {
          if(loginuser.response === 200 && loginuser.status === true ){          
            console.log(details); //return false
              
              this.loginServiceService
              .registerUserList()
              .subscribe( (result:any) => {
              //console.log(result); return false;
              this.elements=[];  
              
                if (result.status = true  && result.response=== 200) {    
                   
                  for (let i = 0; i < result.reg.length;  i++) {
                    const element = result.reg[i];
                 
                     
                                      this.elements.push(
                                        {
                                          created_on	:element.data[0].created_on,
                                          moile: element.data[0].moile,
                                          pass: element.data[0].pass,
                                          name:  element.data[0].first_name+' '+element.data[0].last_name, 
                                          email	:element.data[0].email,
                                          mobileno: element.data[0].moile, 
                                          role_code: element.data[0].role_code, 
                                          user_id: element.data[0].user_id, 
                                          createdBy:  element.data[0].crfname+' '+element.data[0].crlname, 
                                                    
                                          
                                          
                                          
          
                                        }
                                        );
                                        
                                          
                      }
                      this.mdbTable.setDataSource(this.elements);
                      this.elements = this.mdbTable.getDataSource();
                      this.previous = this.mdbTable.getDataSource();
                    
                      }else{
                        console.log('User list Api faile');
                      }
                      setTimeout(() => {
                        this.showLoader = true;
                      }, 2000);
                    });
             
          }else{
            this.showLoader = false;
              setTimeout(() => {
                this.showLoader = true;
              }, 2000);
            
              this.reportSuccess('Staff listing', 'teamSetting');
              this.basicModal4.hide();


            

          }
         
          //console.log(result); 
        });
    console.log(details); return false
  }

    //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 




   openModalone() {
    this.display = "block";
  }



        

  
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }

  delete(data){
    console.log(data.user_id);
    this.showLoader = false;

    // stop here if form is invalid
   
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);
      this.adminService.deleteUserData(data.user_id)
      .subscribe((del:any) => {
   
        if(del.response ===200 && del.status===true){
          this.loginServiceService
          .registerUserList()
          .subscribe( (result:any) => {
          //console.log(result); return false;
          this.elements=[];
            if (result.status = true  && result.response=== 200) {      //        
              for (let i = 0; i < result.reg.length;  i++) {
                const element = result.reg[i];
             
                 
                                  this.elements.push(
                                    {
                                      created_on	:element.data[0].created_on,
                                      moile: element.data[0].moile,
                                      pass: element.data[0].pass,
                                      name:  element.data[0].first_name+' '+element.data[0].last_name, 
                                      email	:element.data[0].email,
                                      mobileno: element.data[0].moile, 
                                      role_code: element.data[0].role_code, 
                                      user_id: element.data[0].user_id, 
                                      createdBy:  element.data[0].crfname+' '+element.data[0].crlname, 
                                                
                                      
                                      
                                      
      
                                    }
                                    );
                                    
                                      
                  }
                
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
               
          
        }else{
          console.log('User list Api faile');
        }
            
      });
  }

  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    //this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }
}