import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';

import {  AdminService} from 'src/app/services/admin/admin.service';

import { MasterService } from 'src/app/services/master/master.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-bus-year',
  templateUrl: './bus-year.component.html',
  styleUrls: ['./bus-year.component.scss']
})
export class BusYearComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("add", {static: true}) add: ModalDirective;
  @ViewChild("edit", {static: true}) edit: ModalDirective;
  
  searchText: any;
  elements: any = [];
  previous: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
  headElements = [
    {
       name:"Business  Id",
       sortBy:"Doc Id",
       width: 150
     },
     {
      name:"Business Year Name",
      sortBy:"Doc Category Name",
      width: 150
    },
    {
      name:"Action",
      sortBy:"Action",
      width: 150
    }

   ];
  totalBusYearName: any;
  totalBusYearId: any;
  busid: any;
  
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public adminServiceService: AdminService,
      private masterService : MasterService,  
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({

     addformgup: this.formBuilder.group({
      addname: ['', Validators.required],
      
       
     }),
     editformgup: this.formBuilder.group({
      editname: ['', Validators.required]
     
   }),
     
   });
    this.adminServiceService
    .getBusinessYear()
    .subscribe( (result:any) => {
  // console.log(result); return false;
    
      if (result.status = true  && result.response=== 200) {           
        for (let i = 0; i < result.data[0].businessYear.length;  i++) {
          const element = result.data[0].businessYear[i];
          //console.log(result); return false;
           
                            this.elements.push(
                              {
                                bus_year_id	:element.bus_year_id,
                                bus_year_name: element.bus_year_name,
                               
                           
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
   
    
    
  }
  // add  
  submittedAdd = true;
  get rs() { return (<FormGroup>this.registerForm.get('addformgup')).controls; }
  get addformgup() {
    return this.registerForm.get('addformgup');
  }
  // edit 

  submittedEdit = true;
  get ed() { return (<FormGroup>this.registerForm.get('editformgup')).controls; }
  get editformgup() {
    return this.registerForm.get('editformgup');
  }
  //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(7);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
    
  }
   //filter
 
  editformdata(data){
    this.totalBusYearId=data.bus_year_id;
    this.totalBusYearName=data.bus_year_name;
    //console.log(data);

    
    this.showLoader = false;
    setTimeout(() => {
    this.edit.show();
      this.showLoader = true;
    }, 1000);
   
  }

  editbtn(){
    this.submittedEdit = true;
    this.showLoader = false;
  
    // stop here if form is invalid
    if (this.editformgup.invalid) {
      console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);
  
      return;
    }else{
  
      let editname=this.editformgup.value.editname;
      console.log(editname);
      let detail = { results: [{ "editname":editname,"totalBusYearId":this.totalBusYearId}] };
      detail['results']; 
      this.masterService.editBussYearmaster(detail).subscribe((result: any) => {
  
        if(result.response === 200 && result.status === true){
          this.showLoader = true;
          this.elements=[];
         
            this.edit.hide();
            this.adminServiceService
            .getBusinessYear()
            .subscribe( (result:any) => {  
              if (result.status = true  && result.response=== 200) {           
                for (let i = 0; i < result.data[0].businessYear.length;  i++) {
                  const element = result.data[0].businessYear[i];
                                    this.elements.push(
                                      {
                                        bus_year_id	:element.bus_year_id,
                                        bus_year_name: element.bus_year_name,        
                                      }
                                      );                                   
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
        }
      
      });
    }
  }
  addBtn(){
    this.submittedAdd = true;
    this.showLoader = false;

    // stop here if form is invalid
    if (this.addformgup.invalid) {
      console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }else{
      let addname=this.addformgup.value.addname;
     

    
      this.masterService.addBussYearmaster(addname).subscribe((add:any) => {
        if(add.response == 200 && add.status == true){
          this.showLoader = true;
          this.addformgup.get('addname').setValue('');
         
          this.elements=[];
          this.add.hide();
          this.adminServiceService
          .getBusinessYear()
          .subscribe( (result:any) => {
        // console.log(result); return false;
          
            if (result.status = true  && result.response=== 200) {           
              for (let i = 0; i < result.data[0].businessYear.length;  i++) {
                const element = result.data[0].businessYear[i];
                                  this.elements.push(
                                    {
                                      bus_year_id	:element.bus_year_id,
                                      bus_year_name: element.bus_year_name,        
                                    }
                                    );                                   
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
               
        }
      
      });
    

    }
  }

deleteForm(data){
  this.showLoader = false;
  setTimeout(() => {
    this.showLoader = true;
  }, 2000);
//console.log(data);
  
  this.busid=data.bus_year_id;    
  this.masterService.deleteBussYearName(this.busid)
  .subscribe((del:any) => {
    if(del.response === 200 && del.status === true ){
      this.elements=[];          
      this.adminServiceService
            .getBusinessYear()
            .subscribe( (result:any) => {  
              if (result.status = true  && result.response=== 200) {           
                for (let i = 0; i < result.data[0].businessYear.length;  i++) {
                  const element = result.data[0].businessYear[i];
                                    this.elements.push(
                                      {
                                        bus_year_id	:element.bus_year_id,
                                        bus_year_name: element.bus_year_name,        
                                      }
                                      );                                   
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
    }else{
      console.log('User list Api faile');
    }

  });
}



        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}