import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  MustMatch
} from 'src/app/helpers/must-match.validator';
import {
  AngularFireAuth
} from 'angularfire2/auth';
import {
  FirebasedataService
} from 'src/app/services/firebasedata/firebasedata.service';
import {
  auth,
  User
} from 'firebase';
import * as firebase from 'firebase';
import {
  RegisterService
} from 'src/app/services/register/register.service';
import {
  ReportService
} from 'src/app/services/report/report.service';
import {
  CryptojsService
} from 'src/app/services/cryptojs/cryptojs.service';

import {  AdminService} from 'src/app/services/admin/admin.service';
export {
  User
}


from 'firebase';
import { LoginServiceService } from 'src/app/services/login/login-service.service';

@Component({
  selector: 'app-update-user-profile',
  templateUrl: './update-user-profile.component.html',
  styleUrls: ['./update-user-profile.component.scss']
})
export class UpdateUserProfileComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean;
  exist: Promise < boolean > ;
  error: string;
  progress: boolean;
  errorMessage: string;
  RM: 'RM';
  hide: boolean = true;
  hideconf: boolean = true;
  showLoader: boolean;
  token: any;
  successMessage: any;
  success: string;
  businessYear
  businessYearArray:any=[];
  businessYearOption:any=[];
  SelectTenure:any=[];
  SelectResidenceStatus:any=[];
  businessCategoryArray:any=[];
  businessCategoryOption:any=[];
  industryArray:any=[];
  industryOption:any=[];
  busNatureOption:any=[];
  busNatureArray:any=[];
  genders:any=[];
  prrofBusureArray:any=[];
  prrofBusOption:any=[];
  crdureArray:any=[];
  crdOption:any=[];
  productureArray:any=[];
  productOption:any=[];
  maritalStatusOpt:any=[];
  emptypeureArray:any=[];
  emptypeOption:any=[];
  user: any;
  loginuserid: any;
  rolecode: any;
  fname: any;
  lname: any;
  moulenumer: string;
  loanAmt: string;
  productcode: string;
  dataEntry: any;
  entry: any;
  prodcode: any;
  middlename: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public afAuth: AngularFireAuth,
    private firebasedataService: FirebasedataService,
    private registerService: RegisterService,
    private report: ReportService,
    public cryptojsService: CryptojsService,
    public adminServiceService: AdminService,
    private loginServiceService: LoginServiceService,
    
  ) {}


  ngOnInit() {
 
    this.dataEntry =this.loginServiceService.getDetailedDataEntry();
    console.log((JSON.parse(this.dataEntry)));
      this.entry = JSON.parse(this.dataEntry);
 
   
    this.user=this.loginServiceService.userData();
    // console.log(this.user);
     if(this.user.length !=0){
       this.loginuserid= (JSON.parse(this.user)['fb_uid']);
       this.rolecode= (JSON.parse(this.user)['rolecode']);
       this.fname= (JSON.parse(this.user)['first_name']);
       this.lname= (JSON.parse(this.user)['last_name']); 
       //this.middlename= (JSON.parse(this.dataEntry)['middle_name']); 
     
      }
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({
      tempid: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      mname: ['', Validators.required],
      moile: ['', Validators.required],
      dob: ['', Validators.required],
      pincode: ['', Validators.required],
      loanamt: ['', Validators.required],
      tenure: ['', Validators.required],
      residingHere: ['', Validators.required],
      residenceStatus: ['', Validators.required],
      emptype: ['', Validators.required],
      businessCat: ['', Validators.required],
      indType: ['', Validators.required],
      businessName: ['', Validators.required],
      yearOfBusiness: ['', Validators.required],
      natureOfBusiness: ['', Validators.required],
      establishmentStatus: ['', Validators.required],
      cityName: ['', Validators.required],
      gender: ['', Validators.required],
      proofOfBusiness: ['', Validators.required],
      previousFinancialYear: ['', Validators.required],
      currentFinancialYear: ['', Validators.required],
      product: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      fatherFirstName: ['', Validators.required],
      fatherLasttName: ['', Validators.required],
      educationQualification: ['', Validators.required],
      motherFullName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      address3: ['', Validators.required],
      landmark: ['', Validators.required],
      pinecode: ['', Validators.required],
      reg_email: ['', [Validators.required, Validators.email]],
      creditProfile: ['', Validators.required],
      pennumer: ['', Validators.required],
      
    });

    this.adminServiceService
    .getBusinessYear()
    .subscribe( (bu:any) => {
   // console.log(bu.data[0].businessYear); return false;
      if(bu.response === 200 && bu.status === true ){
        for (let i = 0; i < bu.data[0].businessYear.length;  i++) {
          const element = bu.data[0].businessYear[i];
         // console.log(element); return false;
          this.businessYearArray.push(
            {
              value: element.bus_year_id,
              label: element.bus_year_name,                          
             
            }
            );
            //console.log(this.messages);
          //console.log(bu); return false;


         }
         this.businessYearOption =this.businessYearArray;
         console.log(this.businessYearOption);
      }else{
        console.log('businessYear Api faile');
      }
     
    


   });
   this.adminServiceService
   .getBusinessCategory()
   .subscribe( (buc:any) => {
   // console.log(buc.BusCatMaster); return false;
     if(buc.response === 200 && buc.status === true ){
       for (let i = 0; i < buc.BusCatMaster.length;  i++) {
         const element = buc.BusCatMaster[i];
        // console.log(element); return false;
         this.businessCategoryArray.push(
           {
             value: element.employer_catg_id,
             label: element.employer_catg,                          
            
           }
           );
           //console.log(this.messages);
         //console.log(bu); return false;


        }
        this.businessCategoryOption =this.businessCategoryArray;
        console.log(this.businessCategoryOption);
     }else{
       console.log('Business Category Api faile');
     }
    
   


  });
  
  this.adminServiceService
  .getIndustry()
  .subscribe( (ind:any) => {
 // console.log(ind); return false;
    if(ind.response === 200 && ind.status === true ){
      for (let i = 0; i < ind.industry.length;  i++) {
        const element = ind.industry[i];
       // console.log(element); return false;
        this.industryArray.push(
          {
            value: element.industry_id,
            label: element.industry_name,                          
           
          }
          );
          //console.log(this.messages);
        //console.log(bu); return false;


       }
       this.industryOption =this.industryArray;
       console.log(this.industryOption);
    }else{
      console.log('Business Category Api faile');
    }
   
  


   });
   
   this.adminServiceService
  .getBusNatureMaster()
  .subscribe( (BusNat:any) => {
  //console.log(BusNat); return false;
    if(BusNat.response === 200 && BusNat.status === true ){
      for (let i = 0; i < BusNat.natureMaster.length;  i++) {
        const element = BusNat.natureMaster[i];
       // console.log(element); return false;
        this.busNatureArray.push(
          {
            value: element.bus_nature_id,
            label: element.bus_nature_type,                          
           
          }
          );
          //console.log(this.messages);
        //console.log(bu); return false;


       }
       this.busNatureOption =this.busNatureArray;
       console.log(this.busNatureOption);
    }else{
      console.log('Business Category Api faile');
    }
   
  


   });
   
   this.adminServiceService
  .getDocumentproofBusiness()
  .subscribe( (prrofBus:any) => {
  ///console.log(prrofBus); return false;
    if(prrofBus.response === 200 && prrofBus.status === true ){
      for (let i = 0; i < prrofBus.DocumentBusiness.length;  i++) {
        const element = prrofBus.DocumentBusiness[i];
       // console.log(element); return false;
        this.prrofBusureArray.push(
          {
            value: element.doc_id,
            label: element.dscription,                          
           
          }
          );
          //console.log(this.messages);
        //console.log(bu); return false;


       }
       this.prrofBusOption =this.prrofBusureArray;
       console.log(this.busNatureOption);
    }else{
      console.log('Business Category Api faile');
    }
   
  


   });
   
   this.adminServiceService
  .getCreditPofile()
  .subscribe( (crd:any) => {
 // console.log(crd); return false;
    if(crd.response === 200 && crd.status === true ){
      for (let i = 0; i < crd.creditPofile.length;  i++) {
        const element = crd.creditPofile[i];
       // console.log(element); return false;
        this.crdureArray.push(
          {
            value: element.credit_profile_id,
            label: element.credit_profile,                          
           
          }
          );
          //console.log(this.messages);
        //console.log(bu); return false;


       }
       this.crdOption =this.crdureArray;
       console.log(this.busNatureOption);
    }else{
      console.log('Business Category Api faile');
    }
   
  


   });
   
   this.adminServiceService
   .getProduct()
   .subscribe( (product:any) => {
  //console.log(product); return false;
     if(product.response === 200 && product.status === true ){
       for (let i = 0; i < product.product.length;  i++) {
         const element = product.product[i];
        // console.log(element); return false;
         this.productureArray.push(
           {
             value: element.product_id,
             label: element.product_code                          
            
           }
           );
           //console.log(this.messages);
         //console.log(bu); return false;
 
 
        }
        this.productOption =this.productureArray;
        console.log(this.busNatureOption);
     }else{
       console.log('Business Category Api faile');
     }
    
   
 
 
    });
    this.adminServiceService
    .getEmploymentTyp()
    .subscribe( (emp:any) => {
   //console.log(emp); return false;
      if(emp.response === 200 && emp.status === true ){
        for (let i = 0; i < emp.empType.length;  i++) {
          const element = emp.empType[i];
         // console.log(element); return false;
          this.emptypeureArray.push(
            {
              value: element.employment_type_id,
              label: element.employment_type
                          
             
            }
            );
            //console.log(this.messages);
          //console.log(bu); return false;
  
  
         }
         this.emptypeOption =this.emptypeureArray;
         console.log(this.busNatureOption);
      }else{
        console.log('Business Category Api faile');
      }
     
    
  
  
     });
    
    this.maritalStatusOpt=[
      { value: '1', label: 'Married' },      
      { value: '2', label: 'Unmarried' },      
         
      
      
    ];
   this.genders=[
    { value: 'Male', label: 'Male' }, 
    { value: 'Female', label: 'Female' }, 
    
    
    ];
   this.SelectTenure= [
    { value: '12', label: '12' },      
    { value: '24', label: '24' },      
    { value: '36', label: '36' },
    { value: '48', label: '48' },      
  
    
  ];  
  this.SelectResidenceStatus= [
    { value: '1', label: 'Owned' },      
    { value: '2', label: 'Rented' },      
       
  
    
  ];  





  }
  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }
  private showError(error: string) {

    this.error = error;
    this.progress = false;
    // setTimeout(() => this.error = null, 5000);
  }

  get f() {
    return this.registerForm.controls;
  }
  getValue(data){
    this.prodcode=data.label;
  }
  onSubmit() {
    //console.log(this.f);
    this.moulenumer=this.f.moile.value;
    this.loanAmt=this.f.loanamt.value
    this.productcode=this.prodcode;
 //addy admin
    //adminId
    this.submitted = true;
    this.showLoader = false;
  //  console.log(this.f.fname.value);
    let details = {
     
        results: [{
          fname:this.f.fname.value,
          lname:this.f.lname.value,
          mname:this.f.mname.value,
          moile:this.moulenumer.toString(),
          dob:this.f.dob.value,
          pincode:this.f.pincode.value,
          loanamt:this.loanAmt.toString(),
          tenure:this.f.tenure.value,
          residingHere:this.f.residingHere.value,
          residenceStatus: this.f.residenceStatus.value,
          emptype: this.f.emptype.value,
          businessCat:this.f.businessCat.value,
          indType:this.f.indType.value,
          businessName:this.f.businessName.value,
          yearOfBusiness:this.f.yearOfBusiness.value,
          natureOfBusiness: this.f.natureOfBusiness.value,
          establishmentStatus:this.f.establishmentStatus.value,
          cityName:this.f.cityName.value,
          gender:this.f.gender.value,
          proofOfBusiness:this.f.proofOfBusiness.value,
          previousFinancialYear:this.f.previousFinancialYear.value,
          currentFinancialYear:this.f.currentFinancialYear.value,
          product:this.f.product.value,
          maritalStatus:this.f. maritalStatus.value,
          fatherFirstName:this.f.fatherFirstName.value,
          fatherLasttName:this.f.fatherLasttName.value,
          educationQualification:this.f.educationQualification.value,
          motherFullName:this.f.motherFullName.value,
          address1:this.f.address1.value,
          address2:this.f.address2.value,
          address3:this.f.address3.value,
          
          landmark:this.f.landmark.value,
          pinecode:this.f.pinecode.value,
          reg_email:this.f.reg_email.value,
          creditProfile:this.f.creditProfile.value,
          pennumer:this.f.pennumer.value ,
          adminId:this.loginuserid,
          productcode:this.productcode,
          tempid:this.f.tempid.value,
        }]
  
      
    };
     console.log(details);
     this.adminServiceService.insertUserForEligibilityComplete(details).subscribe(             
      (insertData:any) => {
        if(insertData.status === true){
          this.showLoader = false;
          this.router.navigate(['/Leads-listing']);
        }else{
          this.showLoader = false;
          this.router.navigate(['/Leads-listing']);
        }
        console.log(insertData);
      });
     
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }
    
    //this.registerNew(this.f.reg_email.value, this.f.reg_password.value)
   

    //this.CreateRecord(credential.user)

    // this.router.navigate['/login'];
  }
  private showSuccess(message: string) {

    this.success = message;
    this.progress = true;
    setTimeout(() => this.successMessage = null, 5000);
  }
  public registerNew(email: string, password: string) {
    var user = null;
    // Create a new user with email and password
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(credential => {

        user = firebase.auth().currentUser;
        setTimeout(() => {
          this.showLoader = true;
        }, 2000);
        //  this.SendVerificationMail();
        // this.CreateRecord()
        var userdet = this.afAuth.auth.currentUser;

        var fname, useremail, uid, emailVerified, role_code, lname, providerId, password;


        if (userdet != null) {
          fname = this.f.fname.value;
          useremail = userdet.email;
          emailVerified = userdet.emailVerified;
          uid = userdet.uid;
          role_code = this.f.role_code.value;
          lname = this.f.lname.value;
          providerId = userdet.providerData[0].providerId;
          password = this.f.reg_password.value
        }

        let details = {
          data: {
            results: [{
              "fb_uid": uid,
              "first_name": fname,
              "Middle_Name": "",
              "last_name": lname,
              "email": useremail,
              "provider_id": providerId,
              "mobileno": "",
              "otp": "",
              "iemi": "",
              "macaddress": "",
              "ipaddress": "",
              "osid": "",
              "uuid": "",
              "rolecode": role_code,
              "profile_pic": "",
              "mobile_verified_on": "",
              "password": password
            }]

          }
        };
        details['data']['results'][0]['created_on'] = userdet.metadata.creationTime;
        details['data']['results'][0]['signin_on'] = userdet.metadata.lastSignInTime;
        // console.log(details) 
        this.registerService.addUserData(details)
          .subscribe((result: any) => {
            //    console.log(result)
            if (result.status == 'TRUE') {
              this.token = result.result.token_key;
              details['data']['results'][0]['token'] = this.token;
              this.firebasedataService.create_new(details.data.results[0]);
              this.afAuth.auth.currentUser.sendEmailVerification();
              this.success = "Successfully registered";
              this.showSuccess(this.success);
              setTimeout(() =>               this.reportSuccess('Registred', '/login'), 5000);
            } else {
              var userdet = this.afAuth.auth.currentUser;
              // console.log(userdet)
              userdet.delete();
              this.errorMessage = 'error in registration';
              this.showError(this.errorMessage);
              this.reportSuccess('Registred', '/register')
            }



          });



      })
      .catch(error => {
        console.log(error)
        this.showLoader = false;
        setTimeout(() => {
          this.showLoader = true;
        }, 2000);
        if (error.code == 'auth/email-already-in-use') {
          this.errorMessage = 'Your email adress is alredy registerd with us';

        }
        if (error.code == 'auth/weak-password') {
          this.errorMessage = 'Password should be at least 6 characters';

        }
        this.showError(this.errorMessage);

      });
  }

}
