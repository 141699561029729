import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { CookieService } from '../services/cookie/cookie.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Role } from '../model/role/role';
import { ReportService } from '../services/report/report.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';
@Injectable()
export class AuthGuard implements CanActivate {
    token: string;
    showMobile: boolean;
  //  private token;
   // private user: User;
    sub: Subscription;
    exists:boolean;
  progress: boolean;

    constructor(
        private router: Router,
        private cookieService:CookieService,
        private authenticationService:AuthenticationService,
        private loginServiceService: LoginServiceService,
        private afAuth :AngularFireAuth,
        public report:ReportService
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :Observable<boolean> | Promise<boolean> | boolean{
    
      const currentUser =  this.loginServiceService.userData();
      const token=   this.cookieService.get('token_rm');
     //const token= localStorage.getItem('token_rm');
     //console.log(token)
        if (token && token !='') {
          //console.log('logged in');
          return true;

         
        }else{
          //console.log('notlogged in');
         // window.location.reload();
        this.reportSuccess('not logged in','/login');
       
        return false;
     
      }
      
 
    }
    private reportSuccess(message: string, jumpTo?: string) {
    
      this.progress = false;
      this.report.add(message);
  //console.log(message)
      if(jumpTo) {
        
        this.router.navigate([jumpTo])
     
      }
    }
    goToUrl() {
        window.location.href = 'http://meraemi.c/user/sess_logout';
        this.cookieService.deleteAll();
    }
   
    

      
  
}