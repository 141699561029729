import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {
  AngularFireAuth
} from 'angularfire2/auth';

import {
  Observable,
  Subscription
} from 'rxjs';
import {
  User
} from 'firebase';

import * as firebase from 'firebase';

import { LoginServiceService } from 'src/app/services/login/login-service.service';

export {
  User
}
from 'firebase';
import {
  CookieService
} from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  public authState: User = null;
  private user: User;
  private sub: Subscription;
  error: string;
  progress: boolean;
  errorMessage: string;
  currentUser: any = [];
  showLoader: boolean;
  hide: boolean = true;
  cryptojsService: any;
  decryptData: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
   
    private loginServiceService: LoginServiceService,
    
    public cookieService: CookieService

  ) {}

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.loginForm = this.formBuilder.group({
      login_email: ['', [Validators.required, Validators.email]],
      login_password: ['', [Validators.required,Validators.minLength(6)]],

    });
    this.sub = this.authState$.subscribe(user => {

      this.user = user;
      //  console.log(user)

    });


  }

  private showError(error: string) {

    this.error = error;
    this.progress = false;
    // setTimeout(() => this.error = null, 5000);
  }

  get authenticated() {
    return this.user !== null;
  }


  get authState$(): Observable < User | null > {
    return this.afAuth.user;
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.signIn(this.f.login_email.value, this.f.login_password.value)
    // this.updateloginTime()

  }

  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    //this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }

  public signIn(email: string, password: string) {
    //console.log("Signing in as: " + email);
         
         let logindata={ results: [{"email":email,"pass":password}]};
       //  console.log(details);
         this.loginServiceService.loginData(logindata)
         .subscribe((loginuser: any) => {
          if(loginuser.response=="200" && loginuser.status=="TRUE" ){
          
            let data=loginuser.result
            console.log(data);
           // rajeevpathak121@gmail.com
            if (data && data != null) {
           // console.log(loginuser); //return false;
           //
            
              this.cookieService.set('currentUser', JSON.stringify(data));
             this.cookieService.set('token_rm', data.token);
              this.reportSuccess('Signed in as ' + email, 'dashboard')
              setTimeout(() => {
                this.showLoader = true;
              }, 2000);
            } else {
              this.showLoader = false;
              setTimeout(() => {
                this.showLoader = true;
              }, 2000);
              this.errorMessage = "Error while login";
              this.showError(this.errorMessage);
              //  this.reportSuccess('Signed in as ' + email,'rm-login')


            }



        
        
           
            

           
          }else{
            this.showLoader = false;
              setTimeout(() => {
                this.showLoader = true;
              }, 2000);
              this.errorMessage = "Error while login";
              this.showError(this.errorMessage);
              //  this.reportSuccess('Signed in as ' + email,'rm-login')


            

          }
         
          //console.log(result); 
        });
    
  }

  ngOnDestroy() {
    this.sub.unsubscribe();

  }

}
