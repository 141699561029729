import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { MasterService } from 'src/app/services/master/master.service';
import {  AdminService} from 'src/app/services/admin/admin.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-business-category',
  templateUrl: './business-category.component.html',
  styleUrls: ['./business-category.component.scss']
})
export class BusinessCategoryComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("add", {static: true}) add: ModalDirective;
  @ViewChild("edit", {static: true}) edit: ModalDirective;
  searchText: any;
  elements: any = [];
  previous: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
  emptypeureArray: any = [];
  emptypeOption: any = [];
  headElements = [
    {
       name:"Business Category  Id",
       sortBy:"Doc Id",
       width: 150
     },
     {
      name:"Business Category Name",
      sortBy:"Business Category Name",
      width: 150
    },
    
     {
      name:"Employment Type  Name",
      sortBy:"Employment Type  Name",
      width: 150
    },
    {
      name:"Action",
      sortBy:"Action",
      width: 150
    }

   ];
  employer_catg_id: any;
  employer_catg: any;
  
  constructor( 
      private cryptojsService:CryptojsService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      public adminServiceService: AdminService,
      private masterService : MasterService,  
      
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({

      addformgup: this.formBuilder.group({
       addname: ['', Validators.required],
       empcatid: ['', Validators.required],
        
      }),
      editformgup: this.formBuilder.group({
       editname: ['', Validators.required]
      
    }),
      
    });
    this.masterService
    .getBussCatMaster()
    .subscribe( (buc:any) => {
      console.log(buc); return false; 
     if(buc.response === 200 && buc.status === true ){
      for (let i = 0; i < buc.empcat.length;  i++) {
        const element = buc.empcat[i];
       // console.log(element); return false; 
                            this.elements.push(
                              {
                                employer_catg_id	:element.data[0].employer_catg_id,
                                employer_catg: element.data[0].employer_catg,
                                employment_type: element.data[0].employment_type,
                               
                           
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
          this.adminServiceService
          .getEmploymentTyp()
          .subscribe( (emp:any) => {
         //console.log(emp); return false;
            if(emp.response === 200 && emp.status === true ){
              for (let i = 0; i < emp.empType.length;  i++) {
                const element = emp.empType[i];
               // console.log(element); return false;
                this.emptypeureArray.push(
                  {
                    value: element.employment_type_id,
                    label: element.employment_type
                                
                   
                  }
                  );
                  //console.log(this.messages);
                //console.log(bu); return false;
        
        
               }
               this.emptypeOption =this.emptypeureArray;
               console.log(this.emptypeOption);
            }else{
              console.log('Business Category Api faile');
            }
           
          
        
        
           });
          
    
    
  }
    //intraktUrlAPIlisting
    // add  
  submittedAdd = true;
  get rs() { return (<FormGroup>this.registerForm.get('addformgup')).controls; }
  get addformgup() {
    return this.registerForm.get('addformgup');
  }
  // edit 

  submittedEdit = true;
  get ed() { return (<FormGroup>this.registerForm.get('editformgup')).controls; }
  get editformgup() {
    return this.registerForm.get('editformgup');
  }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(7);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 
 
  editformdata(data){
    console.log(data);
    this.employer_catg_id=data.employer_catg_id;
    this.employer_catg=data.employer_catg;
    //console.log(data);

    
    this.showLoader = false;
    setTimeout(() => {
    this.edit.show();
      this.showLoader = true;
    }, 1000);
   
  }
  editbtn(){
    this.submittedEdit = true;
    this.showLoader = false;
  
    // stop here if form is invalid
    if (this.editformgup.invalid) {
      console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);
  
      return;
    }else{
  
      let editname=this.editformgup.value.editname;
      console.log(editname);
      let detail = { results: [{ "editname":editname,"employer_catg_id":this.employer_catg_id}] };
      detail['results']; 
      this.masterService.editEmpCatmaster(detail).subscribe((result: any) => {
  
        if(result.response === 200 && result.status === true){
          this.showLoader = true;
          this.elements=[];
         
            this.edit.hide();
            this.masterService
            .getBussCatMaster()
            .subscribe( (buc:any) => {
             if(buc.response === 200 && buc.status === true ){
              for (let i = 0; i < buc.empcat.length;  i++) {
                const element = buc.empcat[i];
               // console.log(element); return false; 
                                    this.elements.push(
                                      {
                                        employer_catg_id	:element.data[0].employer_catg_id,
                                        employer_catg: element.data[0].employer_catg,
                                        employment_type: element.data[0].employment_type,
                                       
                                   
                                                  
                                        
                                        
                                        
        
                                      }
                                      );
                                      
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
        }
      
      });
    }
  }
  addBtn(){
    this.submittedAdd = true;
    this.showLoader = false;

    // stop here if form is invalid
    if (this.addformgup.invalid) {
      console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }else{
      let addname=this.addformgup.value.addname;
      let empcatid=this.addformgup.value.empcatid;
      
      let detail = { results: [{ "addname":addname,"empcatid":empcatid}] };
      detail['results']; 
      this.masterService.addBussCategorymaster(detail).subscribe((add: any) => {
        if(add.response == 200 && add.status == true){
          this.showLoader = true;
          this.addformgup.get('addname').setValue('');
         
          this.elements=[];
          this.add.hide();
          this.masterService
          .getBussCatMaster()
          .subscribe( (buc:any) => {
           if(buc.response === 200 && buc.status === true ){
            for (let i = 0; i < buc.empcat.length;  i++) {
              const element = buc.empcat[i];
             // console.log(element); return false; 
                                  this.elements.push(
                                    {
                                      employer_catg_id	:element.data[0].employer_catg_id,
                                      employer_catg: element.data[0].employer_catg,
                                      employment_type: element.data[0].employment_type,
                                     
                                 
                                                
                                      
                                      
                                      
      
                                    }
                                    );
                                    
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
               
        }
      
      });
    

    }
  }
  deleteForm(data){
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
  //console.log(data);
    
    this.employer_catg_id   =data.employer_catg_id;    
    this.masterService.deleteempcat(this.employer_catg_id)
    .subscribe((del:any) => {
      if(del.response === 200 && del.status === true ){
        this.elements=[];          
        this.adminServiceService
        this.masterService
        .getBussCatMaster()
        .subscribe( (buc:any) => {
         if(buc.response === 200 && buc.status === true ){
          for (let i = 0; i < buc.empcat.length;  i++) {
            const element = buc.empcat[i];
           // console.log(element); return false; 
                                this.elements.push(
                                  {
                                    employer_catg_id	:element.data[0].employer_catg_id,
                                    employer_catg: element.data[0].employer_catg,
                                    employment_type: element.data[0].employment_type,
                                   
                               
                                              
                                    
                                    
                                    
    
                                  }
                                  );
                                  
                                    
                }
              //console.log(this.elements);
                this.mdbTable.setDataSource(this.elements);
                this.elements = this.mdbTable.getDataSource();
                this.previous = this.mdbTable.getDataSource();
                }else{
                  console.log('User list Api faile');
                }
                
              });
      }else{
        console.log('User list Api faile');
      }

    });
  }








        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}