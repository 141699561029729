import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
   
   // *local  token
    // bearer_token:string = "Bearer  18e86f14-ef87-4d82-9d77-4495d26330c1";
   //* CRM token
     // bearer_token:string = "Bearer    c4dd8db1-b5cf-463b-867b-01e992703a37";
   //* uat token
     bearer_token:string = "Bearer a88c7f7e-03e8-4fb9-a517-5924c268bfea";
 
  
  constructor() { }
}
    




