import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})

export class FeedbackService {

  constructor( private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,) { }
    bearer_token = this.TokenService.bearer_token ;
    postfeedback(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/InsertFeedbackIap',{data:data},{headers: headers});
       console.log(headers);
     }
     postfeedback_getcrm(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/get_feedback',{data:data},{headers: headers});
       console.log(headers);
     }
     postSubfeedbackComment(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/postSubfeedbackComment',{data:data},{headers: headers});
       console.log(headers);
     }
     GetSubfeedbackComment(data){
  
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
       .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
       return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/GetSubfeedbackComment',{data:data},{headers: headers});
       console.log(headers);
     }
    //  postPendingfeedback(data){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //   return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/postPendingfeedback',{data:data},{headers:headers});
  
    // }
    // getPendimgFeedback(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    //   return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/pendingFeedback',{headers:headers});
    // }
    // getPendimgMeraemiStage(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    //   return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/getPendimgMeraemiStage',{headers:headers});
    // }
    // GetFollowUpStage(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    //   return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/GetFollowUpStage',{headers:headers});
    // }
     //get all pending stage as same day
    //  getPendingMeraemiStage(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    //   return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/getPendingMeraemiStage',{headers:headers});
    // }
    // getAllStage(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    //   return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/Stage',{headers:headers});
    // }
    // GetFollowUpEligMessag(){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    // .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    // return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/GetFollowUpEligMessag',{headers:headers});
    // }
     //post meaasge elig
    //  postfeedbackElig(data){
    //   let headers = new HttpHeaders().set('Content-Type', 'application/json')
    //   .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
    //   return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/InsertFeedbackElig',{data:data},{headers:headers});
  
    // }
    GetContactRequest(){
      let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'Contact/getContactRequest',{headers:headers});
    }
       //post meaasge elig
       postwhatsap(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'app/whatsap',{data:data},{headers:headers});
    
      }
      postwhatsapInsertdataadmin(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'app/whatsapInsertDataAdmin',{data:data},{headers:headers});
    
      }
      GetWhatsapUserdata(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/GetWhatsapUserdata',{data:data},{headers:headers});
    
      }
    
      SendEmailAdminUserTransfer(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/SendEmailAdmin',{data:data},{headers:headers});
    
      }
      
      getproduct(){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/GetProduct',{headers:headers});
      }
      GetLendernameUserTransfer(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/GetLendernameUserTransfer',{data:data},{headers:headers});
    
      }
      updateUserAdId(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/updateUserAdId',{data:data},{headers:headers});
    
      }
      getlendermaster(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/getlendermaster',{data:data},{headers:headers});
    
      }
      GetMeraemiStage(){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
      return this.http.get<Global>(`${this.global.apiurl}`+'LeadStage/GetMeraemiStage',{headers:headers});
      }
      GetMeraemiStageFilter(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/GetMeraemiStageFilter',{data:data},{headers:headers});
    
      }
      GetOldFeedbackMessage(data){
        let headers = new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
        return this.http.post<any>(`${this.global.apiurl}`+'LeadStage/GetOldFeedbackMessage',{data:data},{headers:headers});
    
      }
}
