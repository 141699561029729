import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {
  MustMatch
} from 'src/app/helpers/must-match.validator';
import {
  AngularFireAuth
} from 'angularfire2/auth';
import {
  FirebasedataService
} from 'src/app/services/firebasedata/firebasedata.service';
import {
  auth,
  User
} from 'firebase';
import * as firebase from 'firebase';
import {
  RegisterService
} from 'src/app/services/register/register.service';
import {
  ReportService
} from 'src/app/services/report/report.service';
import {
  CryptojsService
} from 'src/app/services/cryptojs/cryptojs.service';
import {  AdminService} from 'src/app/services/admin/admin.service';
export {
  User
}
from 'firebase';

@Component({
  selector: 'app-quick-data-entry',
  templateUrl: './quick-data-entry.component.html',
  styleUrls: ['./quick-data-entry.component.scss']
})
export class QuickDataEntryComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean;
  exist: Promise < boolean > ;
  error: string;
  progress: boolean;
  errorMessage: string;
  RM: 'RM';
  hide: boolean = true;
  hideconf: boolean = true;
  showLoader: boolean;
  token: any;
  successMessage: any;
  success: string;
  genders:any=[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public afAuth: AngularFireAuth,
    private firebasedataService: FirebasedataService,
    private registerService: RegisterService,
    private report: ReportService,
    public cryptojsService: CryptojsService,
    public adminServiceService: AdminService,
  ) {}


  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.genders=[
      { value: 'Male', label: 'Male' }, 
      { value: 'Female', label: 'Female' }, 
      
      
      ];
    this.registerForm = this.formBuilder.group({
      fname: ['', Validators.required],
      mname: ['', Validators.required],
      lname: ['', Validators.required],
      moile: ['', Validators.required],
      gender: ['', Validators.required],
      reg_email: ['', [Validators.required, Validators.email]],
     
    });
  }
  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }
  private showError(error: string) {

    this.error = error;
    this.progress = false;
    // setTimeout(() => this.error = null, 5000);
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.showLoader = false;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      return;
    }else{
      let details = {
          
        results: [{
                 fname : this.f.fname.value,
                 mname : this.f.mname.value,
                  
                 lname : this.f.lname.value,
                 moile : this.f.moile.value,
                 email : this.f.reg_email.value,
                 provider_id : "3",
                  gender: this.f.gender.value,
                 }]
        };
        console.log(details);
        this.adminServiceService.insertUserForLead(details).subscribe(             
         (insertData:any) => {
          if(insertData.status=== true){
            this.showLoader = false;
            this.router.navigate(['/ctwa-lead-listing']);
          }else{
            this.showLoader = false;
            this.router.navigate(['/quickDataEntry']);
          }
          // console.log(insertData);
         });
    }
          
   
   
  }
  private showSuccess(message: string) {

    this.success = message;
    this.progress = true;
    setTimeout(() => this.successMessage = null, 5000);
  }
  backmenu(){
    
  }

}
