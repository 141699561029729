import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from '../main-layout/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { SetPasswordComponent } from './set-password/set-password.component';

import { ModalComponent } from './modal/modal.component';












import { UserRegisterComponent } from './user-register/user-register.component';

import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import { UserListinComponent } from './user-listin/user-listin.component';
import { UserLeadDetailsComponent } from './user-lead-details/user-lead-details.component';
import { LeadsListingComponent } from './leads-listing/leads-listing.component';
import { LeadsDetailsComponent } from './leads-details/leads-details.component';
import { MastercalculatorComponent } from './mastercalculator/mastercalculator.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { TeamSettingComponent } from './team-setting/team-setting.component';
import { DocTypeMasterComponent } from './doc-type-master/doc-type-master.component';

import { DocCategoryMasterComponent } from './doc-category-master/doc-category-master.component';
import { CrmStatusComponent } from './crm-status/crm-status.component';
import { BusYearComponent } from './bus-year/bus-year.component';
import { ProductComponent } from './product/product.component';
import { EmpMasterComponent } from './emp-master/emp-master.component';
import { BusinessCategoryComponent } from './business-category/business-category.component';
import { IndustryComponent } from './industry/industry.component';
import { NatureOfBusinessComponent } from './nature-of-business/nature-of-business.component';
import { UsersSalariedComponent } from './users-salaried/users-salaried.component';
import { SalariedDetailsComponent } from './salaried-details/salaried-details.component';
import { SelfEmployedComponent } from './self-employed/self-employed.component';
import { SelfEmpLeadsDetailsComponent } from './self-emp-leads-details/self-emp-leads-details.component';
import { ProfessionalComponent } from './professional/professional.component';
import { ProfessionalDetailsComponent } from './professional-details/professional-details.component';
import { UserListingCTWAComponent } from './user-listing-ctwa/user-listing-ctwa.component';
import { UserCTWADetailsComponent } from './user-ctwadetails/user-ctwadetails.component';
import { LeadListingCTWAComponent } from './lead-listing-ctwa/lead-listing-ctwa.component';
import { LeadCTWADetailsComponent } from './lead-ctwadetails/lead-ctwadetails.component';
import { QuickDataEntryComponent } from './quick-data-entry/quick-data-entry.component';
import { DetailedDataEntryComponent } from './detailed-data-entry/detailed-data-entry.component';
import { UpdateUserProfileComponent } from './update-user-profile/update-user-profile.component';
import { MeraemiBusinessPartnerComponent } from './meraemi-business-partner/meraemi-business-partner.component';
import { BusinessPartnerDetailsComponent } from './business-partner-details/business-partner-details.component';




@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    PdfViewerModule,
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'AIzaSyCb44fZMVNTqsA7phK5chbOolMgsJl9mFw'
    }),
    CalendarModule.forRoot()
  ],
  declarations: [
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    ResetPasswordComponent,   

    SetPasswordComponent,
   
    ModalComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UserRegisterComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UserListinComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UserLeadDetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    LeadsListingComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    LeadsDetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    MastercalculatorComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    ApplicationFormComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    TeamSettingComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    DocTypeMasterComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    DocCategoryMasterComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    CrmStatusComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    BusYearComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    ProductComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    EmpMasterComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    BusinessCategoryComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    IndustryComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    NatureOfBusinessComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UsersSalariedComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    SalariedDetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    SelfEmployedComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    SelfEmpLeadsDetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    ProfessionalComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    ProfessionalDetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UserListingCTWAComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UserCTWADetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    LeadListingCTWAComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    LeadCTWADetailsComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    QuickDataEntryComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    DetailedDataEntryComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    UpdateUserProfileComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    MeraemiBusinessPartnerComponent,

    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   


    
   
  
  
   
    
   
    
   
   
   
   

   
   

 
   

   
    BusinessPartnerDetailsComponent,
   
    
    
  ],
  exports: [
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    SetPasswordComponent,
    ResetPasswordComponent,  
 
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }
