
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MdbTablePaginationComponent,IMyOptions, MDBDatePickerComponent, ClockPickerComponent,ModalDirective,MdbTableDirective } from 'ng-uikit-pro-standard';

import { CryptojsService } from 'src/app/services/cryptojs/cryptojs.service';
import { ActivatedRoute, Router } from '@angular/router';


import { TrackprogressService } from 'src/app/services/trackprogress/trackprogress.service';
import { FormBuilder,FormControl, Validators ,FormGroup} from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FirebasedataService, User } from 'src/app/services/firebasedata/firebasedata.service';
import { Subscription, Observable, empty } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireAuth } from 'angularfire2/auth';

//import * as saveAs from 'file-saver';
import { saveAs } from 'file-saver';
import {formatDate } from '@angular/common';

import { DatePipe } from '@angular/common';
import { contains } from 'jquery';


import { CtwaService } from 'src/app/services/ctwa/ctwa.service';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import {  AdminService} from 'src/app/services/admin/admin.service';
import { LoginServiceService } from 'src/app/services/login/login-service.service';

@Component({
      providers: [DatePipe],

      selector: 'app-user-lead-details',
      templateUrl: './user-lead-details.component.html',
      styleUrls: ['./user-lead-details.component.scss']
})
export class UserLeadDetailsComponent implements OnInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("frame", {static: true}) frame: ModalDirective;
  @ViewChild("frame1", {static: true}) frame1: ModalDirective;
  @ViewChild("frame2", {static: true}) frame2: ModalDirective;
  @ViewChild("docRecive", {static: true}) docRecive: ModalDirective;
  @ViewChild("docVeryFiNo", {static: true}) docVeryFiNo: ModalDirective;
  @ViewChild("docVeryFiYes", {static: true}) docVeryFiYes: ModalDirective;
  @ViewChild("feedbackquate", {static: true}) feedbackquate: ModalDirective;
  @ViewChild("doc", {static: true}) doc: ModalDirective;
  @ViewChild("docCat", {static: true}) docCat: ModalDirective;
  @ViewChild("docReject", {static: true}) docReject: ModalDirective;
  @ViewChild("assigned", {static: true}) assigned: ModalDirective;
  
  
  headElements = [
    
     
     {
      name:"Doc Name",
      sortBy:"Document_Name"
    },
     {
       name:"Doc Type",
       sortBy:"Doc_Type"
     },     
     {
       name:"Doc Comment",
       sortBy:"Doc_c"
     },
     {
       name:"Date",
       sortBy:"Date"
     },
     {
       name:"View",
       sortBy:"View"
     }


   ];
  fullerton_lender_id:  number=0;
  idfc_lender_id:  number=0;
  loantab_lender_id:  number=0;
  public radioModel: string = 'Left';
  tab : any = 'tab1';
  tab1 : any
  tab2 : any
  tab3 : any
  Clicked : boolean
  @ViewChild("datePicker", {static: true}) darkPicker: MDBDatePickerComponent;
  darkClock: any;
  user:any;
  subs: Subscription;
  sub: any;
  data: any;
  showLoader: boolean;
  decryptData: any;
  leadDetails: any=[];
  elements: any=[];
  elements_feedback: any=[];
  elementsfeedback: any=[];
  meraemiStage: any=[];
  showDocuments:boolean=false;
  custsD = {};
  driveapi = {};
  cust: any;
  ProposedEmi: number;
  foir: number;
  e_emi: number;
  interest_rate: number;
  isTrackProgress:boolean=true;
  trackData= {};
  trxStatus: any;
  trxSubStatus: any;
  requestJsonDisabled:boolean=false;
  responseJsonDisabled:boolean=false;
  trackProgressJsonDisabled:boolean=false;
  iapstage: string;
  mapstage:string;
  iap_stage_color: string;
  eligstage: string;
 
  elig_stage_color: string;
  userdetils: {};
  //registerForm: any;
  registerForm: FormGroup;
  useridSubmitted: boolean;
  quateSubmitted: boolean;
  usercrmSubmitted: boolean;
  FileRejectSubmitted: boolean;
  from_user_id: any;
  message: any;
  user_id: any;
  feedForId: any;
  display: string;
  name = "Angular CLI: 11.2.1,  Node: 13.14.0,  OS: win32 x64";
  checkFormArray: Array<any> = [];
  
  // categories = [ 
  //   {"checked": true, name :"Active", id: 1},
  //   {"checked": false, name :"Cancel", id: 2},
  //   {"checked": false, name :"Transfer", id: 3},
    
  // ];
  followupdate: any;
  interactiondate: any;
  status: any;
  followuptime: any;
  today= new Date();
  jstoday = '';
  popupaddBtn: number;
  mesg: string;
  messagediv: number;
  checkAction: string;
  fedbacklength: number=0;
  fedbackStage: any;  
  currentdate: string;
  userdate: string;
  rolecode: any;
  popupbtnactive: any;
  loginAdmin: any;
  loanid: any;
  elig_status: string;
  eligStatus: any;
  feedback_Stage: any;

  activeClass: string;
  valuemain: number=0;
  userid: number=0;
  tabdiv: number=0;
  isValue: number = 0;
  idfc: number=0;
  fullerton: number=0;
  document: number=0;
  stage: number=0;
  aapstage: number=0;
  
  lenderResponse: any = [];

  sub_lender_Response: any = [];
  min_lender_Response: any = [];
  lenderMainArrya: any[];
  iapstageBackColour: string;
  datesarray: any=[];
  re_active: Date;
  re_active_feedback: any=[];
  reactive_stage: any;
  ConsentArray: any=[];
  criteriafail: any=[];
  eligStage: string;
  eligArray: any=[];
  lenderStage: any=[];
  quest_label: any;
  quest_value_text: any;
  custError: any= [];
  ErrorDetils= {};
  
  eligError: {};
  eligerrorstage: number=0;
  oldfeedbank: number=0;
  eligcheck: number=0;
  remainingText: number=0;
  iapstageBack_Colour: string;
  tabval: number;
  title: any;
  imageur: string;
  css: string;
  jsonresp: number=0;
  provider: string;
  logindate: any;
  loindateExp: number=0;
  kysdocback: string;
  lenderOfflineResponse: number=0;
  mapiapstage: string;
  trx_status: string;
  useid: any;
  lenderExcelldown: string;
  idfcbackcolour: string;
  criteriafailstage: number=0;
  criteriafailaapstage:string;
  dropoffdate:string;
  sys_user_dropoffs_msg:string;
  transaction_stage:string;
  bot_stage_name: string;
  userdetails: any;
  question: any;
  answer: any;
  lenderapiFail: string;
  lenderapiFailstatus: number=0;
  whatstage: number=0;
  adminname: any;
  whatssenduserdate: any;
  whatstatus: any;
  whatsapmessage: any;
  interaktstage: number=0;
  interaktstagependingElig: number=0;
  googledrive : any=[];
  googledriveErrorMsg : any=[];
  googledriveDeleteDocErrorMsg : any=[];
  listofdeletdocComp: any=[];
  deleteDocErrorMsg : any=[];
  drivelength: number=0;
  usewebContentLinkid: any;
  IapInteraktstage: number=0;
  EligInteraktstage: number=0;
     whataapelig: number=0;
     mobileintr: string;
     interaktIapPending : number=0;
     IapPenndInteraktstage: number=0;
     FeedbackPopup: number=0;
     transfertabopen: number=0;
      optionsmeraemistage: { value: string; label: string; }[];
      GetDocReceivedlist: { value: string; label: string; }[];
      crmdocveryfi: { value: string; label: string; }[];
       optionsArray: any = []; 
       lenderArray: any = []; 
   messages: any = []; 
   feedbackProductname: string;
feedbackLendername: number=0;
getProduct: any = []; 
docCategory: any = []; 
getfileError: any = []; 
productArray: any = [];
listfileError: { value: string; label: string; }[];
  meraemistage:   any=[];
  meraemi_stage: any;
  feedbackdatepikerdisable: number=0;
  doc_recevied: number;
  usertransfer: any= []; 
  mainlenderarrayfilter: any= []; 
  adid: string;
  userdetilspage:{};
  loantabuserid: any;
  loantabloan_code: any;
  loantabloan_id: any;
  loantabdiv: number=0;
  adminid: any;
  loantabapishow: number=0;
  crmStage: string;
  crmDate: string;
  appstage: string;
  substagelender: string;
  appstagedate: any;
  appstageflu: string;
  substagelenderflu: string;
  appstagedateflu: string;
  appstageLoantap: string;
  substagelenderLoantap: string;
  appstagedateLoantap: any;
  lenderstage: string;
  loantaparray:  any = [];
  Loan_Tap_Normal: number=0;
  Loan_Tap_Impact: number=0;
  getmeraemistages: any = [];
  GetDocReceived: any = [];
  documentcomplete: any = [];
  loantabLendername :string;
  loantabMessage:string;

  LoantabDate:string;
  LoantabStatus:string;
  crm_api_call_id:string;
  lapp_id:string;
  lontap_error:string;
  betncolour:string;
  crmstageFilter: any= []; 
  maincrmstageFilter: any= []; 
  userstage:string;
  feedbacklenderid: string;
  feedbackloanid: string;
  feedbackstage: string;
  hdbf_lender_id: number=0;
  hdbbtn: number=0;
  idfcapid: string;
  lenderStatus: any;
  ApiCallLenderResponse: any = [];
  ApiCallLenderResponseOption:  any = [];
  OldFeedbackMessage:  any = [];
  subFeedback:  any = [];
  hdb:  number=0;
  LoanTap:  number=0;
  fedbacklengthOld: any;
  lenderid : number=0;
  lender_btn: string;
  hdbloder: number;
  loantaploder: number;
  docPending: any = [];
  docReceived: any = [];
  lenderloantabactive: number;
  lenderHDBactive: number;
  docmesg: string;
  invalid_msg_id: any;
  doc_response_id: any;
  doc_id: any;
  doccomplete: number=1;
  CrmStage: any;
  docid: number=0;
  setcounter: number=0;
  checkedInfo: any;
  userRoleListTemp: any = [];
  userRoleListToSave: any = [];
  inputs: any;
  doccompletebtn: number=0;
  form_id: any;
  quate_feedback: any;
  feedid: any;
  uid: any;
  whatloder: number;
  intResult: any;
  meraemiStages: string;
  docPendingStatus: number=0;
  ReceivedStatus: number=0;
  docReceiveduser:  any = [];
  userdocReceived: number=0;
  ctwaDocs:  any = [];
  doclength: number;
  ctwaurl: any;
  msgid: any;
  ctwauid: any;
  sanitizer: any;
  pdfimg: number;
  docCategoryArray: any = [];
  catid: number=0;
  doclist: any = [];
  doclistArray: any = [];
  duserid: any;
  dname: string;
  dob: any;
  eligStages: any;
  email: any;
  moile: any;
  reg_date: any;
  loanApp: any;
  location: any;
  elig_date: any;
  loan_code: any;
  masterdata: any;
  product_name: any;
  loan_id: any;
  loginuserid: any;
  fname: any;
  lname: any;
  crmstage: any;
  name_of_employer: any;
  lender_name: any;
  industry_name: any;
  employer_catg: any;
  bus_year_name: any;
  address: any;
  address_1: any;
  address_2: any;
  address_3: any;
  address_pincode: any;
  aboutMe: any;
  bussDetails: any;
  loanapp: any;
  loginuser: any;
  loginDetails: any;
  education_qualification: any;
  since_residing_here: any;
  father_first_name: any;
  father_last_name: any;
  gender: any;
  marital_status: any;
  residence_status: any;
  mother_name: any;
  pen_no: any;
  employment_type: any;
  bus_nature_type: any;
  prev_fin_year: any;
  short_name: any;
  estb_status: any;
  dscription: any;
  loan_amt: any;
  city_name: any;
  tenure: any;
  email_id: any;
  ad_id: any;
  last_name: any;
  mobile_no: any;
  first_name: any;
  provider_id: any;
  lead_id: any;
  userlanme: any;
  userfname: any;
  usermoile: any;
  useremail: any;
  eligstatus: any;
  eligdate: any;
  address_landmark: any;
  address_pin_code: any;
  imgType: any;
  totalReject: number=0;
  rejectstatus: any;
  docname: any;
  assigny: any;
  assFname: any;
  assLname: any;
  ass_cityname: any;
  district_name: any;
  uname: any;
  stateArray: any = [];
  optionState: any = []; 
  cityArray: any = [];
  optionCity: any = []; 
  assstatus: any;
  
  constructor(
    private cryptojsService:CryptojsService,
    private route:ActivatedRoute,   
    private router:Router,
    private trackProgressService:TrackprogressService,
    private formBuilder: FormBuilder,
    private FeedbackService:FeedbackService,
    public afAuth: AngularFireAuth,  
    private firebasedataService:FirebasedataService,
    private authService:AuthenticationService,
    public cookieService:CookieService,
    private datePipe: DatePipe,     
       public adminServiceService: AdminService,     
       private ctwadoc:CtwaService,
       private loginServiceService: LoginServiceService,
       
    
  ) { this.jstoday = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US'); }
  public myDatePickerOptions: IMyOptions = {
    // Your options
  };
  
 
  ngOnInit() {
    this.user=this.loginServiceService.userData();
    // console.log(this.user);
     if(this.user.length !=0){
       this.loginuserid= (JSON.parse(this.user)['fb_uid']);
       this.rolecode= (JSON.parse(this.user)['rolecode']);
       this.fname= (JSON.parse(this.user)['first_name']);
       this.lname= (JSON.parse(this.user)['last_name']); 
      }

      this.adminServiceService
      .getBanchStateMater()
      .subscribe( (st:any) => {
       // console.log(st);
              if (st.status === true) {
                
                for (let i = 0; i < st.state.length; i++) {
                  const menuarray = st.state[i];
                 
               
                  this.stateArray.push(
                    {
                      value: menuarray.data[0].district_id,
                      label: menuarray.data[0].district_name,                          
                     
                    }
                    );
                    //console.log(this.messages);
                
                
                   
                    
                }
                this.optionState =this.stateArray;
               // console.log(this.optionState);
              }else{
                console.log('District Api faile');
              }
              
            });
    this.popupaddBtn =0;
    for (let i = 1; i <= 15; i++) {
      this.elements.push({ id: i, first: 'User ' + i, last: 'Name ' + i, handle: 'Handle ' + i });
    }

    
    this.ctwadoc
    .getDocCategory()
    .subscribe( (result:any) => {   
   //  console.log(result);   return false;
            if (result.status == true  && result.response== 200) {                
              for (let i = 0; i < result.docCat.length; i++) {
                const menuarray = result.docCat[i];
              //  console.log(menuarray);
                this.docCategory.push(
                  {
                    value: menuarray.data[0].doc_catg_id,
                    label: menuarray.data[0].document_category,                         
                   
                  }
                  );
                  
              }
              this.docCategoryArray=this.docCategory;
              //this.productArray =this.getProduct;                
            }else{
              console.log('Select Doc Category  Api faile');

             
            }
            
          });




    this.FeedbackService.GetMeraemiStage().subscribe( (result:any) => {   

           if (result.status == true  && result.response== 200) {                
             for (let i = 0; i < result.meraemistage.length; i++) {
               const menuarray = result.meraemistage[i];
              //console.log(menuarray);
               
              // console.log(this.userdocReceived);
               if(this.userdocReceived ==1){
    
                 this.getmeraemistages.push(
                   {
                     value: menuarray.data[0].stage_id,
                     label: menuarray.data[0].meraemi_stage,                         
                     lender_short_name : menuarray.data[0].meraemi_stage, 
                   }
                   );
               }else{
                 if(menuarray.data[0].stage_id !="18" && menuarray.data[0].stage_id !="19" && menuarray.data[0].stage_id !="17"){
                   this.getmeraemistages.push(
                     {
                       value: menuarray.data[0].stage_id,
                       label: menuarray.data[0].meraemi_stage,                         
                       lender_short_name : menuarray.data[0].meraemi_stage, 
                     }
                     );
                 }
               }
              // console.log(menuarray.data[0].stage_id);
    
                 
                 
          
            //   console.log(menuarray.data[0].stage_id);
               if(menuarray.data[0].stage_id =='21' ||   menuarray.data[0].stage_id =='14'  || menuarray.data[0].stage_id =='16'|| menuarray.data[0].stage_id =='20' || menuarray.data[0].stage_id =='26'  ){
                 this.GetDocReceived.push(
                   {
                     value: menuarray.data[0].stage_id,
                     label: menuarray.data[0].meraemi_stage,                         
                     lender_short_name : menuarray.data[0].meraemi_stage, 
                   }
                   );
               }
               if(menuarray.data[0].stage_id =='25' ||  menuarray.data[0].stage_id =='26'){
                 this.documentcomplete.push(
                   {
                     value: menuarray.data[0].stage_id,
                     label: menuarray.data[0].meraemi_stage,                         
                     lender_short_name : menuarray.data[0].meraemi_stage, 
                   }
                   );
               }   
             }
                  this.GetDocReceivedlist =this.GetDocReceived; 
                  this.optionsmeraemistage =this.getmeraemistages;   
                  this.crmdocveryfi=this.documentcomplete;
        
    
              //    console.log(this.optionsmeraemistage); //return false;
    
    
    
           }else{
             console.log('Product Api faile');
    
            
           }
           
         });
                  
    
     this.FeedbackService
      .getproduct()
      .subscribe( (result:any) => {   
     //   console.log(result);  
              if (result.status == true  && result.response== 200) {                
                for (let i = 0; i < result.productname.length; i++) {
                  const menuarray = result.productname[i];
                //  console.log(menuarray);
                  this.getProduct.push(
                    {
                      value: menuarray.data[0].product_id,
                      label: menuarray.data[0].product_name,                         
                     
                    }
                    );
                    
                }
                this.productArray =this.getProduct;                
              }else{
                console.log('Product Api faile');

               
              }
              
            });
           
               

    this.isValue = 1;
    this.idfc=0;
  //  get login Details
    this.sub = this.authState$.subscribe( user => {
      this.user = user;
      if(this.user){
           //console.log(this.user.uid)
          //  console.log(this.afAuth.auth.currentUser)
          this.firebasedataService.checkUserExist(this.user.uid)
          this.firebasedataService.getUserData().subscribe( data => {
            this.user = data;
            this.rolecode= this.user.rolecode;
            this.loginAdmin= this.user.first_name;
           //console.log(this.loginAdmin)
          }
        )
      }
     
    
    });
    
    // validation input form
    this.registerForm = this.formBuilder.group({
        idFilter: this.formBuilder.group({
        message: ['', Validators.required],        
         toDate: ['', Validators.required],
        meraemistage: ['', Validators.required],
        productname: ['', Validators.required],
        lendername: ['', Validators.required],
        
       
      }),
      adidedit: this.formBuilder.group({
        adid: ['', Validators.required]
      }),
      crmsubstage: this.formBuilder.group({
        meraemi_stage: ['', Validators.required]
      }), docrej: this.formBuilder.group({
        ctwaUser: ['', Validators.required]
      }),
      
      FileReject: this.formBuilder.group({
        file_msg: ['', Validators.required]
      }),
      
      docfeedback: this.formBuilder.group({
        docrecivstage: ['', Validators.required],
        docmessage: ['', Validators.required]
      }),
         
      quatefeedback: this.formBuilder.group({
        quatemessage: ['', Validators.required],
     
      }),
      ctwaDocRec: this.formBuilder.group({
        doccatid: ['', Validators.required],
        docname: ['', Validators.required],
     
      }),
      userassignedby: this.formBuilder.group({
        state: ['', Validators.required],
        city: ['', Validators.required]
      }),

      
    });
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      const  out =  params.data.replace(/\s/g, "+");
        this.data = out;
        if(params.userstage !=undefined){
          this.feedback_Stage=params.stage;
          this.userstage=params.userstage;
      }else{
        this.userstage="Active";
        this.feedback_Stage=params.stage;
      }
        
       
//console.log(this.userstage);
//console.log(this.feedback_Stage);
        if(this.data != undefined){
          this.decryptData = this.cryptojsService.decryptData(this.data);
         
         // console.log(this.decryptData);
          if( this.decryptData != false){
            this.showLoader = true;           
            this.custsD["results"] = [{"user_id":parseInt(this.decryptData)}];
      //    console.log(this.custsD)
            this.cust = this.custsD["results"];
            //console.log(this.custsD["results"])
           
            this.userdetils =this.custsD;
          }else{
            this.showLoader = true;    
            this.router.navigate(['/leads']);
          }
        }
        
      },(err)=>{
        console.log(err);
      });
      function comparer(otherArray){
        return function(current){
          return otherArray.filter(function(other){
            return other.value == current.value && other.display == current.display
          }).length == 0;
        }
      }
   
   
  //  docunent




  
 
  
    
    
   
         
         
             

                     //lender sub stage
              
       
      let userdetilspage = { results: [{ "user_id":parseInt(this.decryptData) }]
      
       
      };
      // doc listing
      const uid = parseInt(this.decryptData);
      this.ctwadoc.getCTWADocListing(uid).subscribe(             
        (doc:any) => {
          if(doc.status === true   && doc.response == 200 ){
            this.doclength=doc.ctwa.length;
            for (let i = 0; i < doc.ctwa.length; i++) {
              const element = doc.ctwa[i];
              this.imgType=element.data[0].ctwa_doc_url;
              let urlParts = this.imgType.split('%2F');
             let img= urlParts[3];
             let file_type = img.split('&');
              
              console.log(file_type[0]);
              this.ctwaDocs.push(
                {
                 
                  created_on: element.data[0].created_on, 
                  ctwa_doc_name: element.data[0].ctwa_doc_name, 
                  ctwa_doc_url:  element.data[0].ctwa_doc_url,
                //  doc_catg_id:  element.data[0].doc_catg_id, 
                //  doc_id:  element.data[0].doc_id, 
                  message:  element.data[0].message, 
                  message_id:  element.data[0].message_id, 
                  message_title:  element.data[0].message_title,
                  message_type:  file_type[0],
                  modified_by:  element.data[0].modified_by,
                  status:  element.data[0].status,
                  user_id:  element.data[0].user_id,             


                 
                 
                }
                );
              }
              console.log( this.ctwaDocs);
          }else{
            this.doclength=0;
          }
         // console.log(userdetilspage);
        });
  
          this.ctwadoc.GetUserDetails(this.decryptData).subscribe( 
            
            (udetails:any) => {
             // console.log(udetails);
              if (udetails.status === true) {
                //assigny
                this.assigny=udetails.data['loans'][0]['assigny'];
                this.assFname=this.assigny.assFname;
                this.assLname=this.assigny.assLname;
                this.ass_cityname  =this.assigny.ass_cityname;
                this.district_name =this.assigny.district_name;
                this.assstatus =this.assigny.assstatus;
                //aboutMe
                this.aboutMe=udetails.data['loans'][0]['aboutMe'];
                        this.education_qualification=this.aboutMe.education_qualification;
                        this.since_residing_here=this.aboutMe.since_residing_here;
                        this.father_first_name=this.aboutMe.father_first_name;
                        this.father_last_name=this.aboutMe.father_last_name;
                        this.marital_status=this.aboutMe.marital_status;
                        this.gender=this.aboutMe.gender;
                        this.residence_status=this.aboutMe.residence_status;
                        this.pen_no=this.aboutMe.pen_no;
                        this.mother_name=this.aboutMe.mother_name;
               // console.log( this.aboutMe.education_qualification); return false;
                //address
                this.address=udetails.data['loans'][0]['address'];
                this.address_1=this.address.address_1;
                this.address_2=this.address.address_2;
                this.address_3=this.address.address_3;
                this.address_landmark=this.address.address_landmark;
                this.address_pin_code=this.address.address_pin_code;
                //bussDetails
                this.bussDetails=udetails.data['loans'][0]['bussDetails'];
                            this.employment_type=this.bussDetails.employment_type;
                            this.industry_name=this.bussDetails.industry_name;
                            this.employer_catg=this.bussDetails.employer_catg;
                            this.bus_year_name=this.bussDetails.bus_year_name;
                            this.bus_nature_type=this.bussDetails.bus_nature_type;
                            this.prev_fin_year=this.bussDetails.prev_fin_year;
                            this.prev_fin_year=this.bussDetails.prev_fin_year;
                            this.short_name=this.bussDetails.short_name;
                            this.estb_status=this.bussDetails.estb_status;
                            this.dscription=this.bussDetails.dscription;
                //eligStage
                this.eligStage=udetails.data['loans'][0]['eligStage'];
                            
                            this.eligstatus=this.eligStage['elig_status'];
                            this.eligdate=this.eligStage['elig_date'];
                //loanapp
                this.loanapp=udetails.data['loans'][0]['loanapp'];
                                this.loan_code=this.loanapp.loan_code;
                                this.city_name=this.loanapp.city_name;
                                this.loan_amt=this.loanapp.loan_amt;
                                this.tenure=this.loanapp.tenure;
                //loginuser
                this.loginuser=udetails.data['loans'][0]['loginuser'];
                          this.email_id=this.loginuser.email_id;
                          this.ad_id=this.loginuser.ad_id;
                          
                          this.dob=this.loginuser.dob;
                          this.mobile_no=this.loginuser.mobile_no;
                          this.last_name=this.loginuser.last_name;
                          this.first_name=this.loginuser.first_name;
                          this.provider_id=this.loginuser.provider_id;
                          this.lead_id=this.loginuser.lead_id;
                         // this.logindate=this.loginuser.lead_id;
                //tempdata
                this.loginDetails=udetails.data['loans'][0]['loginDetails'];
                    this.reg_date=this.loginDetails.reg_date;
                    this.provider=this.loginDetails.provider;
                    this.userfname=this.loginDetails.userfname;
                    this.userlanme=this.loginDetails.userlanme;
                    this.usermoile=this.loginDetails.usermoile;
                    this.useremail=this.loginDetails.useremail;
                    this.userid=this.loginDetails.user_id;
    
                    
    
    
    
    
    
    
    
    
    
               //this.userdetailspagedata = details.msg[0].data[0];
             //  console.log( this.userdetailspagedata);
              
    
               
               //console.log( ); return false;
              }else{
                console.log("ctwa user listing api not found"); return false;
              }
             
             

              //console.log( this.lenderResponse);
            });
            let details =  { results: [{ "user_id":this.decryptData}] } ;
            details['results']; 
             
              this.FeedbackService.postfeedback_getcrm(details)
              .subscribe((result: any) => {  
             //  console.log(result); return false;
                if (result.status == true) {
                 this.popupaddBtn=1;
                  for (let i = 0; i < result.feedvalue.length; i++) {
                    const element = result.feedvalue[i];
           
                   if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                    this.docPending.push(
                      { 
                        docPending:"1"
                
                      });
                
                      
                
                  }
                   if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                    this.docPending.push(
                      { 
                        docPending:"1"
                
                      });
                
                
                
                  }
                  
                  
                  
                  if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                    this.docPending.push(
                      { 
                        Received:"1"
                
                      });
                  
                  }
                 
                 
                  if(element.data[0].lender_name == "LoanTap"){
                    this.lenderloantabactive=1;
                  }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                    this.lenderHDBactive=1;
                  }
                 
                    if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                      this.OldFeedbackMessage.push(
                        {
                         
                          admin_name: element.data[0].admin_name, 
                          feedback_id: element.data[0].feedback_id, 
                          feedback_message:  element.data[0].feedback_message, 
                          followupdate:  element.data[0].follow_update, 
                          interactiondate:  element.data[0].interactiondate, 
                          stage:  element.data[0].stage, 
                          user_id:  element.data[0].user_id, 
                          status:  element.data[0].status,

                         
                         
                        }
                        );
                    }else{
                      if(element.data[0].stage === "21" ){
                        this.docReceiveduser.push({
                          docReceived : '1'
                        }
                        );
                       
                      }else{
                        this.docReceiveduser.push({
                          docReceived : '0'
                        }
                        );
                      }
                   
                      this.elements_feedback.push(
                        {
                          admin_name: element.data[0].admin_name, 
                          feedback_id: element.data[0].feedback_id, 
                          feedback_message:  element.data[0].feedback_message, 
                          followupdate:  element.data[0].follow_update, 
                          interactiondate:  element.data[0].interactiondate, 
                          stage:  element.data[0].stage, 
                          user_id:  element.data[0].user_id, 
                          status:  element.data[0].status,
                          meraemi_stage:  element.data[0].meraemi_stage,
                          lender_name:  element.data[0].lender_name,
                          product_name:  element.data[0].product_name,
                          
                        }
                        );
                    }


                    
                   
                     
                    
  
                      
                  }
                 
                  for (let i = 0; i <  this.docPending.length; i++) {
                    const docrec = this.docPending[i];
                 
                    if(docrec.docReceived !='0'){
                      this.docPending=1;
                     
                    }

                    
                  }






                  for (let i = 0; i <  this.docReceiveduser.length; i++) {
                    const docrec = this.docReceiveduser[i];
                 
                    if(docrec.docReceived !='0'){
                      this.userdocReceived=1;
                     
                    }

                    
                  }
                 // console.log(this.userdocReceived);
                  
                  //console.log("docPending"+this.docPending); return false;
                 
                 // this.docPendingStatus=this.docPending[0]['docPending'];
                  //this.ReceivedStatus=this.docPending[0]['Received'];
                  this.ReceivedStatus=this.userdocReceived;
                if(this.elements_feedback.length !=0){
                  this.fedbacklength =this.elements_feedback.length;
                }else{
                  this.popupaddBtn =0;
                 
                }
                  
                 /// console.log(this.elements_feedback.length); 
              //  console.log(this.OldFeedbackMessage);
              //  console.log(this.elements_feedback);
               // this.popupbtnactive=this.meraemiStage[0].stage;
                 //console.log(this.elements_feedback.length);
                  
                  this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                  this.message = result.message;
                   
                   
                }else{
                  this.popupaddBtn =0;
                  //("hello");
                }
              }); 
            
          //  console.log(this.lenderid);
          
           
               

         
            
    }
     
  stateid(selectedValue: any): void {
    this.cityArray=[];
    this.optionCity=[];
    console.log(selectedValue);
    this.adminServiceService.getBanchCityMater(selectedValue)
      .subscribe((res:any) => {
        this.optionCity.length= 0;
        this.cityArray.length= 0;
       // console.log(res); return false;
        if (res.status === true) {
          //this.optionCity=[];
        //  this.cityArray=[];
          for (let i = 0; i < res.city.length; i++) {
            const menuarray = res.city[i];
           
         
            this.cityArray.push(
              {
                value: menuarray.data[0].city_id,
                label: menuarray.data[0].city_name,                          
               
              }
              );
              //console.log(this.messages);
          
          
             
              
          }
          this.optionCity =this.cityArray;
          console.log(this.optionCity);
        }else{
          console.log('City Api faile');
        }
      });
    
   
    // Do something with the selected value
  }
    assignedToSatff(userid,fname,lname){
      this.uid=userid;
      this.uname=fname+' '+lname;
     // this.optionCity.length= 0;
     // this.cityArray.length= 0;
    
  
      //console.log(user);
      this.assigned.show();
    }
    assignbyuserbtn(){
      this.assignSubmitted = true;
      this.showLoader = false;
  
      // stop here if form is invalid
      if (this.userassignedby.invalid) {
        setTimeout(() => {
          this.showLoader = true;;
        }, 2000);
  
        return;
      }else{
        this.showLoader = false;
        const userid     = this.uid;
        const loginuserid = this.loginuserid;
        const cityid= this.userassignedby.value.city;
        const state= this.userassignedby.value.state;
        let assUser={ results: [{"adminid":loginuserid,"userid":userid,"cityid":cityid}]};
        console.log(assUser);
        this.adminServiceService.assigByUser(assUser)
        .subscribe((asUserData: any) => {

          this.assigned.hide();
          this.ctwadoc.GetUserDetails(this.decryptData).subscribe( 
            
            (udetails:any) => {
             // console.log(udetails);
              if (udetails.status === true) {
                //assigny
                this.assigny=udetails.data['loans'][0]['assigny'];
                this.assFname=this.assigny.assFname;
                this.assLname=this.assigny.assLname;
                this.ass_cityname  =this.assigny.ass_cityname;
                this.district_name =this.assigny.district_name;
                this.assstatus =this.assigny.assstatus;
                //aboutMe
                this.aboutMe=udetails.data['loans'][0]['aboutMe'];
                        this.education_qualification=this.aboutMe.education_qualification;
                        this.since_residing_here=this.aboutMe.since_residing_here;
                        this.father_first_name=this.aboutMe.father_first_name;
                        this.father_last_name=this.aboutMe.father_last_name;
                        this.marital_status=this.aboutMe.marital_status;
                        this.gender=this.aboutMe.gender;
                        this.residence_status=this.aboutMe.residence_status;
                        this.pen_no=this.aboutMe.pen_no;
                        this.mother_name=this.aboutMe.mother_name;
               // console.log( this.aboutMe.education_qualification); return false;
                //address
                this.address=udetails.data['loans'][0]['address'];
                this.address_1=this.address.address_1;
                this.address_2=this.address.address_2;
                this.address_3=this.address.address_3;
                this.address_landmark=this.address.address_landmark;
                this.address_pin_code=this.address.address_pin_code;
                //bussDetails
                this.bussDetails=udetails.data['loans'][0]['bussDetails'];
                            this.employment_type=this.bussDetails.employment_type;
                            this.industry_name=this.bussDetails.industry_name;
                            this.employer_catg=this.bussDetails.employer_catg;
                            this.bus_year_name=this.bussDetails.bus_year_name;
                            this.bus_nature_type=this.bussDetails.bus_nature_type;
                            this.prev_fin_year=this.bussDetails.prev_fin_year;
                            this.prev_fin_year=this.bussDetails.prev_fin_year;
                            this.short_name=this.bussDetails.short_name;
                            this.estb_status=this.bussDetails.estb_status;
                            this.dscription=this.bussDetails.dscription;
                //eligStage
                            this.eligStage=udetails.data['loans'][0]['eligStage'];
                            
                            this.eligstatus=this.eligStage['elig_status'];
                            this.eligdate=this.eligStage['elig_date'];
                //loanapp
                this.loanapp=udetails.data['loans'][0]['loanapp'];
                                this.loan_code=this.loanapp.loan_code;
                                this.city_name=this.loanapp.city_name;
                                this.loan_amt=this.loanapp.loan_amt;
                                this.tenure=this.loanapp.tenure;
                //loginuser
                this.loginuser=udetails.data['loans'][0]['loginuser'];
                          this.email_id=this.loginuser.email_id;
                          this.ad_id=this.loginuser.ad_id;
                          
                          this.dob=this.loginuser.dob;
                          this.mobile_no=this.loginuser.mobile_no;
                          this.last_name=this.loginuser.last_name;
                          this.first_name=this.loginuser.first_name;
                          this.provider_id=this.loginuser.provider_id;
                          this.lead_id=this.loginuser.lead_id;
                         // this.logindate=this.loginuser.lead_id;
                //tempdata
                this.loginDetails=udetails.data['loans'][0]['loginDetails'];
                    this.reg_date=this.loginDetails.reg_date;
                    this.provider=this.loginDetails.provider;
                    this.userfname=this.loginDetails.userfname;
                    this.userlanme=this.loginDetails.userlanme;
                    this.usermoile=this.loginDetails.usermoile;
                    this.useremail=this.loginDetails.useremail;
                    this.userid=this.loginDetails.user_id;   
    
    
               //this.userdetailspagedata = details.msg[0].data[0];
             //  console.log( this.userdetailspagedata);
              
    
               
               //console.log( ); return false;
              }else{
                console.log("ctwa user listing api not found"); return false;
              }
             
             

              //console.log( this.lenderResponse);
            });

         // console.log(asUserData);
        });
      }
    }
   getValue(event) {
   //console.log(event);
      this.catid =event.value;
      this.crmstage=event.label;
      this.ctwadoc.getDocId(this.catid)
      .subscribe((result:any) => {
        if (result.status == true  && result.response== 200) { 
          for (let i = 0; i < result.docid.length; i++) {
            const menuarray = result.docid[i];
            this.doclist.push(
              {
                // value: menuarray.data[0].doc_id,
                label: menuarray.data[0].dscription,                         
                value: menuarray.data[0].dscription,  
              }
              );
           
          }
          this.doclistArray=this.doclist;
          console.log( this.doclistArray);   return false;
          }else{
            console.log('Select Doc   Api faile');
          }
       });
   
    // console.log(this.feedbackdatepikerdisable);
   }
    adidSubmitted: boolean = false;
    assignSubmitted: boolean = false;
  // Employer  Login Method
  get fe() { return (<FormGroup>this.registerForm.get('idFilter')).controls; }
  get fc() { return (<FormGroup>this.registerForm.get('adidedit')).controls; }
  get cr() { return (<FormGroup>this.registerForm.get('crmsubstage')).controls; }
  get dj() { return (<FormGroup>this.registerForm.get('docrej')).controls; }
  
  get fr() { return (<FormGroup>this.registerForm.get('FileReject')).controls; }
  get df() { return (<FormGroup>this.registerForm.get('docfeedback')).controls; }
  get qf() { return (<FormGroup>this.registerForm.get('quatefeedback')).controls; }
  get ctd() { return (<FormGroup>this.registerForm.get('ctwaDocRec')).controls; }
  get rs() { return (<FormGroup>this.registerForm.get('userassignedby')).controls; }
  get userassignedby() {
    return this.registerForm.get('userassignedby');
  }
  get ctwaDocRec() {
    return this.registerForm.get('ctwaDocRec');
  }
  get quatefeedback() {
    return this.registerForm.get('quatefeedback');
  }
  get docfeedback() {
    return this.registerForm.get('docfeedback');
  }
  get FileReject() {
    return this.registerForm.get('FileReject');
  }
  get docrej() {
    return this.registerForm.get('docrej');
  }
  get crmsubstage() {
    return this.registerForm.get('crmsubstage');
  }
  get adidedit() {
    return this.registerForm.get('adidedit');
  }
  get idFilter() {
    return this.registerForm.get('idFilter');
  }
  onChange1(email:string, isChecked: boolean) {
   // console.log(email);
    if(isChecked) {
      this.checkFormArray.push(email);
    } else {
      let index = this.checkFormArray.indexOf(email);
      this.checkFormArray.splice(index,1);
    }
  }

  Addid(){
    this.user_id=this.decryptData;
    let adname=this.adidedit.value.adid;
    let detail = { results: [{ "user_id":this.user_id, "adid":adname}] };
    detail['results']; 
    this.FeedbackService.updateUserAdId(detail).subscribe((result: any) => {
      if(result.status===true  && result.response== 200){
        this.display = "none";
        alert("ad id update!");
      }
    /// console.log(result);
    });
    
  }
feedbackPopup(data1){
this.popupaddBtn=1;
this.feedbackloanid=data1;
 
 // console.log(data2);
  this.FeedbackPopup=1;
 
}
filterUserId(){
 // console.log(this.idFilter.value.meraemistage);
 // console.log(this.CrmStage); return false; 
//console.log(this.idFilter.value.productname);  
                    if(this.idFilter.value.meraemistage ==="15"){
                      
                      this.meraemiStages="SE CRM Cancel";
                    }else if(this.idFilter.value.meraemistage ==="14"){
                      
                      this.meraemiStages="SE Cust Cancel";
                    }else if(this.idFilter.value.meraemistage ==="18"){
                      
                      this.meraemiStages="SE Transfer";
                    }else if(this.idFilter.value.meraemistage ==="16"){
                      
                      this.meraemiStages="SE CRM Decline";
                    }else{
                    this.meraemiStages=this.CrmStage;
                    }

                  if(this.idFilter.value.meraemistage ==="18"){
                    this.status =parseInt(this.idFilter.value.meraemistage);
                    this.feedbackLendername =(Number(this.idFilter.value.lendername));
                    
                   // this.feedbackProductname =this.idFilter.value.productname;
                  }else if(this.idFilter.value.meraemistage !="18"){
                    this.status =parseInt(this.idFilter.value.meraemistage);
                    this.feedbackLendername =0;

                  }else{
                    alert("Pl Select Meraemi Stage !");
                    this.idFilter.reset();
                  }

                    //console.log(this.feedbackLendername);
                    //console.log(this.status);
                    if(this.loan_id === undefined){
                      this.loanid=this.loan_id;

                    }else{
                      this.loanid=this.loan_id;
                    }
                    //message
                    if(this.idFilter.value.message !='' || this.idFilter.value.message !=undefined || this.idFilter.value.message !=null){
                      this.message =this.idFilter.value.message;
                    }else{
                        alert("Pl Input Feedback Message !");
                        this.idFilter.reset();
                    }
                  
                    this.feedbackProductname ="4";
                    


                    var currentdate = new Date();
                    // adjust 0 before single digit date
                    let dates = ("0" + currentdate.getDate()).slice(-2);
                    // current month
                    let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
                    // current year
                    let year = currentdate.getFullYear();
                    this.currentdate =(year + "-" + month + "-" + dates);

                    //console.log(this.idFilter.value.toDate);
                    if(this.idFilter.value.toDate !="" || this.idFilter.value.toDate !=null){
                     let followupdate =this.idFilter.value.toDate;

                        if(this.currentdate <= followupdate){
                          this.followupdate =this.idFilter.value.toDate;
                          //console.log("1");
                        }else{
                          this.followupdate ="0000-00-00 00:00:00";
                  
                        }
                     
                    }else{
                     this.followupdate ="0000-00-00 00:00:00";

                    }
                    
                
                    // console.log(this.feedbackLendername);
                    // console.log(this.status);
                    // console.log(this.loanid);
                    // console.log(this.message);
                    // console.log(this.followupdate);
                    console.log(this.CrmStage);
                         
                  
  
                  this.user_id=parseInt(this.decryptData);
                  let details = {
                    
                      results: [{
                        "message":this.message,            
                        "interactiondatetime":this.currentdate,          
                        "status": this.status,
                        "user_id":this.user_id,
                        "admin_name":this.fname,                       
                        "loan_id":this.lead_id,
                        "followupdate":this.followupdate,
                        "feedbackProductname" :this.feedbackProductname,
                        "feedbackLendername":this.feedbackLendername,
                        "CrmStage":this.crmstage,
                         "campstatus":"1"
                      }]
                  
                    
                  };
              console.log(details); // return false;     
                  details['results']; 
                      this.FeedbackService.postfeedback(details)
                      .subscribe((result: any) => { 
                            //  console.log(result);
                                      if (result.status === true && result.response===200 ) {
                                        this.idFilter.reset();
                                        this.transfertabopen=0;
                                                  this.FeedbackPopup =0;
                                                  this.elements_feedback.length=0;
                                                 
                                                 
                                                let details =  { results: [{ "user_id":this.decryptData}] } ;
                                                details['results']; 
                                                this.FeedbackService.postfeedback_getcrm(details)
                                                .subscribe((result: any) => {  
                                                  //console.log(result);
                                                  if (result.status == true) {
                                                   console.log(result);
                                                    for (let i = 0; i < result.feedvalue.length; i++) {
                                                      const element = result.feedvalue[i];
                                                     
                                                      if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                                                        this.docPending.push(
                                                          { 
                                                            docPending:"1"
                                                      
                                                          });
                                                      
                                                      
                                                      
                                                      }
                                                       if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                                                        this.docPending.push(
                                                          { 
                                                            docPending:"1"
                                                      
                                                          });
                                                      
                                                      
                                                      
                                                      }
                                                      
                                                      if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                                                        this.docPending.push(
                                                          { 
                                                            Received:"1"
                                                      
                                                          });
                                                      
                                                      }
                                                     
                                                      
                                                      if(element.data[0].lender_name == "LoanTap"){
                                                        this.lenderloantabactive=1;
                                                      }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                                                        this.lenderHDBactive=1;
                                                      }else{
                                                        console.log("call other lender");
                                                      }
                                                      if(element.data[0].status == "1"){
                                                        this.crmDate=element.data[0].interactiondate;
                                                        this.crmStage= element.data[0].meraemi_stage;
                                                      }
                                                      // console.log(element.data[0].status);
//console.log(this.crmStage);
                                                      if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                                                       
                                                    
                                                       
                                                         
                                                        this.OldFeedbackMessage.push(
                                                          {
                                                           
                                                            admin_name: element.data[0].admin_name, 
                                                            feedback_id: element.data[0].feedback_id, 
                                                            feedback_message:  element.data[0].feedback_message, 
                                                            followupdate:  element.data[0].follow_update, 
                                                            interactiondate:  element.data[0].interactiondate, 
                                                            stage:  element.data[0].stage, 
                                                            user_id:  element.data[0].user_id, 
                                                            status:  element.data[0].status,
                                  
                                                           
                                                           
                                                          }
                                                          );
                                                      }else{
                                                        this.elements_feedback.push(
                                                          {
                                                            admin_name: element.data[0].admin_name, 
                                                            feedback_id: element.data[0].feedback_id, 
                                                            feedback_message:  element.data[0].feedback_message, 
                                                            followupdate:  element.data[0].follow_update, 
                                                            interactiondate:  element.data[0].interactiondate, 
                                                            stage:  element.data[0].stage, 
                                                            user_id:  element.data[0].user_id, 
                                                            status:  element.data[0].status,
                                                            meraemi_stage:  element.data[0].meraemi_stage,
                                                            lender_name:  element.data[0].lender_name,
                                                            product_name:  element.data[0].product_name,
                                                          }
                                                          );
                                                      }
                                  
                                  
                                                      
                                                     
                                                       
                                                      
                                    
                                                        
                                                    }
                                                   
                                                   
                                                   
                                                    //this.docPendingStatus=this.docPending[0]['docPending'];
                                                   // this.ReceivedStatus=this.docPending[0]['Received'];
                                                    
                                                      
                                                    console.log(this.OldFeedbackMessage);
                                                  console.log(this.elements_feedback);
                                                 
                                                   //console.log(this.popupbtnactive);
                                                    this.fedbacklength =this.elements_feedback.length;
                                                    this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                                                    this.message = result.message;
                                                    this.popupaddBtn=1;
                                                     
                                                  }else{
                                                    this.popupaddBtn =0;
                                                    //("hello");
                                                  }
                                                }); 
                                                
                                      }else {
                                        let details = {
                                          
                                            results: [{
                                              "message":this.message,            
                                              "interactiondatetime":this.currentdate,          
                                              "status": this.status,
                                              "user_id":this.user_id,
                                              "admin_name":this.user.first_name,                      
                                              "loan_id":this.loanid,
                                              "followupdate":this.followupdate,
                                              "feedbackProductname" :this.feedbackProductname,
                                              "feedbackLendername":this.feedbackLendername,
                                              "emilSendStage":"feedbak"
                                            }]
                                        
                                        
                                        };
                                        
                                     //  console.log(details);                 
                                        details['results'];
                                        this.FeedbackService.SendEmailAdminUserTransfer(details)
                                        .subscribe((result: any) => {
                                        console.log(result);
                                        });
                                      }
                                      //meraemi stage
                                      
                                    
                                     
                                     
                });
   
}
 
resetfeedback() {
     this.FeedbackPopup =0;
      this.idFilter.reset();
  }
  onCloseHandledone() {
    this.display = "none";
  }
    backmenu(){
      this.showLoader = false;
      setTimeout(()=>{    
        //this.showLoader = true;
        this.router.navigate(['/MeraEMI-All-Users-Leads']);
      }, 2000);
     
      
      }
     
     
  calculateEMI(interest:number,tenure:number,principal:number){
    var emi = 0;
    var a = (1 + interest / 1200);
    var b = Math.pow(a, tenure);
    var c = Math.pow(a, tenure) - 1;
    emi = Math.round(principal * (interest / 1200) * b / c);
    //console.log(emi)
    return emi;
  }
  calculateFOIR(existemi:number,proposedemi:number,income:number){
    var foir= 0;
   // console.log(existemi)
   // console.log(proposedemi)
  //  console.log(income)
    foir = ((existemi + proposedemi) / income * 100);
   // console.log(foir)
    return foir;
  }
  trackProgress(data){
    // console.log(data)
    // return false;
    data.sfdc_id = "0328568170";
    this.trackData["results"]= {
      "record_id":"0328568170",
      "email_send": "NO",
      "sms_send": "NO",
      //"user_id":107
    };

    this.isTrackProgress = !this.isTrackProgress;

    setTimeout(()=>{  
      
      this.isTrackProgress = true;
     // console.log('set disable '+this.isTrackProgress)
      this.trackProgressService.updateTrackProgress(this.trackData)
      .subscribe(result => {
       // console.log(result)
          
        if(result.status == 1){
          //console.log(result.trx_status)
          if(result.trx_status != 'undefined'){
            this.trxStatus = result.trx_status;
            this.trxSubStatus =result.trx_substatus;
            //console.log(this.setBtnDisable);
            
              if(result.trx_status == "DDE" || result.trx_status == "QDE" || result.trx_status == "Data Verification"){
               // console.log(result.trx_status)
                this.isTrackProgress = this.isTrackProgress;
                //console.log(this.isTrackProgress)
              }else{
                this.isTrackProgress = !this.isTrackProgress;
               // console.log(this.isTrackProgress)
              }
          }  
        }
        
      });
    }, 20000);
  

  }
  get authState$(): Observable<User|null> {
    return this.afAuth.user;
  }
  //idfc bank json downlods file
  downloadRequestFile(filename){  

    saveAs("./assets/UserRequestResponse/idfc/Request/"+filename+".json",filename);
   
  
  }
  downloadResponseFile(filename){  

    saveAs("./assets/UserRequestResponse/idfc/Response/"+filename+".json",filename);
  
  }
  downloadTrackProgressFile(filename){   
 
   saveAs("./assets/UserRequestResponse/idfc/trackprogress/"+filename+".json",filename);
  
  } 

  //loan tab json downlods file
  downloadRequestFileLoantab(filename){  
   
    saveAs("./assets/UserRequestResponse/LoanTap/Request/"+filename+".json",filename);
 
  
  }
  downloadResponseFileLoantab(filename){  
   
    saveAs("./assets/UserRequestResponse/LoanTap/Response/"+filename+".json",filename);
      }
  downloadTrackProgressFileLoantab(filename){   
  
   saveAs("./assets/UserRequestResponse/LoanTap/Trackprogress/"+filename+".json",filename);
  
  }
   //Fullerton json downlods file
   downloadRequestFileFullerton(filename){  
   
    saveAs("./assets/UserRequestResponse/fullerton/Request/"+filename+".json",filename);
 
  
  }
  downloadResponseFileFullerton(filename){  
   
    saveAs("./assets/UserRequestResponse/fullerton/Response/"+filename+".json",filename);
  }
  downloadTrackProgressFileFullerton(filename){   
  console.log(filename);
   saveAs("./assets/UserRequestResponse/fullerton/CallBacks/"+filename+".json",filename);
  
  }
  //HDB json downlods file
  downloadRequestFileHDB(filename){  
   
    saveAs("./assets/UserRequestResponse/HDBF/Request/"+filename+".json",filename);
 
    
  }
  
  downloadResponseFileHDB(filename){  
   
    saveAs("./assets/UserRequestResponse/HDBF/Response/"+filename+".json",filename);
  }
  downloadTrackProgressFileHDB(filename){   
  console.log(filename);
   saveAs("./assets/UserRequestResponse/HDBF/CallBacks/"+filename+".json",filename);
  
  }
  
  buttonMain(){
   
    this.tabdiv = 1;
  }
  openModalone(data) {
    this.adid=data;
    //console.log(data);
   
    this.display = "block";
 
    this.valuemain=1;
    this.userid =1;
    this.isValue = 1;
  }
  toggle1() { 
    alert('1');
    this.isValue = 1;
    this.idfc=1;
    
    this.document=0;
    this.stage=0;
    
  }
toggle6() { 
  alert('2');
  this.isValue = 2;
   this.idfc=1;
  
   this.document=0;
   this.stage=0;
   
}
toggle2() { 
  alert('3');
  this.isValue = 2;
  this.idfc= 1;
 
  this.document=0;
  this.stage=0;
}
toggle5() { 
  alert('4');
  this.isValue = 1;
  this.idfc= 1;
 
  this.document=0;
  this.stage=0;
}
toggle3() { 
  alert('5');
  this.isValue = 3; 
  this.idfc= 0;

  this.document=1;
  this.stage=0;
}
toggle4() { 
  alert('6');
  this.isValue = 4;
  this.idfc= 1;
 
  this.document=0;
  this.stage=1;

}

valueChange(value) {
  //console.log(value);
  if(value !=undefined){
    this.remainingText = 300 - value.length;
  //console.log(this.remainingText);
  }
  
  
 }
 docrejecttotal(value) {
  //console.log(value);
  if(value !=undefined){
    this.totalReject = 30 - value.length;
  //console.log(this.remainingText);
  }
  
  
 }
 onClick(check){
      //console.log(check);
      if(check==1){
        this.tab = 'tab1';
        this.tabval=1;
      }else if(check==2){
        this.tab = 'tab2';
        this.tabval=2;
       // console.log(this.cust);
      }else if(check==3){
        this.tab = 'tab3';
        this.tabval=3;
       // console.log(this.cust);
      }     
   //alert(this.tabval);
  }
 onSwitch(check){
 
  switch (check) {
   case 1:
     return 'tab1';
   case 2:
     return 'tab2';
   
 }
}
getapencard(el){
  // console.log(el);
   this.title=el;
   this.imageur="../../../assets/documents/pencard.png";
   this.css="border_clas";
 }
 getadharcard(el){
   this.title=el;
   this.imageur="../../../assets/documents/adharcard.png";
   this.css="border_clas";
 }
   //user Details
   ExcellDetails(user){
   
    let stage=   this.feedback_Stage;
   // 
   this.useid = this.cryptojsService.encryptData((user));  
  // console.log(this.useid);  
   //this.decryptData = this.cryptojsService.decryptData(this.useid); 
  // console.log(this.decryptData);    
   const base_url=location.origin+"/#/MeraEMI-mis-send-lender?data=";        
   const detailsurl = base_url + this.useid.toString();
   const dateur=detailsurl+"&stage="+stage.toString();
  
   //console.log(detailsurl);
   window.open(dateur, "_blank");
  }

clickEvent(data1,data2,data3,data4,data5,data6,data7,data8,data9){
  let userId=data1;
  let phoneNumber=data2;
  let countryCode=data3;
  let name=(data5+' '+data6);
  let email=data4;
  this.user_id=this.decryptData;
  var today = new Date();
  let currentdateandtime=(today.toISOString());
  
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();    
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
   this.followupdate =(date+' '+time);
  //console.log(event.toISOString());
  let details = {
    data: {
      results: [{
        "userId":userId,            
        "phoneNumber":phoneNumber,          
        "countryCode": countryCode,
        "name": name,
        "email": email,
        "createdAt": currentdateandtime
        
            
      }]
  
    }
  };
  
 
   // console.log(details);
     details['data']['results']; 
     this.FeedbackService.postwhatsap(details)
     .subscribe((result: any) => { 
      if(result.result===true){
        let details = {
          data: {
            results: [{
              "user_id":userId,
              "message":result.message,            
              "admin":this.user.first_name,          
              "status": "1",
              "created_by": "1",
              "created_on": this.followupdate,
                  
            }]
        
          }
        };
        //console.log(details);
        details['data']['results']; 
        this.FeedbackService.postwhatsapInsertdataadmin(details)
        .subscribe((result: any) => { 
          if(result.response=== 200 && result.status=== true){
            //console.log(this.userdetils);
            let details = {
              data: {
                results: [{
                  "user_id":userId,                  
                      
                }]
            
              }
            };
          
          }
        });
      }else{ //main if close
        let details = {
         
            results: [{
              "user_id":userId,
              "message":result.message,            
              "admin":this.user.first_name, 
              "phoneNumber":phoneNumber,
              "name": name,
              "email": email,
              "createdAt": currentdateandtime,
              "emilSendStage":"Insert"
            }]
        
        };
        details['results']; 
        this.FeedbackService.SendEmailAdminUserTransfer(details)
        .subscribe((result: any) => {
        console.log(result);
        });


      }
      
      
        
     });//main function  close
  
}
DetDocumentPage(data){
  let stage=  data.file_name;
  this.usewebContentLinkid =data.webViewLink; 
  const filetype = this.usewebContentLinkid.split("?"); 
  const docname=filetype[0];
  const str=filetype[0];
  const newstr = str.replace("view","preview"); 
  const driveurl = this.cryptojsService.encryptData(newstr);
        const base_url=location.origin+"/#/Meraemi-Document-View?data=";        
        const detailsurl = base_url + driveurl.toString();       
        const dateur=detailsurl+"&stag="+stage.toString();      
        //console.log(dateur);
        window.open(dateur, "_blank");
//console.log(data);
}

whataapBtn(){
  this.whataapelig=1;
}
cancel(){
   this.whataapelig =0;
}

docrecevied(){
  this.doc_recevied=1;
}





Doc_reject(){
  
  console.log(this.docrej.value.ctwaUser);
  let details = {
   
    results: [{
      "message":this.docrej.value.ctwaUser, 
      "status":"3",
      "userid":this.decryptData,
      "msgid":this.msgid
  
      
    }]

  
};

details['results']; 
      this.ctwadoc.reasonForRejection(details)
      .subscribe((result: any) => { 
        if(result.response===200 && result.status===true){
          this.docReject.hide();
          this.doclength=0;
          this.ctwaDocs=[];
          const uid = parseInt(this.decryptData);
            this.ctwadoc.getCTWADocListing(uid).subscribe(             
              (doc:any) => {
                if(doc.status === true   && doc.response == 200 ){
                  this.doclength=doc.ctwa.length;
                  for (let i = 0; i < doc.ctwa.length; i++) {
                    const element = doc.ctwa[i];
                    this.imgType=element.data[0].ctwa_doc_url;
                    let urlParts = this.imgType.split('%2F');
                  let img= urlParts[3];
                  let file_type = img.split('&');
                    
                    console.log(file_type[0]);
                    this.ctwaDocs.push(
                      {
                      
                        created_on: element.data[0].created_on, 
                        ctwa_doc_name: element.data[0].ctwa_doc_name, 
                        ctwa_doc_url:  element.data[0].ctwa_doc_url,
                      //  doc_catg_id:  element.data[0].doc_catg_id, 
                      //  doc_id:  element.data[0].doc_id, 
                        message:  element.data[0].message, 
                        message_id:  element.data[0].message_id, 
                        message_title:  element.data[0].message_title,
                        message_type:  file_type[0],
                        modified_by:  element.data[0].modified_by,
                        status:  element.data[0].status,
                        user_id:  element.data[0].user_id,             


                      
                      
                      }
                      );
                    }
                    console.log( this.ctwaDocs);
                }else{
                  this.doclength=0;
                }
              // console.log(userdetilspage);
              });
        }else{
          this.docReject.hide();
        }
        //console.log(result);
      });
}
ctwaDocReceived(){

  this.docname=this.ctwaDocRec.value.docname;
  let details = {
     
    results: [{
      "docname":this.ctwaDocRec.value.docname, 
      "status":"2",
      "userid":this.decryptData,
      "msgid":this.msgid
  
      
    }]

  
};

details['results']; 
      this.ctwadoc.ctwaDocReceived(details)
      .subscribe((result: any) => { 
        if(result.response===200 && result.status===true){
          this.docCat.hide();
          this.doclength=0;
          this.ctwaDocs=[];
          const uid = parseInt(this.decryptData);
          this.ctwadoc.getCTWADocListing(uid).subscribe(             
            (doc:any) => {
              if(doc.status === true   && doc.response == 200 ){
                this.doclength=doc.ctwa.length;
                for (let i = 0; i < doc.ctwa.length; i++) {
                  const element = doc.ctwa[i];
                  this.imgType=element.data[0].ctwa_doc_url;
                  let urlParts = this.imgType.split('%2F');
                let img= urlParts[3];
                let file_type = img.split('&');
                  
                  console.log(file_type[0]);
                  this.ctwaDocs.push(
                    {
                    
                      created_on: element.data[0].created_on, 
                      ctwa_doc_name: element.data[0].ctwa_doc_name, 
                      ctwa_doc_url:  element.data[0].ctwa_doc_url,
                      message:  element.data[0].message, 
                      message_id:  element.data[0].message_id, 
                      message_title:  element.data[0].message_title,
                      message_type:  file_type[0],
                      modified_by:  element.data[0].modified_by,
                      status:  element.data[0].status,
                      user_id:  element.data[0].user_id,             


                    
                    
                    }
                    );
                  }
                  console.log( this.ctwaDocs);
              }else{
                this.doclength=0;
              }
            // console.log(userdetilspage);
            });
        }else{
          this.docCat.hide();
        }
        //console.log(result);
      });

}
  DocReceived(){
            if(this.crmsubstage.value.meraemi_stage !=''){
            if(this.crmsubstage.value.meraemi_stage =='21'){
              this.status=parseInt(this.crmsubstage.value.meraemi_stage);
              this.message="Update on Doc Complete Stage";
            }else if(this.crmsubstage.value.meraemi_stage =='14'){
                  this.status=parseInt(this.crmsubstage.value.meraemi_stage);
                this.message="Update on Cust Cancel Stage";
              
            }else if(this.crmsubstage.value.meraemi_stage =='15'){
                          this.status=parseInt(this.crmsubstage.value.meraemi_stage);
              this.message="Update on CRM Cancel Stage";
            
            }else if(this.crmsubstage.value.meraemi_stage =='16'){
                          this.status=parseInt(this.crmsubstage.value.meraemi_stage);
              this.message="Update on CRM Decline Stage";          
            }else if(this.crmsubstage.value.meraemi_stage =='20'){
              this.status=parseInt(this.crmsubstage.value.meraemi_stage);
                this.message="Update on Doc Pending Stage";          
              }

        }else{
        alert("Select crm Stage !...");
        this.crmsubstage.reset();
        }
// console.log(this.crmsubstage.value.meraemi_stage);  return false;  
this.docRecive.hide();
this.user_id=parseInt(this.decryptData);
this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
this.feedbackProductname ="4";
this.feedbackLendername =0;
var currentdate = new Date();
// adjust 0 before single digit date
let dates = ("0" + currentdate.getDate()).slice(-2);
// current month
let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
// current year
let year = currentdate.getFullYear();
this.currentdate =(year + "-" + month + "-" + dates);
this.followupdate ="0000-00-00 00:00:00";

 let details = {
   
     results: [{
       "message":this.message, 
       "status":this.status,
       "user_id":this.user_id,
       "admin_name":this.user.first_name,                      
       "loan_id":this.loanid,      
       "feedbackProductname" :this.feedbackProductname,
       "feedbackLendername":this.feedbackLendername,
       "interactiondatetime":this.currentdate,  
       "followupdate":this.followupdate,
       "CrmStage":this.CrmStage,
      "campstatus":"1"

     }]
 
   
 };
//console.log(details);       //return false;     
 details['results']; 
 this.FeedbackService.postfeedback(details)
.subscribe((result: any) => { 
// console.log(result); return false;     
         if (result.status === true && result.response===200 ) {
           this.idFilter.reset();
           this.transfertabopen=0;
                     this.FeedbackPopup =0;
                     this.elements_feedback.length=0;
                    
                    
                   let details =  { results: [{ "user_id":this.user_id}] } ;
                   details['results']; 
                   this.FeedbackService.postfeedback_getcrm(details)
                   .subscribe((result: any) => {  
                    // console.log(result);
                     if (result.status == true) {
                      //console.log(result);
                       for (let i = 0; i < result.feedvalue.length; i++) {
                         const element = result.feedvalue[i];
                         

                            if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                              this.docPending.push(
                                { 
                                  docPending:"1"

                                });


                            }

                         
                               if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                                 this.docPending.push(
                                   { 
                                     docPending:"1"

                                   });


                               }



                               if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                                 this.docPending.push(
                                   { 
                                     Received:"1"

                                   });

                               }








                         if(element.data[0].lender_name == "LoanTap"){
                           this.lenderloantabactive=1;
                         }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                           this.lenderHDBactive=1;
                         }else{
                           console.log("call other lender");
                         }
                         if(element.data[0].status == "1"){
                           this.crmDate=element.data[0].interactiondate;
                           this.crmStage= element.data[0].meraemi_stage;
                         }
                          console.log(element.data[0].status);
                          console.log(this.crmStage);
                         if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                          
                       
                          
                            
                           this.OldFeedbackMessage.push(
                             {
                              
                               admin_name: element.data[0].admin_name, 
                               feedback_id: element.data[0].feedback_id, 
                               feedback_message:  element.data[0].feedback_message, 
                               followupdate:  element.data[0].follow_update, 
                               interactiondate:  element.data[0].interactiondate, 
                               stage:  element.data[0].stage, 
                               user_id:  element.data[0].user_id, 
                               status:  element.data[0].status,
     
                              
                              
                             }
                             );
                         }else{
                           this.elements_feedback.push(
                             {
                               admin_name: element.data[0].admin_name, 
                               feedback_id: element.data[0].feedback_id, 
                               feedback_message:  element.data[0].feedback_message, 
                               followupdate:  element.data[0].follow_update, 
                               interactiondate:  element.data[0].interactiondate, 
                               stage:  element.data[0].stage, 
                               user_id:  element.data[0].user_id, 
                               status:  element.data[0].status,
                               meraemi_stage:  element.data[0].meraemi_stage,
                               lender_name:  element.data[0].lender_name,
                               product_name:  element.data[0].product_name,
                             }
                             );
                         }
     
     
                         
                        
                          
                         
       
                           
                       }
                      
                      
                      
                    
                       
                       this.docPendingStatus=this.docPending[0]['docPending'];
                       this.ReceivedStatus=this.docPending[0]['Received'];
                    
                      //console.log(this.popupbtnactive);
                       this.fedbacklength =this.elements_feedback.length;
                       this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                       this.message = result.message;
                       this.popupaddBtn=1;
                        
                     }else{
                       this.popupaddBtn =0;
                       //("hello");
                     }
                   }); 
                   
         }else {
           let details = {
             
               results: [{
                 "message":this.message,            
                 "interactiondatetime":this.currentdate,          
                 "status": this.status,
                 "user_id":this.user_id,
                 "admin_name":this.user.first_name,                      
                 "loan_id":this.loanid,
                 "followupdate":this.followupdate,
                 "feedbackProductname" :this.feedbackProductname,
                 "feedbackLendername":this.feedbackLendername,
                 "emilSendStage":"feedbak"
               }]
           
           
           };
           
        //  console.log(details);                 
           details['results'];
           this.FeedbackService.SendEmailAdminUserTransfer(details)
           .subscribe((result: any) => {
           console.log(result);
           });
         }
         //meraemi stage
         
       
        
        
});

 }


    docuplods(){
      this.user_id=this.decryptData;   
      const docurl = "https://doc.meraemi.com/document/upload";     
      const dateur=docurl+"/"+this.user_id.toString();
      window.open(dateur, "_blank");
      console.log(this.user_id);  
    }
 



    docVeryFiyCancle(){
      this.docVeryFiNo.hide();
      this.FileReject.reset();
    }
    DocReject(){
     // console.log(this.form_id);
      this.docVeryFiNo.hide();
      if(this.FileReject.value.file_msg !=null){
        this.status="26";
        this.invalid_msg_id=this.FileReject.value.file_msg;
        
      // console.log(this.FileReject.value.file_msg );
      }else{
        this.FileReject.reset();
        this.listfileError.length=0;
      }

      this.message="Update on Doc Incomplete Stage";  
      this.user_id=this.decryptData;
      this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
      this.feedbackProductname ="4";
      this.feedbackLendername =0;
      var currentdate = new Date();
      // adjust 0 before single digit date
      let dates = ("0" + currentdate.getDate()).slice(-2);
      // current month
      let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
      // current year
      let year = currentdate.getFullYear();
      this.currentdate =(year + "-" + month + "-" + dates);
      this.followupdate ="0000-00-00 00:00:00";
      let details = {
            
        results: [{
          "message":this.message, 
          "status":this.status,
          "user_id":this.user_id,
          "admin_name":this.user.first_name,                      
          "loan_id":this.loanid,      
          "feedbackProductname" :this.feedbackProductname,
          "feedbackLendername":this.feedbackLendername,
          "interactiondatetime":this.currentdate,  
          "followupdate":this.followupdate,
          "invalid_msg_id":this.invalid_msg_id,
          "CrmStage":"Doc Incomplete",
          "doc_response_id":this.doc_response_id,
          "doc_id":this.doc_id,
          "campstatus":"1",
        "form_id": this.form_id
        
    
          
        }]
    
      
    };
    
    }
    docveryfiyes(data,count){
    
      this.setcounter ++;
      console.log(this.setcounter); return false;
      

    }
    onChangeRole(doc, isChecked) {
      this.checkedInfo = isChecked;
      let toatalcountDoc=this.drivelength;
    //  console.log(this.checkedInfo); //return false;
      if (isChecked.target.checked) {
    
          this.setcounter ++;
      } else {
        
          this.setcounter --;
      }
      console.log(this.setcounter);
      if(toatalcountDoc===this.setcounter){
        this.doccompletebtn=1;
        this.docVeryFiYes.show();
        this.status="25";
      // this.message="Update on Doc Complete Stage";  
        this.user_id=this.decryptData;
        this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
        this.feedbackProductname ="4";
    
        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
        this.followupdate ="0000-00-00 00:00:00";
      }else{
        this.doccompletebtn=0;
      }

      //console.log(this.setcounter); return false;
    }

    uncheck() {
        this.inputs.forEach(check => {
            check.nativeElement.checked = false;
        });
    }
    copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
    docverify(data){
      //console.log();
     //   console.log(this.drivelength); return false;
        this.doc_id="0";
        this.status=this.docfeedback.value.docrecivstage;  
        this.message=this.docfeedback.value.docmessage;  
        this.user_id=this.decryptData;
        this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
        this.feedbackProductname ="4";
     
        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
        this.followupdate ="0000-00-00 00:00:00";
        let details = {
              
          results: [{
            "feedbackProductname" :this.feedbackProductname,
            "user_id":this.user_id,
            "admin_name":this.user.first_name,
            "message":this.message, 
            "interactiondatetime":this.currentdate, 
            "loan_id":this.loanid,    
            "status":this.status,
            "CrmStage":"Doc Review",      
            "doc_id":this.doc_id,  
            "campstatus":"1"
            
          }]
      
        
      };
       
      }
      docReceivedUser(data,evnt){
      
    
        var currentdate = new Date();
        // adjust 0 before single digit date
        let dates = ("0" + currentdate.getDate()).slice(-2);
        // current month
        let month = ("0" + (currentdate.getMonth() + 1)).slice(-2);
        // current year
        let year = currentdate.getFullYear();
        this.currentdate =(year + "-" + month + "-" + dates);
                               
                        
         if(evnt.isTrusted===true){
                        this.user_id=this.decryptData;
                        this.loanid=this.leadDetails[0].loans[0].loanDetails.loan_id;
                        
                        this.feedbackProductname ="4";
                        this.feedbackLendername =0;
                        let details = {
                          
                            results: [{
                              "message":"doc Received User",            
                              "interactiondatetime":this.currentdate,          
                              "status": data,
                              "user_id":this.user_id,
                              "admin_name":this.user.first_name,                      
                              "loan_id":this.loanid,
                              "followupdate":this.currentdate,
                              "feedbackProductname" :this.feedbackProductname,
                              "feedbackLendername":this.feedbackLendername,
                              "CrmStage":"doc Received",
                               "campstatus":"1"
                            }]
                        
                          
                        };
                    //console.log(details);       return false;     
                        details['results']; 
                            this.FeedbackService.postfeedback(details)
                            .subscribe((result: any) => { 
                                    console.log(result);
                                            if (result.status === true && result.response===200 ) {
                                              this.idFilter.reset();
                                              this.transfertabopen=0;
                                                        this.FeedbackPopup =0;
                                                        this.elements_feedback.length=0;
                                                       
                                                       
                                                      let details =  { results: [{ "user_id":this.user_id}] } ;
                                                      details['results']; 
                                                      this.FeedbackService.postfeedback_getcrm(details)
                                                      .subscribe((result: any) => {  
                                                        console.log(result);
                                                        if (result.status == true) {
                                                         console.log(result);
                                                          for (let i = 0; i < result.feedvalue.length; i++) {
                                                            const element = result.feedvalue[i];


                                                            if(element.data[0].stage === "3" && element.data[0].campain_status ==="1"){
                                                              this.docPending.push(
                                                                { 
                                                                  docPending:"1"
                                                            
                                                                });
                                                            
                                                            
                                                            
                                                            }
                                                             if(element.data[0].stage === "20" && element.data[0].campain_status ==="1"){
                                                              this.docPending.push(
                                                                { 
                                                                  docPending:"1"
                                                            
                                                                });
                                                            
                                                            
                                                            
                                                            }
                                                            
                                                            if(element.data[0].stage != "20" && element.data[0].campain_status ==="1"){
                                                              this.docPending.push(
                                                                { 
                                                                  Received:"1"
                                                            
                                                                });
                                                            
                                                            }
                                                            if(element.data[0].lender_name == "LoanTap"){
                                                              this.lenderloantabactive=1;
                                                            }else  if(element.data[0].lender_name == "HDB Financial Services Ltd"){
                                                              this.lenderHDBactive=1;
                                                            }else{
                                                              console.log("call other lender");
                                                            }
                                                            if(element.data[0].status == "1"){
                                                              this.crmDate=element.data[0].interactiondate;
                                                              this.crmStage= element.data[0].meraemi_stage;
                                                            }
                                                             console.log(element.data[0].status);
                                                             console.log(this.crmStage);
                                                            if(element.data[0].stage == "Cancel" ||  element.data[0].stage == "Active" || element.data[0].stage == "Transfer"){
                                                             
                                                          
                                                             
                                                               
                                                              this.OldFeedbackMessage.push(
                                                                {
                                                                 
                                                                  admin_name: element.data[0].admin_name, 
                                                                  feedback_id: element.data[0].feedback_id, 
                                                                  feedback_message:  element.data[0].feedback_message, 
                                                                  followupdate:  element.data[0].follow_update, 
                                                                  interactiondate:  element.data[0].interactiondate, 
                                                                  stage:  element.data[0].stage, 
                                                                  user_id:  element.data[0].user_id, 
                                                                  status:  element.data[0].status,
                                        
                                                                 
                                                                 
                                                                }
                                                                );
                                                            }else{
                                                              this.elements_feedback.push(
                                                                {
                                                                  admin_name: element.data[0].admin_name, 
                                                                  feedback_id: element.data[0].feedback_id, 
                                                                  feedback_message:  element.data[0].feedback_message, 
                                                                  followupdate:  element.data[0].follow_update, 
                                                                  interactiondate:  element.data[0].interactiondate, 
                                                                  stage:  element.data[0].stage, 
                                                                  user_id:  element.data[0].user_id, 
                                                                  status:  element.data[0].status,
                                                                  meraemi_stage:  element.data[0].meraemi_stage,
                                                                  lender_name:  element.data[0].lender_name,
                                                                  product_name:  element.data[0].product_name,
                                                                }
                                                                );
                                                            }
                                        
                                        
                                                            
                                                           
                                                             
                                                            
                                          
                                                              
                                                          }
                                                         
                                                         
                                                         
                                                       
                                                          this.docPendingStatus=this.docPending[0]['docPending'];
                                                          this.ReceivedStatus=this.docPending[0]['Received'];
                                                       
                                                         //console.log(this.popupbtnactive);
                                                          this.fedbacklength =this.elements_feedback.length;
                                                          this.fedbacklengthOld =this.OldFeedbackMessage.length;        
                                                          this.message = result.message;
                                                          this.popupaddBtn=1;
                                                           
                                                        }else{
                                                          this.popupaddBtn =0;
                                                          //("hello");
                                                        }
                                                      }); 
                                                      
                                            }else {
                                              let details = {
                                                
                                                  results: [{
                                                    "message":this.message,            
                                                    "interactiondatetime":this.currentdate,          
                                                    "status": this.status,
                                                    "user_id":this.user_id,
                                                    "admin_name":this.user.first_name,                      
                                                    "loan_id":this.loanid,
                                                    "followupdate":this.followupdate,
                                                    "feedbackProductname" :this.feedbackProductname,
                                                    "feedbackLendername":this.feedbackLendername,
                                                    "emilSendStage":"feedbak"
                                                  }]
                                              
                                              
                                              };
                                              
                                           //  console.log(details);                 
                                              details['results'];
                                              this.FeedbackService.SendEmailAdminUserTransfer(details)
                                              .subscribe((result: any) => {
                                              console.log(result);
                                              });
                                            }
                                            //meraemi stage
                                            
                                          
                                           
                                           
                      });
         }else{
           console.log("Other stage");
         }
      }
      quetesfeedback(data,userid){
        this.feedbackquate.show();
        this.feedid=data;
        this.uid=userid;
      //  console.log(data);
      }
      quatefeedbackInsert(){
       // undefined
      
        if(this.quatefeedback.value.quatemessage !=undefined){
              this.quate_feedback= this.quatefeedback.value.quatemessage;
              let details = { results: [{ "message":this.quate_feedback ,"feedid":this.feedid ,"user_id":this.uid, "admin_name":this.user.first_name   }]};
           //   console.log(details); return false;
              details['results']; 
              this.FeedbackService.postSubfeedbackComment(details)
              .subscribe((result: any) => { 
                  if(result.status==true){
                    let Getdetail =  { results: [{ "user_id":this.decryptData}] } ;
                    Getdetail['results']; 
                 
                  }
               // console.log(result);
              
            });
          
        }
    }
   
     //intraktUrlAPIlisting
  intraktuserlisting(data){
    // console.log(data); return false;
  
      
      const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
     

     const dateur=base_url+"91"+data.toString();
    
     // //console.log(detailsurl);
      window.open(dateur, "_blank");
      

    }
    detDocumentPagesss(data){
       console.log(data); return false;
    
        
        const base_url="https://app.interakt.ai/inbox?channelPhoneNumber=";        
       
  
       const dateur=base_url+"91"+data.toString();
      
       // //console.log(detailsurl);
        window.open(dateur, "_blank");
        
  
      }
      detDocumentPage(data){
        this.rejectstatus=data.status;
        console.log(data);
        this.imgType=data.ctwa_doc_url;
        let urlParts = this.imgType.split('%2F');
       let img= urlParts[3];
       let file_type = img.split('&');
        
        console.log(file_type[0]);
        
   //    const fileExtension: string =  this.imgType.split('.').pop()?.toLowerCase() || '';
//
      //  console.log(fileExtension);
        if(file_type[0]=="pdf"){
          this.ctwaurl=data.ctwa_doc_url;
        // this.sanitizer.bypassSecurityTrustResourceUrl(this.ctwaurl);
          this.msgid=data.message_id;
          this.ctwauid=data.user_id;
          this.doc.show();
          this.pdfimg=1;
        }else{
          this.pdfimg=0;
          this.ctwaurl=data.ctwa_doc_url;
          // this.sanitizer.bypassSecurityTrustResourceUrl(this.ctwaurl);
           this.msgid=data.message_id;
           this.ctwauid=data.user_id;
           this.doc.show();
        }
        
        console.log(this.pdfimg);
        console.log(this.ctwaurl);
        console.log(this.msgid);
        console.log(this.ctwauid); return false;

      this.ctwaurl=data.ctwa_doc_url;
     // this.sanitizer.bypassSecurityTrustResourceUrl(this.ctwaurl);
      this.msgid=data.message_id;
      this.ctwauid=data.user_id;
      this.doc.show();
        
    }
    docAccept(){
      this.doc.hide();
      this.docCat.show();
    }
    docRejects(){
      this.doc.hide()
      this.docReject.show();
    }
    
}
