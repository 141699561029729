import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CtwaService } from 'src/app/services/ctwa/ctwa.service';

import { MasterService } from 'src/app/services/master/master.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-doc-type-master',
  templateUrl: './doc-type-master.component.html',
  styleUrls: ['./doc-type-master.component.scss']
})
export class DocTypeMasterComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("addnewDoc", {static: true}) addnewDoc: ModalDirective;
  @ViewChild("editDoc", {static: true}) editDoc: ModalDirective;
  
  searchText: any;
  elements: any = [];
  previous: any = [];
  docCategory: any = [];
  docCategoryArray: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
  headElements = [
    {
       name:"doc id",
       sortBy:"doc id",
       width: 150
     },
     {
      name:"Doc Name",
      sortBy:"Doc Name",
      width: 150
    },
    {
      name:"Action",
      sortBy:"Action",
      width: 150
    }

   ];
  docid: any;
  docname: any;
  
  constructor( 
      private masterService : MasterService,  
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
     
      private ctwaService:CtwaService,
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.registerForm = this.formBuilder.group({

      docnameformgup: this.formBuilder.group({
        docname: ['', Validators.required],
        doccatid: ['', Validators.required],
       
     }),
     editdocnameformgup: this.formBuilder.group({
      editDocname: ['', Validators.required]
     
   }),
     
   });
    this.ctwaService
    .getDocName()
    .subscribe( (result:any) => {
   // console.log(result); return false;
    
      if (result.status = true  && result.response=== 200) {      //        
        for (let i = 0; i < result.docName.length;  i++) {
          const element = result.docName[i];
       
           
                            this.elements.push(
                              {
                                doc_id	:element.data[0].doc_id,
                                dscription: element.data[0].dscription,
                               
                           
                                          
                                
                                
                                

                              }
                              );
                              
                                
            }
          //console.log(this.elements);
            this.mdbTable.setDataSource(this.elements);
            this.elements = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
            }else{
              console.log('User list Api faile');
            }
            
          });
         
          this.ctwaService
          .getDocCategory()
          .subscribe( (result:any) => {   
         // console.log(result);   return false;
                  if (result.status == true  && result.response== 200) {                
                    for (let i = 0; i < result.docCat.length; i++) {
                      const menuarray = result.docCat[i];
                    //  console.log(menuarray);
                      this.docCategory.push(
                        {
                          value: menuarray.data[0].doc_catg_id,
                          label: menuarray.data[0].document_category,                         
                         
                        }
                        );
                        
                    }
                    this.docCategoryArray=this.docCategory;
                    //this.productArray =this.getProduct;                
                  }else{
                    console.log('Select Doc Category  Api faile');
      
                   
                  }
                  
                });
    
    
  }
// add doc 
  docSubmitted = true;
  get rs() { return (<FormGroup>this.registerForm.get('docnameformgup')).controls; }
  get docnameformgup() {
    return this.registerForm.get('docnameformgup');
  }
  // edit doc

  editDocSubmitted = true;
  get ed() { return (<FormGroup>this.registerForm.get('editdocnameformgup')).controls; }
  get editdocnameformgup() {
    return this.registerForm.get('editdocnameformgup');
  }
    //intraktUrlAPIlisting
    docnamebtn(){
      this.docSubmitted = true;
      this.showLoader = false;
  
      // stop here if form is invalid
      if (this.docnameformgup.invalid) {
        console.log("err");
        setTimeout(() => {
          this.showLoader = true;
        }, 2000);
  
        return;
      }else{
        let docname=this.docnameformgup.value.docname;
        let doccatid=this.docnameformgup.value.doccatid;
        console.log(doccatid);

        let detail = { results: [{ "docname":docname,"cat_id":doccatid}] };
        detail['results']; 
        this.masterService.addDoc(detail).subscribe((result: any) => {
          if(result.response == 200 && result.status == true){
            this.showLoader = true;
            this.docnameformgup.get('docname').setValue('');
            this.docnameformgup.get('doccatid').setValue('');
            this.elements=[];
            this.addnewDoc.hide();
            this.ctwaService
            .getDocName()
            .subscribe( (result:any) => {
           // console.log(result); return false;
            
              if (result.status = true  && result.response=== 200) {      //        
                for (let i = 0; i < result.docName.length;  i++) {
                  const element = result.docName[i];
               
                   
                                    this.elements.push(
                                      {
                                        doc_id	:element.data[0].doc_id,
                                        dscription: element.data[0].dscription,
                                       
                                   
                                                  
                                        
                                        
                                        
        
                                      }
                                      );
                                      
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
                 
          }
        
        });
      

      }
    }
    docEdit(data){
      this.docid=data.doc_id;
      this.docname=data.dscription;
  
      this.showLoader = false;
      setTimeout(() => {
        this.editDoc.show();
        this.showLoader = true;
      }, 1000);
      console.log(data);
    }
    editdocnamebtn(){
      this.editDocSubmitted = true;
      this.showLoader = false;
  
      // stop here if form is invalid
      if (this.editdocnameformgup.invalid) {
        console.log("err");
        setTimeout(() => {
          this.showLoader = true;
        }, 2000);
  
        return;
      }else{

        let editDocname=this.editdocnameformgup.value.editDocname;
        console.log(editDocname);
        let detail = { results: [{ "editDocname":editDocname,"docid":this.docid}] };
        detail['results']; 
        this.masterService.editDoc(detail).subscribe((result: any) => {

          if(result.response === 200 && result.status === true){
            this.showLoader = true;
            this.elements=[];
            this.editDoc.hide();
            this.ctwaService
            .getDocName()
            .subscribe( (result:any) => {
           // console.log(result); return false;
            
              if (result.status = true  && result.response=== 200) {      //        
                for (let i = 0; i < result.docName.length;  i++) {
                  const element = result.docName[i];
               
                   
                                    this.elements.push(
                                      {
                                        doc_id	:element.data[0].doc_id,
                                        dscription: element.data[0].dscription,
                                       
                                   
                                                  
                                        
                                        
                                        
        
                                      }
                                      );
                                      
                                        
                    }
                  //console.log(this.elements);
                    this.mdbTable.setDataSource(this.elements);
                    this.elements = this.mdbTable.getDataSource();
                    this.previous = this.mdbTable.getDataSource();
                    }else{
                      console.log('User list Api faile');
                    }
                    
                  });
          }
        
        });
      }
    }
    docDelete(data){
      this.showLoader = false;
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);

      
      this.docid=data.doc_id;    
      this.masterService.deleteDoc(this.docid)
      .subscribe((del:any) => {
        if(del.response === 200 && del.status === true ){
          this.elements=[];          
          this.ctwaService
          .getDocName()
          .subscribe( (result:any) => {      
                 if (result.status = true  && result.response=== 200) {           
              for (let i = 0; i < result.docName.length;  i++) {
                const element = result.docName[i];           
                  this.elements.push(
                                    {
                                      doc_id	:element.data[0].doc_id,
                                      dscription: element.data[0].dscription,
                                    }
                                    );                                  
                                      
                  }
                //console.log(this.elements);
                  this.mdbTable.setDataSource(this.elements);
                  this.elements = this.mdbTable.getDataSource();
                  this.previous = this.mdbTable.getDataSource();
                  }else{
                    console.log('User list Api faile');
                  }
                  
                });
        }else{
          console.log('User list Api faile');
        }
    
      });
      
    }
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(7);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 








        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/dashboard']);
  }, 2000);
 
  
  }
}